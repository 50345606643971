import React, { useContext } from 'react';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import { DataControlContext } from '../../../../../store/context/dataControl';
import { valuePeriodiciteEcheance } from '../Bloc3/Bloc3';
import './style.scss';
import { activePageBlock } from '../../../../../store/actions/simulateur';
import { SimulateurContext } from '../../../../../store/context/simulateur';

const Bloc32 = (props) => {
    const  { data, withoutCalcul } = props;

    const { simulateurDispatch } = useContext(SimulateurContext);
    const { empruntState, setEmpruntState } = useContext(DataControlContext);
    const { avec_assurance_amortiss_sup, periode_echeance } = empruntState;

    const selectData = [
        {
            value: 0,
            label: 'Non'
        },
        {
            value: 1,
            label: 'Oui'
        }
    ]

    const handleChange = (event) => {
        const { value } = event.target;
        const emp = { ...empruntState };

        emp.avec_assurance_amortiss_sup = value;

        simulateurDispatch(activePageBlock());
        setEmpruntState(emp);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
            <div style={{ fontWeight: 'bold', borderBottom: '2px solid #D8D8D8', padding: 8 }} >Montant échéance</div>
            <div style={{ height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }} >

                <div style={{ display: 'flex', height: '30px', lineHeight: '30px' }} >
                    <div style={{ width: '45%' }} >Périodicité</div>
                    <div style={{ background: 'rgb(88,92,102)', color: '#FFFFFF', width: '35%', textAlign: 'center', borderRadius: 15 }} >
                        { valuePeriodiciteEcheance(periode_echeance) }
                    </div>
                </div>
                <div className='cadreRougeBloc32' ></div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }} >
                    <div>
                        <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                            {
                                data && 
                                data.durant_differe ? numberFormat(data.durant_differe) : 0
                            } €
                        </div>
                        <div className='cadreRoseBloc32' ></div>
                        <div>Durant le différé</div>
                    </div>
                    <div>
                        <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                            {
                                data && 
                                data.apres_differe ? numberFormat(data.apres_differe) : 0
                            } €
                        </div>
                        <div className='cadreRoseBloc32' ></div>
                        <div>Après le différé</div>
                    </div>
                </div>
                
                <div style={{ display: 'flex', height: '50px', lineHeight: '50px' }} >
                    <div style={{ width: '45%' }} >Avec assurance</div>
                    <div>
                        <select disabled={withoutCalcul} className='formSelectBloc32'  onChange={(event) => handleChange(event) } >
                            {
                                selectData.map((data, index) => {
                                    return (
                                        <option key={`Bloc32${index}`} value={data.value} selected={avec_assurance_amortiss_sup === data.value} >{data.label}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='cadreJauneBloc32' ></div>

            </div>
        </div>
    )
};

export default Bloc32;