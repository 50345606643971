export const autreDefaultData = JSON.parse(JSON.stringify({
    patrimoineFiscalite: {
        fiscaliteRevenus: {
            fiscaliteAnnuelle: 0
        }
    }
}))

// Les paramètres de l'export
export const configurationExportDefaultData = JSON.parse(JSON.stringify({
    donationSuccession: {
        succession: {
            touteSelection: false,
            elements: [
                {
                    value: false,
                    type: 'configuration',
                    label: "Configuration"
                },
                {
                    value: false,
                    type: 'patrimoine',
                    label: "Patrimoine"
                },
                {
                    value: false,
                    type: 'heritiersLegatairesBeneficiaires',
                    label: "Héritiers, légataires et bénéficiaires",
                    childrens: [
                        {
                            value: false,
                            type: 'heritiers',
                            label: "Héritiers"
                        },
                        {
                            value: false,
                            type: 'autresLegatairesBeneficiaires',
                            label: "Autres légataires et bénéficiaires"
                        }
                    ]
                }
            ],
            enregistrementSelection: false,
        }
    }
}))