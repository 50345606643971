import { CImg } from '@coreui/react';
import React from 'react';
import EditIcon from '../../../assets/icons/svg/edit.svg';
import CloneIcon from '../../../assets/images/clone.svg';
import SimulationClientSearchIcon from '../../../assets/images/bouton-recherche-simulation-client.svg';
import FicheThematiqueSearchIcon from '../../../assets/images/bouton-recherche-fiche-thematique.svg';
import AddClientIcon from '../../../assets/images/bouton-ajout-client.svg';
import ReinitialisationIcon from '../../../assets/images/bouton-reinitialisation.svg';
import DeleteIcon from '../../../assets/icons/svg/trash.svg';
import NewIcon from '../../../assets/images/svg/IFI/add.svg';
import { OrdinaryTooltip } from '../../InfoBulle/Tooltip/Tooltip';

const ButtonWithAction = ({
    type,
    label,
    onRequestClick
}) => {
    const Icon = type === 'SEARCH-SIMULATION-CLIENT' ? SimulationClientSearchIcon :
        type === 'SEARCH-FICHE-THEMATIQUE' ? FicheThematiqueSearchIcon :
            type === 'NEW-CLIENT' ? AddClientIcon :
                type === 'REINITIALISATION' ? ReinitialisationIcon :
                    type === 'NEW' ? NewIcon :
                        type === 'EDIT' ? EditIcon :
                            type === 'CLONE' ? CloneIcon : DeleteIcon;

    const texte = label || (
        ['SEARCH-SIMULATION-CLIENT', 'SEARCH-FICHE-THEMATIQUE'].includes(type) ? 'Rechercher' :
            type === 'NEW-CLIENT' ? 'Ajouter un nouveau client' :
                type === 'REINITIALISATION' ? "Réinitialiser" :
                    type === 'NEW' ? 'Nouveau' :
                        type === 'EDIT' ? "Editer" :
                            type === 'CLONE' ? "Dupliquer" : "Supprimer"
    );

    const isBigButton = ['SEARCH-SIMULATION-CLIENT', 'SEARCH-FICHE-THEMATIQUE', 'NEW-CLIENT', 'REINITIALISATION'].includes(type); 
    const ICON_WIDTH = isBigButton ? '35px' : '18px';

    const handleClick = () => {
        if (onRequestClick) {
            onRequestClick();
        }
    }

    return (
        <OrdinaryTooltip arrow title={texte} placement='bottom'>
            <div>
                <CImg className={isBigButton ? 'bouton-plateforme' : ''} src={Icon} onClick={handleClick} style={{ width: ICON_WIDTH }} />
            </div>
        </OrdinaryTooltip>
    )
}

export default ButtonWithAction;