import React from 'react';
import { completeDate, now, numberFormat } from '../../../../../extra/all_ppt_render';
import './style.scss';

const Bloc2 = (props) => {
    const  { data } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
            <div style={{ fontWeight: 'bold', borderBottom: '2px solid #D8D8D8', padding: 8 }} >Capital restant dû</div>
            <div style={{ height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }} >
                
                <div style={{ display: 'flex', height: '50px', lineHeight: '50px' }} >
                    <div style={{ width: '45%' }} >Montant en euros</div>
                    <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                        {
                            data &&
                            data.montant_en_euro ? numberFormat(data.montant_en_euro) : 0
                        } €
                    </div>
                </div>

                <div className='cadreRose' ></div>

                <div style={{ marginTop: 13, visibility: 'hidden' }} >TAEG</div>

                <div style={{ display: 'flex', height: '25px', lineHeight: '25px', marginTop: -7, visibility: 'hidden' }} >
                    <div style={{ width: '45%', fontSize: 11 }} >
                        Taux annuel effectif global
                    </div>
                    <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >4.63 %</div>
                </div>

                <div className='cadreRouge' style={{ visibility: 'hidden' }} ></div>

                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }} >
                    <div>
                        <div style={{ color: 'rgb(240,79,108)', marginBottom: 2 }} >&nbsp;</div>
                        <div>À la date du : <span style={{ color: 'rgb(127,134,154)' }} >{completeDate(now)}</span></div>
                    </div>
                </div>


            </div>
        </div>
    )
};

export default Bloc2;