import { dateAuFormatComplet } from "../../../../utils";

export const ficheClientDefaultData = JSON.parse(JSON.stringify({
    position: 1,
    dateEnregistrement: null,
    conjoints: [
      {
        civilite: null,
        nom: "",
        prenom: "",
        nomPersonnalise: "",
        dateNaissance: "",
        dateApproximative: false,
        age: 0,
        handicap: 0,
        situationFamiliale: 1,
        hasDescendant: 0,
        note: "",
        enfants: [],
        petitsEnfants: []
      },
      {
        civilite: null,
        nom: "",
        prenom: "",
        nomPersonnalise: "",
        dateNaissance: "",
        dateApproximative: false,
        age: 0,
        handicap: 0,
        enfants: [],
        petitsEnfants: []
      }
    ],
    nombreEnfants: 0,
    enfants: [],
    nombrePetitsEnfants: 0,
    petitsEnfants: [],
    intituleVersion: "version1",
    heureVersion: null,
    dateVersion: dateAuFormatComplet(),
    commentaireVersion: "",
    versions: []
}))
