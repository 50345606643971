import React, { useContext, useEffect, useState } from 'react';
import './style.scss';
import { DataControlContext } from '../../store/context/dataControl';
import { SimulateurContext } from '../../store/context/simulateur';

const SimulateurContainerWithMenuBis = ({
    typeTab,
    menus,
    scenarioErreur,
    onRequestCatchScenarioErreur,
    onRequestMenuChange,
    defaultIndex
}) => {
    const [currentMenu, setCurrentMenu] = useState(0);
    menus = menus?.filter((element) => element?.content !== null);

    const { headerMenuIndex } = useContext(DataControlContext);
    const { simulateurState } = useContext(SimulateurContext);

    // à chaque fois que le menu change
    useEffect(() => {
        if (onRequestMenuChange) {
            // console.log(menus);
            onRequestMenuChange(currentMenu, menus?.[currentMenu]?.resultPage || false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenu])

    useEffect(() => {
        if(defaultIndex !== undefined){
            setCurrentMenu(defaultIndex);
        }
    }, [ defaultIndex ])

    useEffect(() => {
      // console.log(headerMenuIndex, currentMenu.current, menus, "menu");
      if (simulateurState?.postItData.requestView) {
        console.log("Request pin view");
        const tabs = typeTab.split('.');
        if (tabs.length === 2) {
          console.log(headerMenuIndex[tabs[0]][tabs[1]]);
          setCurrentMenu(headerMenuIndex[tabs[0]][tabs[1]])
        } else {
          setCurrentMenu(headerMenuIndex[typeTab])
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulateurState?.postItData.requestView])

    useEffect(() => {
      if (headerMenuIndex.ficheClient === 1) {
        setCurrentMenu(headerMenuIndex.ficheClient)
      }
    }, [headerMenuIndex])

    return (
        <div className='simulateur-top-menu-bis'>
            {/** Les menus */}
            <div className='menu'>
                {
                    menus?.map((menu, index) => {
                        let isNotLastElement = index !== menus.length - 1;

                        return (
                            <div key={`Menu${index + 1}`} style={{ display: 'flex' }}>
                                <div
                                    className={`menu-item${currentMenu === index ? '-active' : ''}`}
                                    onClick={() => {
                                        if (!scenarioErreur) {
                                            if (!menu.noContent) {
                                                // console.log(index);
                                                setCurrentMenu(index)
                                            }
                                        }
                                        else {
                                            if (onRequestCatchScenarioErreur) {
                                                onRequestCatchScenarioErreur(true);
                                            }
                                        }
                                    }}
                                >
                                    {menu.label}
                                </div>
                                {
                                    isNotLastElement && (
                                        <div style={{ borderBottom: '2px solid #D9E1E7', width: '10px' }} />
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>

            {/** Le contenu du menu sélectionné */}
            <div className='contenu' style={{ ...(menus?.[currentMenu]?.style || {}) }}>
                {
                    menus?.[currentMenu]?.content || <></>
                }
            </div>
        </div>
    )
}

export default SimulateurContainerWithMenuBis;
