import Calculatrice from '../assets/images/Export/IFI/CALCULATOR.png';
import Info from '../assets/images/Export/IFI/INFO.png';
import TitleImage from '../assets/images/Export/IFI/HEADER.png';
import { restApiUri } from '../config';
import { completeDate, today } from '../utils';
import logoBase64 from './logoBase64';

// Police utilisée dans le document
export const pptFontFamily = 'Roboto';

// Couleur par défaut des textes dans le PPT
export const defaultPPTTextColor = '393939';

// Couleur Bleue dans les PPT
export const pptBlueColor = '4472C4';

// Récupération de logo
const fetchClientLogo = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const data = await fetch(`${restApiUri}/api/banque/${user.pole.banqueId}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    });

    return await data.json();
};

// Récuperation des éléments pour PPT personnalisé
const fetchPPTPersonnaliseElements = async () => {
    const response = await fetchClientLogo();
    const data = {
        logo: 'simulabox',
        pptPersonnalise: false
    };

    if (response) {
        const logo = response.image_64 && response.image_64 !== "" ? response.image_64 : 'simulabox';
        const pptPersonnalise = response.ppt_key;

        data.logo = logo;
        data.pptPersonnalise = pptPersonnalise;
    }

    return data;
};

// Define Slide Master
export const defineSlideMaster = async (pptx) => {
    const element = await fetchPPTPersonnaliseElements();
    const logo = element.logo;
    const isPPTPersonnalise = element.pptPersonnalise;

    // Texte en bas du document
    const objects = [
        { text: { text: "Document d’information générale – Non contractuel et Confidentiel", options: { x: 0.6, y: "90.6%", fontSize: 11, color: '32396C', w: 5, h: 0.3, fontFace: pptFontFamily } } },
        { text: { text: `En date du ${completeDate(today)}  - `, options: { x: 0.6, y: "94.3%", fontSize: 11, color: '32396C', w: 3, h: 0.2, fontFace: pptFontFamily } } }
    ];

    // Logo du document
    objects.push(
        {
            image: {
                x: "80%",
                y: "90.6%",
                w: 1.8,
                h: 0.6,
                data: `image/png;base64,${isPPTPersonnalise ? logo : logoBase64}`
            }
        }
    )

    // Layout
    pptx.defineLayout({ name: 'A4', width: 11.70, height: 8.26 });
    pptx.layout = 'A4';

    // Slide Master avec numérotation de page
    pptx.defineSlideMaster({
        title: "MASTER_SLIDE",
        bkgd: "FFFFFF",
        objects: objects,
        slideNumber: {
            x: 2.35,
            y: "93.8%",
            w: "3.5%",
            h: 0.18,
            bold: false,
            color: '32396C',
            fontSize: 11,
            fontFace: pptFontFamily
        }
    });
};

// Page de garde
export const generatePageGarde = (
    pptx,
    slide,
    text,
    width,
    text2,
    text3,
    textProps
) => {
    // Texte de fond BLEU CLAIR
    if (text3) {
        slide.addText(text3?.value || "", {
            x: text3?.x || 0,
            y: 0.3,
            w: text3?.width || width,
            h: 0.8,
            align: text3?.align || 'center',
            fontFace: 'Roboto',
            fontSize: 14,
            color: 'FFFFFF',
            bold: true,
            fill: 'B4C7E7',
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4,
            margin: text2?.margin || []
        })
    }

    // Texte de fond BLEU CIEL
    if (text2) {
        slide.addText(text2?.value || "", {
            x: text2?.x || 0,
            y: 0.3,
            w: text2?.width || width,
            h: 0.8,
            align: text2?.align || 'center',
            fontFace: 'Roboto',
            lineSpacing: text2?.lineSpacing || undefined,
            fontSize: 14,
            color: 'FFFFFF',
            bold: text2?.bold === false ? false : true,
            fill: '4472C4',
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4,
            margin: text2?.margin || []
        })
    }

    // Texte de fond BLEU MARINE
    slide.addText(text, {
        x: 0.6,
        y: 0.3,
        w: width,
        h: 0.8,
        align: 'center',
        fontFace: 'Roboto',
        fontSize: 14,
        color: 'FFFFFF',
        bold: true,
        fill: '28366D',
        shape: pptx.ShapeType.roundRect,
        rectRadius: 0.4,
        ...(textProps || {})
    })
}

// Titre de page (Pas l'entête) avec la barre horizontale bleue en dessous
export const generatePageTitle = (pptx, slide, label) => {
    slide.addText(label, {
        x: 0.4,
        y: 1.4,
        w: 7,
        fontSize: 13,
        bold: true,
        h: 0.4,
        fontFace: 'Roboto',
        color: '4472C4',
        valign: 'middle'
    });

    slide.addText("", {
        x: 0.5,
        y: 1.75,
        w: 0.6,
        h: 0.07,
        fill: '4472C4',
        shape: pptx.ShapeType.roundRect,
        rectRadius: 0.05,
    })
}

// Titre de première page des exports
export const generateFirstSlideTitle = (pptx, slide, title, x, y, type = 'SIMULATION', xTitle = undefined, width = undefined) => {
    const image = type === 'SIMULATION' ? Calculatrice : Info;

    const calculatriceWidth = 0.4;
    const calculatriceHeight = 0.5;
    const calculatriceX = x + 0.18;
    const calculatriceY = y + 0.18;

    const infoWidth = 0.17;
    const infoHeight = 0.42;
    const infoX = x + 0.32;
    const infoY = y + 0.22;

    slide.addText(title, {
        x: xTitle ? xTitle : x,
        y: y,
        w: width || 7,
        h: 1,
        fontFace: 'Roboto',
        align: 'center',
        valign: 'center',
        fontSize: 16,
        fill: '28366D',
        color: 'FFFFFF',
        shape: pptx.ShapeType.roundRect,
        rectRadius: 0.4,
        bold: true
    })

    slide.addImage({
        path: TitleImage,
        x: x - 0.25,
        y: y - 0.2,
        w: 1.8,
        h: 1.3
    })

    slide.addImage({
        path: image,
        x: type === 'SIMULATION' ? calculatriceX : infoX,
        y: type === 'SIMULATION' ? calculatriceY : infoY,
        w: type === 'SIMULATION' ? calculatriceWidth : infoWidth,
        h: type === 'SIMULATION' ? calculatriceHeight : infoHeight,
        fontFace: 'Roboto',
        fontSize: 16,
        color: '4472C4',
        align: 'center',
        valign: 'center',
        bold: true
    })
}

// Entête de toutes les pages des exports
export const generateTitleAndSubtitle = (type, pptx, slide, title, subtitle, secondSubtitle) => {
    const image = type === 'SIMULATION' ? Calculatrice : Info;
    const paddingLeftValue = 60;

    // Troisième titre
    if (secondSubtitle && secondSubtitle.text && secondSubtitle.width) {
        slide.addText(secondSubtitle.text, {
            x: ((title?.width + subtitle?.width) - 0.6) || secondSubtitle.x,
            y: 0.3,
            w: secondSubtitle.width,
            h: 0.8,
            fill: '9DC3E6',
            fontFace: 'Roboto',
            fontSize: 14,
            color: 'FFFFFF',
            bold: true,
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4,
            margin: [paddingLeftValue, 0, 0, 0]
        })
    }

    // Second titre
    if (subtitle && subtitle.text && subtitle.width) {
        slide.addText(subtitle.text, {
            x: title?.width || subtitle.x,
            y: 0.3,
            w: subtitle.width,
            fill: '4472C4',
            h: 0.8,
            fontFace: 'Roboto',
            fontSize: 14,
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4,
            color: 'FFFFFF',
            bold: subtitle.bold === false ? false : true,
            margin: [paddingLeftValue, 0, 0, 0]
        })
    }
    // Fin

    // Premier titre
    if (title && title.text && title.width) {
        slide.addText(title.text, {
            x: 0.6,
            y: 0.3,
            w: title.width,
            fill: '28366D',
            shape: pptx.ShapeType.roundRect,
            rectRadius: 0.4,
            h: 0.8,
            fontFace: 'Roboto',
            fontSize: 14,
            lineSpacing: title.lineSpacing,
            color: 'FFFFFF',
            bold: true,
            margin: [paddingLeftValue, 0, 0, 0]
        })
    }
    // Fin texte en Bleu marine

    slide.addImage({
        path: TitleImage,
        x: 0.35,
        y: 0.3,
        w: 1.2,
        h: 0.85
    })

    // Caractéristiques sur la CALCULATRICE
    const calculatriceX = 0.62;
    const calculatriceY = 0.5;
    const calculatriceWidth = 0.3;
    const calculatriceHeight = 0.4;

    // Caractéristiques sur INFO
    const infoX = 0.72;
    const infoY = 0.55;
    const infoWidth = 0.1;
    const infoHeight = 0.3;

    slide.addImage({
        path: image,
        x: type === 'SIMULATION' ? calculatriceX : infoX,
        y: type === 'SIMULATION' ? calculatriceY : infoY,
        w: type === 'SIMULATION' ? calculatriceWidth : infoWidth,
        h: type === 'SIMULATION' ? calculatriceHeight : infoHeight,
        fontFace: 'Roboto',
        fontSize: 12,
        color: '4472C4',
        align: 'center',
        valign: 'center',
        bold: true
    })
}

export const elementsPerPage = (rows, nbElementParPage) => {
    const data = [];

    rows?.map((row, index) => {
        if((index % nbElementParPage) === 0){
            let currentRow = rows.slice(index, index + nbElementParPage);

            data.push(currentRow);
        }
    })

    return data;
}