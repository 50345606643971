import PPTX from 'pptxgenjs';
import { now, fetchPPTPersonnalise, getInfoClient, generateLastPage, completeDate, generateSlide1SimulateurTitle, generateThreeBarres, generateTitleAndSubtitle, blackColor } from '../../all_ppt_render'
import { locationMeubleeData, locationMeubleeData2 } from './TableauFicheThematique/tableauLocationMeublee';
import { barreGreenBackgrounndFichePVImmo, barreOrangeBackgroundFichePVImmo, labelBackgroundFichePVImmo, noteBackgroundFichePVImmo, revenusFonciersDataFichePVImmo } from './TableauFicheThematique/tableauRevenuFoncier';
import OrangeInfo from '../../../assets/images/svg/PvImmo/ORANGE_INFO.png';
import { impotSocieteData } from './TableauFicheThematique/tableauImpotSociete';
import BarreBleue from '../../../assets/images/svg/PvImmo/BARRE_VERTICALE_BLEUE.png';
import FlecheBleue from '../../../assets/images/svg/PvImmo/FLECHE_BLEUE.png';
import { pvParticulierData } from './TableauFicheThematique/tableauPvParticulier';
import { tableauAbattement1, tableauAbattement2 } from './TableauFicheThematique/tableauAbattement';
import { pvProfessionnelleData } from './TableauFicheThematique/tableauPvProfessionnelle';
import { synthese1Data, synthese2Data } from './TableauFicheThematique/synthese';
import { defineSlideMaster } from '../../utils';

const exportFichePVImmo = async (profil) => {
    const pptx = new PPTX();
    await defineSlideMaster(pptx);

    // SLIDE1
    const slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    const slide1Title = [
        { text: "IMPOSITION DES INVESTISSEMENTS LOCATIFS", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "Synthèse des régimes applicables", options: { breakLine: true } }
    ]

    const slide1Text = [
        { text: "1- Imposition des revenus locatifs", options: { breakLine: true, bold: true, color: '4472C4' } },
        { text: "", options: { breakLine: true } },
        { text: "Revenus fonciers", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
        { text: "Location meublée - BIC", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
        { text: "Impôt sur les sociétés", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
        
        { text: "", options: { breakLine: true } },
        
        { text: "2- Imposition de la plus-value", options: { breakLine: true, bold: true, color: '4472C4' } },
        { text: "", options: { breakLine: true } },
        { text: "Plus-value immobilière des particuliers", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
        { text: "Plus-value professionnelle", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
        
        { text: "", options: { breakLine: true } },
        
        { text: "3- Synthèse", options: { breakLine: true, bold: true, color: '4472C4' } },
        { text: "", options: { breakLine: true } },
        { text: "Fiscalité des revenus locatifs", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
        { text: "Fiscalité des plus-values de cession", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
    ]

    const xSlide1 = 3.8;
    const ySlide1 = 2;

    generateSlide1SimulateurTitle(pptx, slide1, slide1Title, "CS", xSlide1 - 1.4, ySlide1 - 1, 'FICHE');
    generateThreeBarres(slide1, xSlide1 + 1, ySlide1 + 0.6, 4);

    slide1.addText(slide1Text, {
        x: xSlide1 + 1.3,
        y: ySlide1 + 0.6,
        w: 5,
        h: 4,
        fontSize: 11,
        fontFace: 'Roboto',
        align: 'justify',
        color: '5271C7',
        lineSpacingMultiple: 1.3
    })


    // SLIDE 2
    const slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide2,
        "CS",
        {
            text: "IMPOSITION DES REVENUS LOCATIFS",
            width: 5
        },
        {
            x: 5,
            text: "    REVENUS FONCIERS",
            width: 4
        }
    )

    slide2.addTable(revenusFonciersDataFichePVImmo, {
        x: 0.6,
        y: 1.5,
        colW: [ 1.7, 0.2, 6.6 ],
        rowH: [ 
            0.6, 
            0, 
            2.5, 
            0, 
            1.5, 
            0, 
            0.6
        ]
    })

    slide2.addText(
        [
            { text: "Sont soumis au régime des revenus fonciers", options: { bold: true, breakLine: true } },
            { text: "", options: { breakLine: true } },
            { text: "Les revenus issus de la location d’immeubles nus, perçus par une personne physique ou une personne morale soumise à l’impôt sur le revenu." }
        ],
        {
            x: 9.4,
            y: 1.5,
            fontSize: 11,
            fontFace: 'Roboto',
            w: 2,
            h: 2.5,
            fill: noteBackgroundFichePVImmo
        }
    )

    slide2.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 2.35,
        y: 5,
        fill: barreOrangeBackgroundFichePVImmo,
        w: 0.06,
        h: 1,
        rectRadius: 5
    });
    
    slide2.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 2.35,
        y: 6.15,
        fill: barreGreenBackgrounndFichePVImmo,
        w: 0.06,
        h: 0.4,
        rectRadius: 5
    });

    // SLIDE3
    const slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide3,
        "CS",
        {
            text: "IMPOSITION DES REVENUS LOCATIFS",
            width: 5
        },
        {
            x: 5,
            text: "    LOCATION MEUBLÉE -  BIC",
            width: 4
        }
    )
    
    slide3.addTable(locationMeubleeData, {
        x: 0.6,
        y: 1.5,
        colW: [ 1.7, 0.2, 6.6 ],
        rowH: [ 0.6, 0, 5 ]
    })
    
    slide3.addText(
        [
            { text: "Sont soumis au régime BIC", options: { bold: true, breakLine: true } },
            { text: "", options: { breakLine: true } },
            { text: "Les revenus issus de la location d’immeubles meublés, et perçus par une personne physique (en entreprise individuelle) ou une personne morale commerciale soumise à l’impôt sur le revenu." }
        ],
        {
            x: 9.4,
            y: 1.5,
            fontSize: 11,
            fontFace: 'Roboto',
            w: 2,
            h: 2.5,
            fill: noteBackgroundFichePVImmo
        }
    )
    
    slide3.addText(
        [
            { text: "Limite de déduction des amortissements au régime réel", options: { bold: true, breakLine: true } },
            { text: "", options: { breakLine: true } },
            { text: "Les amortissements déductibles sont limités à la différence entre les loyers et les charges afférentes au bien. L’amortissement ne peut ainsi pas à lui seul créer un déficit." }
        ],
        {
            x: 9.4,
            y: 4.4,
            fontSize: 11,
            fontFace: 'Roboto',
            w: 2,
            h: 2.5,
            fill: 'FBE5D6'
        }
    )

    slide3.addImage({
        path: OrangeInfo,
        x: 7.15,
        y: 6.05,
        w: 0.25,
        h: 0.25
    })
    
    slide3.addImage({
        path: OrangeInfo,
        x: 9.3,
        y: 4.3,
        w: 0.25,
        h: 0.25
    })

    // SLIDE4
    const slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide4,
        "CS",
        {
            text: "IMPOSITION DES REVENUS LOCATIFS",
            width: 5
        },
        {
            x: 5,
            text: "    LOCATION MEUBLÉE -  BIC",
            width: 4
        }
    )
    
    slide4.addTable(locationMeubleeData2, {
        x: 0.5,
        y: 1.5,
        colW: [ 1.7, 0, 3.3, 0, 3.3 ],
        rowH: [ 0.6, 0, 2.2, 0, 0.6 ]
    })
    
    slide4.addText(
        [
            { text: "STATUT DU LOUEUR EN MEUBLÉ AU SENS DE L’IR ET DE LA PLUS-VALUE", options: { breakLine: true, bold: true } },
            { text: "", options: { breakLine: true } },
            { text: "Au sens de l’impôt sur le revenu et de la plus-value, le loueur en meublé peut être considéré comme ", options: { breakLine: false } },
            { text: "professionnel (LMP) ", options: { bold: true, breakLine: false } },
            { text: "ou ", options: { breakLine: false } },
            { text: "non professionnel (LMNP).", options: { breakLine: false, bold: true } },
            { text: "Le passage d’un statut à l’autre est ", options: { breakLine: false } },
            { text: "automatique", options: { bold: true } },
            { text: "", options: { breakLine: true } },
            { text: "", options: { breakLine: true } },
            { text: "Le ", options: { breakLine: false } },
            { text: "foyer fiscal ", options: { breakLine: false, bold: true } },
            { text: "est LMP si deux conditions sont remplies :", options: { breakLine: true } },
            { text: "Les recettes de location meublée perçues par le foyer fiscal sont supérieures à 23.000€", options: { breakLine: true, bullet: { code: '25CF' } } },
            { text: "", options: { breakLine: true } },
            { text: "Les recettes de location meublée sont supérieures aux autres revenus professionnels du foyer fiscal", options: { breakLine: true, bullet: { code: '25CF' } } },
        ],
        {
            x: 9.4,
            y: 1.5,
            fontSize: 11,
            fontFace: 'Roboto',
            color: blackColor,
            w: 2,
            h: 5.7,
            fill: noteBackgroundFichePVImmo
        }
    )

    slide4.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 2.25,
        y: 2.3,
        fill: barreOrangeBackgroundFichePVImmo,
        w: 0.06,
        h: 0.58,
        rectRadius: 5
    });
    
    slide4.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 2.25,
        y: 3.1,
        fill: barreGreenBackgrounndFichePVImmo,
        w: 0.06,
        h: 1.7,
        rectRadius: 5
    });

    // SLIDE5
    const slide5 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide5,
        "CS",
        {
            text: "IMPOSITION DES REVENUS LOCATIFS",
            width: 5
        },
        {
            x: 5,
            text: "    LOCATION MEUBLÉE -  BIC",
            width: 4
        }
    )

    slide5.addText(
        [
            { text: "SPÉCIFICITÉS AU TITRE DE L’IFI", options: { breakLine: true, fontSize: 12, bold: true } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: "Que le loueur soit considéré comme un LMP ou LMNP, les biens donnés en location meublée peuvent être exonérés d’IFI en tant que biens professionnels si trois conditions sont respectées :", options: { breakLine: true, bold: true, fontSize: 11 } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: "Les recettes de location meublée perçues par le foyer fiscal sont supérieures à 23.000 €", options: { breakLine: true, bullet: { code: '25CF', fontSize: 11 } } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: "Les revenus nets tirés de la location meublée sont supérieurs aux autres revenus professionnels du foyer fiscal.", options: { breakLine: true, bullet: { code: '25CF', fontSize: 11 } } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: " ", options: { bullet: true, color: noteBackgroundFichePVImmo } },
            { text: "Il s’agit des revenus nets : si la location meublée présente un deficit, l’exonération est impossible.", options: { breakLine: true, italic: true } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: "L’activité de location meublée est ", options: { bullet: { code: '25CF' }, breakLine: false, fontSize: 11 } },
            { text: "exercée à titre professionnel et principal ", options: { breakLine: false, bold: true, fontSize: 11 } },
            { text: "par le contribuable ou un membre de son foyer fiscal IFI.", options: {fontSize: 11} }
        ],
        {
            x: 0.6,
            y: 2,
            w: '44%',
            fontFace: 'Roboto',
            fontSize: 11,
            color: blackColor,
            valign: 'top',
            margin: 10,
            h: 4,
            fill: noteBackgroundFichePVImmo
        }
    )

    slide5.addText(
        [
            { text: "SPÉCIFICITÉS DE LA LOCATION MEUBLÉE EXERCÉE PAR UNE PERSONNE MORALE (EURL, SARL, SNC)", options: { bold: true, fontSize: 12, breakLine: true } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: "Imposition des revenus", options: { breakLine: true, bold: true } },
            { text: "Régime réel uniquement.", options: { breakLine: true } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: "", options: { breakLine: true, fontSize: 11 } },
            { text: "Charges sociales", options: { breakLine: true, bold: true } },
            { text: "L’affiliation à un régime de sécurité sociale ne dépend pas du statut LMP/LMNP, mais des conditions d’affiliation de droit commun des gérants. Notamment, un gérant minoritaire de SARL est redevable des prélèvements sociaux, tandis qu’un gérant majoritaire est redevable des cotisations sociales.", options: { breakLine: true } },
        ],
        {
            x: 6,
            y: 2,
            w: '44%',
            fontFace: 'Roboto',
            fontSize: 11,
            margin: 10,
            valign: 'top',
            color: blackColor,
            h: 4,
            fill: noteBackgroundFichePVImmo
        }
    )

    slide5.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.75,
        y: 2.4,
        fill: '44546A',
        rectRadius: 10,
        w: 0.8,
        h: 0.07
    })
    
    slide5.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 6.15,
        y: 2.6,
        fill: '44546A',
        rectRadius: 10,
        w: 0.8,
        h: 0.08
    })

    // SLIDE6
    const slide6 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide6,
        "CS",
        {
            text: "IMPOSITION DES REVENUS LOCATIFS",
            width: 5
        },
        {
            x: 5,
            text: "    IMPÔT SUR LES SOCIÉTÉS",
            width: 4
        }
    )
    
    slide6.addTable(impotSocieteData, {
        x: 0.5,
        y: 1.5,
        colW: [ 1.7, 0.2, 8.8 ],
        rowH: [ 
            0.6, 
            0, 
            1, 
            0, 
            0.5,
            0.8, 
            0, 
            0.5
        ]
    })

    slide6.addText(
        [
            { text: "Régime d’imposition des revenus perçus par une ", options: { breakLine: false } },
            { text: "entreprise individuelle ou une personne morale soumise à l’impôt sur les sociétés, ", options: { breakLine: false, bold: true } },
            { text: "qu’il s’agisse d’une location nue ou meublée.", options: {} }
        ],
        {
            x: 9.2,
            y: 0.3,
            fontSize: 11,
            fontFace: 'Roboto',
            color: blackColor,
            valign: 'top',
            margin: 10,
            w: 2.2,
            h: 2.2,
            fill: noteBackgroundFichePVImmo
        }
    )
    
    slide6.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 2.3,
        y: 3.9,
        fill: barreOrangeBackgroundFichePVImmo,
        w: 0.06,
        h: 0.45,
        rectRadius: 5
    });
    
    slide6.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 2.3,
        y: 4.58,
        fill: barreGreenBackgrounndFichePVImmo,
        w: 0.06,
        h: 0.5,
        rectRadius: 5
    });

    // Barre verticale bleue
    slide6.addImage({
        path: BarreBleue,
        x: 2.47,
        y: 5.1,
        w: 0.01,
        h: 0.56
    })
    
    // Première flèche
    slide6.addImage({
        path: FlecheBleue,
        x: 2.47,
        y: 5.15,
        w: 0.6,
        h: 0.3
    })

    // Deuxième flèche
    slide6.addImage({
        path: FlecheBleue,
        x: 2.47,
        y: 5.48,
        w: 0.6,
        h: 0.3
    })

    // Texte Réserves
    slide6.addText("Réserves", {
        x: 2.9,
        y: 5.2,
        w: 1,
        h: 0.2,
        fontSize: 11,
        fontFace: 'Roboto',
        align: 'center',
        color: 'FFFFFF',
        bold: true,
        fill: '8497B0'
    })

    // Texte Dividendes
    slide6.addText("Dividendes", {
        x: 2.9,
        y: 5.53,
        w: 1,
        h: 0.2,
        fontSize: 11,
        fontFace: 'Roboto',
        align: 'center',
        color: 'FFFFFF',
        bold: true,
        fill: '4472C4'
    })
    
    slide6.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 4.2,
        y: 5.53,
        fill: '4472C4',
        w: 0.06,
        h: 1.04,
        rectRadius: 5
    });

    // SLIDE 7
    const slide7 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
    
    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide7,
        "CS",
        {
            text: "IMPOSITION DE LA PLUS-VALUE",
            width: 5
        },
        {
            x: 5,
            text: "    PLUS-VALUE IMMOBILIÈRE DES PARTICULIERS",
            width: 6
        }
    )

    slide7.addText(
        [
            { text: "La plus-value est constituée de la ", options: { breakLine: false } },
            { text: "différence entre le prix de cession et le prix d’acquisition. ", options: { breakLine: false, bold: true } },
            { text: "Chacune de ces valeurs est retraitée afin de tenir compte de différents frais et d’abattements pour durée de détention." }
        ],
        {
            x: 0.6,
            y: 1.5,
            fontSize: 11,
            fontFace: 'Roboto',
            w: '90%',
            h: 0.4
        }
    )
    
    slide7.addTable(pvParticulierData, {
        x: 0.6,
        y: 2.1,
        colW: [ 1.7, 0.2, 8.5 ],
        /*rowH: [ 
            0.7, 
            0.0001, 
            2.5, 
            0, 
            1.5, 
            0, 
            0.7 
        ]*/
    })

    // SLIDE 8
    const slide8 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide8,
        "CS",
        {
            text: "IMPOSITION DE LA PLUS-VALUE",
            width: 5
        },
        {
            x: 5,
            text: "    PLUS-VALUE IMMOBILIÈRE DES PARTICULIERS",
            width: 6
        }
    )

    slide8.addText("ABATTEMENTS POUR DURÉE DE DETENTION", {
        x: 0.6,
        y: 1.5,
        w: '90%',
        h: 0.3,
        align: 'center',
        fontFace: 'Roboto',
        fontSize: 13,
        bold: true
    })

    const rowH = 0.3;
    slide8.addTable(tableauAbattement1, {
        x: 0.6,
        y: 2,
        colW: [ 1.6, 1.6, 1.6 ],
        rowH: [
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
        ],
    })
    
    slide8.addTable(tableauAbattement2, {
        x: 6,
        y: 2,
        colW: [ 1.6, 1.6, 1.6 ],
        rowH: [
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
            rowH,
        ],
    })

    slide8.addText("Exemple de lecture : en cas de cession d’un bien détenu 12 ans, la plus-value est réduite de 42% pour l’impôt sur le revenu et 11,55% pour les prélèvements sociaux.", {
        x: 0.6,
        y: 6.8,
        fontSize: 11,
        fontFace: 'Roboto',
        w: '90%',
        h: 0.4
    })

    // SLIDE 9
    const slide9 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide9,
        "CS",
        {
            text: "IMPOSITION DE LA PLUS-VALUE",
            width: 5
        },
        {
            x: 5,
            text: "    PLUS-VALUE PROFESSIONNELLE",
            width: 5
        }
    )

    slide9.addText(
        [
            { text: "La vente d’un bien immobilier par une société soumise à l’impôt sur les sociétés ou par un LMP entraîne le calcul d’une ", options: { breakLine: false } },
            { text: "plus-value professionnelle.", options: { bold: true, breakLine: true } },
            { text: "Ce régime distingue deux plus-values, de court et long terme.", options: { breakLine: true } },
            { text: "Les modalités de calcul et d’imposition sont cependant différentes selon qu’il s’agisse d’une activité soumise aux BIC ou à l’impôt sur les sociétés." },
        ],
        {
            x: 0.6,
            y: 1.5,
            fontFace: 'Roboto',
            fontSize: 11,
            w: '90%',
            h: 0.6
        }
    )

    slide9.addTable(pvProfessionnelleData, {
        x: 0.6,
        y: 2.3,
        colW: [ 1.7, 0, 3.6, 0, 3.6 ]
    })

    // SLIDE 10
    const slide10 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide10,
        "CS",
        {
            text: "SYNTHESE",
            width: 3
        }
    )

    slide10.addText("IR : impôt sur le revenu  ;  PS : prélèvements sociaux  ;  CS : cotisations sociales", {
        x: 0.6,
        y: 7,
        w: '90%',
        h: 0.3,
        fontSize: 11,
        fontFace: 'Roboto',
        fill: labelBackgroundFichePVImmo
    })

    slide10.addTable(synthese1Data, {
        x: 0.6,
        y: 1.5,
        colW: [ 1.4, 2.7, 2, 2, 2.7 ]
    })

    slide10.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.5,
        y: 3.9,
        fill: barreOrangeBackgroundFichePVImmo,
        w: 0.06,
        h: 1.1,
        rectRadius: 5
    });
    
    slide10.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.5,
        y: 5,
        fill: barreGreenBackgrounndFichePVImmo,
        w: 0.06,
        h: 0.85,
        rectRadius: 5
    });
    
    slide10.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.5,
        y: 5.85,
        fill: '4472C4',
        w: 0.06,
        h: 0.85,
        rectRadius: 5
    });

    // SLIDE11
    const slide11 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide11,
        "CS",
        {
            text: "SYNTHESE",
            width: 3
        }
    )
    
    slide11.addTable(synthese2Data, {
        x: 0.6,
        y: 1.5,
        colW: [ 1.4, 2.7, 2, 2, 2.7 ]
    })
    
    slide11.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.5,
        y: 5.2,
        fill: 'FFC000',
        w: 0.06,
        h: 1,
        rectRadius: 5
    });
    
    slide11.addText("IR : impôt sur le revenu  ;  PS : prélèvements sociaux  ;  CS : cotisations sociales  ;   VNC : valeur nette comptable", {
        x: 0.6,
        y: 6.5,
        w: '90%',
        h: 0.3,
        fontSize: 11,
        fontFace: 'Roboto',
        fill: labelBackgroundFichePVImmo
    })

    // Dernier SLIDE
    const lastSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
    // const profil = await getInfoClient();

    generateLastPage(pptx, lastSlide, profil);

    pptx.writeFile("Fiche_REV_LOCATIF_" + completeDate(now) + ".pptx");
}

export default exportFichePVImmo;