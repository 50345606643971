import PPTX from 'pptxgenjs';
import { currencyToSend } from '../../../store/utilsFunc';
import { blackColor, completeDate, fetchPPTPersonnalise, generateLastPage, generateSlide1SimulateurTitle, generateThreeBarres, now, numberFormat } from '../../all_ppt_render';
import rowsAmortissements from './tableauAmortissement';
import { generateTitleAndSubtitle, generateFirstSlideTitle, defineSlideMaster } from '../../utils'

const typeEmprunt = (index) => {
    switch(index){
        case 0:
            return "Amortissable avec annuités constantes";
        case 1:
            return "Amortissable avec amortissements constants";
        default:
            return "In fine";
    }
}

const periodiciteEcheance = (index) => {
    switch(index){
        case 0:
            return "Mensuelle";
        case 1:
            return "Trimestrielle";
        case 2:
            return "Semestrielle";
        default:
            return "Annuelle"
    }
}

const exportPPTEmprunt = async (profil, hypothese, resultat, typeTableauAmortissement) => {
    const pptx = new PPTX();
    await defineSlideMaster(pptx);

    // SLIDE 1
    const slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    const slide1Title = [
        { text: "SIMULATION D’EMPRUNT", options: { breakLine: true } },
    ];

    const slide1Text = [
        { text: "CARACTÉRISTIQUES", options: { breakLine: true, bullet: { code: '25CF' }, color: '4472C2' } },
        { text: "", options: { breakLine: true } },
        { text: "CHIFFRES CLÉS", options: { breakLine: true, bullet: { code: '25CF' }, color: '4472C2' } },
        { text: "", options: { breakLine: true } },
        { text: "TABLEAU D’AMORTISSEMENT", options: { breakLine: true, bullet: { code: '25CF' }, color: '4472C2' } },
    ]
    
    const xSlide1 = 3.8;
    const ySlide1 = 2;

    generateFirstSlideTitle(
        pptx,
        slide1,
        slide1Title,
        xSlide1 - 1.4,
        ySlide1 - 1,
        "SIMULATION",
        xSlide1 - 1
    )
    
    generateThreeBarres(slide1, xSlide1 + 0.4, ySlide1 + 0.6, 1.5);

    slide1.addText(slide1Text, {
        x: xSlide1 + 0.7,
        y: ySlide1 + 0.6,
        w: 5,
        h: 1.5,
        fontSize: 12,
        bold: true,
        fontFace: 'Roboto',
        align: 'justify',
        color: '5271C7'
    })

    // SLIDE 2
    const slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    const tableSlide2Props = () => {
        return {
            fontSize: 11,
            fontFace: 'Roboto',
            valign: 'middle',
            fill: 'F2F2F2',
            color: blackColor,
            border: [
                {
                    pt: 0,
                    color: ''
                },
                {
                    pt: 1,
                    color: 'FFFFFF'
                },
                {
                    pt: 1,
                    color: 'FFFFFF'
                },
                {
                    pt: 0,
                    color: ''
                },
            ]
        }
    }
    
    generateTitleAndSubtitle(
        "SIMULATION",
        pptx,
        slide2,
        {
            text: "CARACTÉRISTIQUES DE L’EMPRUNT",
            width: 4.8
        }
    )

    const table1 = [
        [
            { text: "Date de l'emprunt", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.date_emprunt ? hypothese.date_emprunt : 'jj/mm/aaaa'}`, options: { ...tableSlide2Props() } }
        ],
        [
            { text: "Type d'emprunt", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.type_emprunt !== undefined ? typeEmprunt(hypothese.type_emprunt) : "" }`, options: { ...tableSlide2Props() } }
        ],
        [
            { text: "Montant de l'emprunt", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.montant_emprunt ? numberFormat(currencyToSend(hypothese.montant_emprunt)) : 0} €`, options: { ...tableSlide2Props() } }
        ],
        [
            { text: "Taux d'intérêt annuel", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.taux_annuel ? hypothese.taux_annuel : 0} %`, options: { ...tableSlide2Props() } }
        ],
        [
            { text: "Périodicité", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.periode_echeance !== undefined ? periodiciteEcheance(hypothese.periode_echeance) : "" }`, options: { ...tableSlide2Props() } }
        ],
        [
            { text: "Durée totale (nb de périodes)", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.duree ? hypothese.duree : 0}`, options: { ...tableSlide2Props() } }
        ]
    ];

    if(hypothese?.type_emprunt !== 2){
        table1.push(
            [
                { text: [{ text: "Différé d'amortissement", options: { breakLine: true } }, { text: "Nb de périodes différées" }], options: { ...tableSlide2Props() } },
                { text: `${hypothese && hypothese.nb_periode ? hypothese.nb_periode : 0}`, options: { ...tableSlide2Props() } }
            ]
        )
    }

    const table2 = [
        [
            { text: "Assurance", options: { ...tableSlide2Props(), fill: 'FFFFFF', bold: true } },
            { text: "", options: { ...tableSlide2Props(), fill: 'FFFFFF' } }
        ],
        [
            { text: "Taux de couverture décès", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.taux_deces ? hypothese.taux_deces : 0} %`, options: { ...tableSlide2Props() } }
        ],
        [
            { text: "Taux de la prime d'ass.", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.taux_prime ? hypothese.taux_prime : 0} %`, options: { ...tableSlide2Props() } }
        ],
        [
            { text: "Assiette de la prime d'ass.", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.assiette_prime !== undefined ? hypothese.assiette_prime === 0 ? "Capital emprunté" : "Capital restant dû" : ""}`, options: { ...tableSlide2Props() } }
        ],
        [
            { text: "", options: { ...tableSlide2Props(), fill: 'FFFFFF', margin: [ 2, 5, 10, 10 ] } },
            { text: "", options: { ...tableSlide2Props(), fill: 'FFFFFF', margin: [ 2, 5, 10, 10 ] } }
        ],
        [
            { text: "Frais de dossier et de garantie", options: { ...tableSlide2Props(), fill: 'FFFFFF', bold: true } },
            { text: "", options: { ...tableSlide2Props(), fill: 'FFFFFF' } }
        ],
        [
            { text: "en % du capital emprunté", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.frais_dossier_en_pourcentage ? hypothese.frais_dossier_en_pourcentage : 0} %`, options: { ...tableSlide2Props() } }
        ],
        [
            { text: "en €", options: { ...tableSlide2Props() } },
            { text: `${hypothese && hypothese.frais_dossier_en_euro ? hypothese.frais_dossier_en_euro : 0} €`, options: { ...tableSlide2Props() } }
        ],
    ]

    const rowH = 0.37;

    slide2.addTable(table1, {
        x: 0.7,
        y: 2.5,
        colW: [ 2.5, 2.5 ],
        margin: [ 10, 5, 10, 10 ]
    })
    
    slide2.addTable(table2, {
        x: 6.1,
        y: 2.5,
        colW: [ 2.5, 2.5 ],
        margin: [ 10, 5, 10, 10 ],
    })

    // SLIDE 3
    const slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    const tableSlide3Props = (withBlueBorder=false) => {
        return {
            valign: 'bottom',
            fill: 'FFFFFF',
            fontFace: 'Roboto',
            border: [
                {
                    pt: 0,
                    color: ''            
                },
                {
                    pt: 0,
                    color: ''            
                },
                {
                    pt: withBlueBorder ? 2.5 : 0,
                    color: '4472C4'            
                },
                {
                    pt: 0,
                    color: ''            
                },
            ]
        }
    }
    
    generateTitleAndSubtitle(
        "SIMULATION",
        pptx,
        slide3,
        {
            text: "CHIFFRES CLÉS",
            width: 3
        }
    )

    const tableCoutEmprunt = [
        [
            { text: "Coût total", options: { ...tableSlide3Props(true), bold: true, color: blackColor } },
            { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.cout_total ? numberFormat(resultat.chiffre_cle.cout_total) : 0 } €`, options: { ...tableSlide3Props(), color: '4472C4', bold: true, fontSize: 14, align: 'right' } }
        ],
        [
            { text: [{ text: "TAEG", options: { breakLine: true, bold: true } }, { text: "Taux annuel effectif global", options: { fontSize: 10 } }], options: { ...tableSlide3Props(true), color: blackColor } },
            { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.TAEG ? parseFloat(resultat.chiffre_cle.TAEG).toFixed(2) : 0} %`, options: { ...tableSlide3Props(), color: '4472C4', bold: true, fontSize: 14, align: 'right' }  }
        ],
        [
            { text: "Frais de dossier", options: { ...tableSlide3Props(), fontSize: 11, color: blackColor } },
            { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.frais_dossier ? numberFormat(resultat.chiffre_cle.frais_dossier) : 0 } €`, options: { ...tableSlide3Props(), color: 'F00020', fontSize: 11, align: 'right' } }
        ],
        [
            { text: "Coût intérêts", options: { ...tableSlide3Props(), fontSize: 11, color: blackColor } },
            { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.cout_interet ? numberFormat(resultat.chiffre_cle.cout_interet) : 0 } €`, options: { ...tableSlide3Props(), color: 'F00020', fontSize: 11, align: 'right' } }
        ],
        [
            { text: "Coût assurance", options: { ...tableSlide3Props(), fontSize: 11, color: blackColor } },
            { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.cout_assurance ? numberFormat(resultat.chiffre_cle.cout_assurance) : 0 } €`, options: { ...tableSlide3Props(), color: 'F00020', fontSize: 11, align: 'right' } }
        ],
    ];

    const tableCapitalRestant = [
        [
            { text: "Montant en euros", options: { ...tableSlide3Props(true), bold: true, color: blackColor } },
            { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.montant_en_euro ? numberFormat(resultat.chiffre_cle.montant_en_euro) : 0 } €`, options: { ...tableSlide3Props(), color: '4472C4', bold: true, fontSize: 14, align: 'right' } }
        ],
        [
            { text: "à la date du ", options: { ...tableSlide3Props(), fontSize: 10, color: blackColor } },
            { text: completeDate(now), options: { ...tableSlide3Props(), color: '4472C4', bold: true, fontSize: 12, align: 'right' }  }
        ],
    ];

    const avecAssurance1 = (parseInt(hypothese?.avec_assurance_amortiss) || 0) === 1 ? 'Oui' : 'Non';
    const avecAssurance2 = (parseInt(hypothese?.avec_assurance_amortiss_sup) || 0) === 1 ? 'Oui' : 'Non';

    const tableMontantEcheance = [
        [
            { text: "Périodicité", options: { ...tableSlide3Props(true), bold: true, color: blackColor } },
            { text: `${hypothese && hypothese.periode_echeance !== undefined ? periodiciteEcheance(hypothese.periode_echeance) : "" }`, options: { ...tableSlide3Props(), color: '4472C4', bold: true, align: 'right' } }
        ],
        [
            { text: [{ text: "Montant", options: { breakLine: true, bold: true } }, { text: "avec assurance", options: { fontSize: 10 } }], options: { ...tableSlide3Props(true) } },
            { text: [ { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.montant_echeance_amortiss ? numberFormat(resultat.chiffre_cle.montant_echeance_amortiss) : 0} €`, options: { breakLine: true } }, { text: avecAssurance1, options: { fontSize: 10 } } ], options: { ...tableSlide3Props(), color: '4472C4', bold: true, fontSize: 14, align: 'right' }  }
        ],
    ];
    
    const tableMontantEcheance2 = [
        [
            { text: "Périodicité", options: { ...tableSlide3Props(true), bold: true, color: blackColor } },
            { text: `${hypothese && hypothese.periode_echeance !== undefined ? periodiciteEcheance(hypothese.periode_echeance) : "" }`, options: { ...tableSlide3Props(), color: '4472C4', bold: true, align: 'right' } }
        ],
        [
            { text: [{ text: "Montant", options: { breakLine: true } }, { text: "durant le différé", options: { bold: true } }], options: { ...tableSlide3Props(true), color: blackColor } },
            { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.durant_differe ? numberFormat(resultat.chiffre_cle.durant_differe) : 0} €`, options: { ...tableSlide3Props(), color: '4472C4', bold: true, fontSize: 14, align: 'right' }  }
        ],
        [
            { text: [{ text: "Montant", options: { breakLine: true } }, { text: "après le différé", options: { bold: true } }], options: { ...tableSlide3Props(true), color: blackColor } },
            { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.apres_differe ? numberFormat(resultat.chiffre_cle.apres_differe) : 0} €`, options: { ...tableSlide3Props(), color: '4472C4', bold: true, fontSize: 14, align: 'right' }  }
        ],
        [
            { text: "Avec assurance", options: { ...tableSlide3Props(), fontSize: 10, color: blackColor } },
            { text: avecAssurance2, options: { ...tableSlide3Props(), color: '4472C4', bold: true, align: 'right', fontSize: 10 } }
        ],
    ];

    const tableMontantEcheance3 = [
        [
            { text: "Périodicité", options: { ...tableSlide3Props(true), bold: true, color: blackColor } },
            { text: `${hypothese && hypothese.periode_echeance !== undefined ? periodiciteEcheance(hypothese.periode_echeance) : "" }`, options: { ...tableSlide3Props(), color: '4472C4', bold: true, align: 'right' } }
        ],
        [
            { text: [{ text: "Amortissement", options: { breakLine: true } }, { text: "du capital" }], options: { ...tableSlide3Props(true), bold: true, color: blackColor } },
            { text: `${resultat && resultat.chiffre_cle && resultat.chiffre_cle.amortissement_capital ? numberFormat(resultat.chiffre_cle.amortissement_capital) : 0} €`, options: { ...tableSlide3Props(), color: '4472C4', bold: true, align: 'right' } }
        ],
    ]

    // Premier tableau
    slide3.addTable(tableCoutEmprunt, {
        x: 0.6,
        y: 2.8,
        colW: [1.9, 1.35],
        rowH: [ 0.6, 0.8 , 0.52, 0.4, 0.4]
    })

    slide3.addText("COÛT DE L’EMPRUNT", {
        x: 0.6,
        y: 2.2,
        w: '20%',
        h: 0.4,
        fontFace: 'Roboto',
        fontSize: 14,
        bold: true,
        color: '333F50'
    });

    slide3.addText("CAPITAL RESTANT DÛ", {
        x: 4.3,
        y: 2.2,
        w: '20%',
        h: 0.4,
        fontFace: 'Roboto',
        fontSize: 14,
        bold: true,
        color: '333F50'
    });
    
    slide3.addText("MONTANT ÉCHÉANCE", {
        x: 8,
        y: 2.2,
        w: '20%',
        h: 0.4,
        fontFace: 'Roboto',
        fontSize: 14,
        bold: true,
        color: '333F50'
    });

    slide3.addText("", {
        x: 0.5,
        y: 2.65,
        w: 3.5,
        h: 3.5,
        line: {
            pt: 2,
            color: 'D6DCE5'
        }
    });
    
    // Second tableau
    slide3.addTable(tableCapitalRestant, {
        x: 4.3,
        y: 2.8,
        colW: [1.9, 1.35],
        rowH: [ 0.6, 0.5 ]
    })
    
    slide3.addText("", {
        x: 4.2,
        y: 2.65,
        w: 3.5,
        h: 1.75,
        line: {
            pt: 2,
            color: 'D6DCE5'
        }
    });

    const condition1 = (hypothese.type_emprunt === 2 || (hypothese.type_emprunt === 0 && hypothese.nb_periode === 0));
    const condition2 = (hypothese.type_emprunt === 0 && hypothese.nb_periode > 0);
    const condition3 = (hypothese.type_emprunt === 1);

    // Cas 1 sur le Montant d'échéance
    if(condition1){
        slide3.addTable(tableMontantEcheance, {
            x: 8,
            y: 2.8,
            colW: [1.9, 1.35],
            rowH: [ 0.6, 0.7 ]
        })
        
        slide3.addText("", {
            x: 7.9,
            y: 2.65,
            w: 3.5,
            h: 1.75,
            line: {
                pt: 2,
                color: 'D6DCE5'
            }
        });
    }

    // Cas 2 sur le Montant d'échéance
    if(condition2){
        slide3.addTable(tableMontantEcheance2, {
            x: 8,
            y: 2.8,
            colW: [1.9, 1.35],
            rowH: [ 0.6, 0.7, 0.7, 0.6 ]
        })

        slide3.addText("", {
            x: 7.9,
            y: 2.65,
            w: 3.5,
            h: 3.5,
            line: {
                pt: 2,
                color: 'D6DCE5'
            }
        });
    }
    
    // Cas 3 sur le Montant d'échéance
    if(condition3){
        slide3.addTable(tableMontantEcheance3, {
            x: 8,
            y: 2.8,
            colW: [1.9, 1.35],
            rowH: [ 0.6, 0.7 ]
        })

        slide3.addText("", {
            x: 7.9,
            y: 2.65,
            w: 3.5,
            h: 1.75,
            line: {
                pt: 2,
                color: 'D6DCE5'
            }
        });
    }

    // SLIDE 4
    if(typeTableauAmortissement === 1){
        const amorts = rowsAmortissements(resultat?.tableau_amortissement || [], false, hypothese.periode_echeance === 3, resultat?.somme_tableau_amortissement || {});
        const colW = 1.1;
    
        amorts.map((data, index) => {
            const slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
            
            generateTitleAndSubtitle(
                "SIMULATION",
                pptx,
                slide4,
                {
                    text: "TABLEAU D'AMORTISSEMENT",
                    width: 4
                }
            )
            const colWidth = [
                0.64,
                colW,
                colW,
                colW,
                colW,
                colW,
                colW,
                colW,
                colW
            ];

            if(hypothese.periode_echeance === 3){
                colWidth.push(colW)
            }
    
            slide4.addTable(data, {
                x: 0.6,
                y: 1.4,
                colW: colWidth
            })
        })
    }

    // SLIDE 5 => Tableau d'amortissement annualisé
    if(typeTableauAmortissement === 2){
        const amortissementsAnnualises = rowsAmortissements(resultat?.tableau_amortissement_annualise || [], true, true, resultat?.somme_tableau_amortissement || {})
        const colW = 1.1;
    
        amortissementsAnnualises.map((data, index) => {
            const slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
            
            generateTitleAndSubtitle(
                "SIMULATION",
                pptx,
                slide4,
                {
                    text: "TABLEAU D'AMORTISSEMENT ANNUALISÉ",
                    width: 5.2
                }
            )
    
            slide4.addTable(data, {
                x: 0.6,
                y: 1.4,
                colW: [
                    0.64,
                    colW,
                    colW,
                    colW,
                    colW,
                    1.4,
                    1.4
                ]
            })
        })
    }

    // Dernière SLIDE
    const lastSlide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
    // const profil = await getInfoClient();

    generateLastPage(pptx, lastSlide, profil);

    pptx.writeFile("Emprunt_" + completeDate(now) + ".pptx");
}

export default exportPPTEmprunt;