import React from 'react';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import './style.scss';

const Bloc1 = (props) => {
    const  { data } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
            <div style={{ fontWeight: 'bold', borderBottom: '2px solid #D8D8D8', padding: 8 }} >Coût de l'emprunt</div>
            <div style={{ height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }} >
                
                <div style={{ display: 'flex', height: '50px', lineHeight: '50px' }} >
                    <div style={{ width: '45%' }} >Coût total</div>
                    <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                        {
                            data &&
                            data.cout_total ? numberFormat(data.cout_total) : 0
                        } €
                    </div>
                </div>

                <div className='cadreRose' ></div>

                <div style={{ marginTop: 13 }} >TAEG</div>

                <div style={{ display: 'flex', height: '25px', lineHeight: '25px', marginTop: -7 }} >
                    <div style={{ width: '45%', fontSize: 11 }} >
                        Taux annuel effectif global
                    </div>
                    <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                        {
                            data &&
                            data.TAEG ? parseFloat(data.TAEG).toFixed(2) : 0
                        } %
                    </div>
                </div>

                <div className='cadreRouge' ></div>

                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between' }} >
                    <div style={{ width: '33.33%' }} >
                        <div style={{ color: 'rgb(240,79,108)', marginBottom: 2 }} >
                        {
                            data &&
                            data.frais_dossier ? numberFormat(data.frais_dossier) : 0
                        } €
                    </div>
                        <div>Frais de dossier</div>
                    </div>
                    <div style={{ width: '33.33%' }} >
                        <div style={{ color: 'rgb(240,79,108)', marginBottom: 2 }} >
                        {
                            data &&
                            data.cout_interet ? numberFormat(data.cout_interet) : 0
                        } €
                    </div>
                        <div>Coût intérêts</div>
                    </div>
                    <div style={{ width: '33.33%' }} >
                        <div style={{ color: 'rgb(240,79,108)', marginBottom: 2 }} >
                        {
                            data &&
                            data.cout_assurance ? numberFormat(data.cout_assurance) : 0
                        } €
                    </div>
                        <div>Coût assurance</div>
                    </div>
                </div>


            </div>
        </div>
    )
};

export default Bloc1;