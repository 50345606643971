import React from 'react';

const SwitchWithLabels = ({
    width,
    label1,
    label2,
    type,
    onRequestChoose
}) => {
    const scenarioStyle = (scenario) => {
        return {
            width: '50%',
            background: 'red', 
            textAlign: 'center', 
            padding: 9, 
            color: type === scenario && '#FFFFFF', 
            fontWeight: 'bold', 
            cursor: type !== scenario && 'pointer',
            background: type === scenario ? 'linear-gradient(rgb(43,152,85),rgb(106,178,78))' : '#FFFFFF',
            borderRadius: '15px',
            boxShadow: type !== scenario && 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
            position: 'absolute',
            border: type !== scenario && '1px solid #808080',
            fontSize: 13
        }
    }

    return (
        <div style={{ display: 'flex', position: 'relative', width: width }}>
            <div style={{ ...scenarioStyle(1), ...(type === 1 ? { left: 0, zIndex: 4 } : { left: 15, zIndex: 2 }) }} onClick={() => onRequestChoose(1) } >{label1}</div>
            <div style={{ ...scenarioStyle(2), ...(type === 1 ? { right: 15, zIndex: 2 } : { right: 0, zIndex: 4 }) }} onClick={() => onRequestChoose(2) } >{label2}</div>
        </div>
    )
}

export default SwitchWithLabels;