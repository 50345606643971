import CIcon from '@coreui/icons-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import DataBlock from '../../../../components/DataBlock/DataBlock';
import SwitchWithLabels from '../../../../components/SwitchWithLabels/SwitchWithLabels';
import { numberFormat } from '../../../../extra/all_ppt_render';
import './tableau.scss'
import { activePageBlock } from '../../../../store/actions/simulateur';
import { SimulateurContext } from '../../../../store/context/simulateur';

const paddingRight = {
    paddingRight: 25
}

const TableauAmortissement = (props) => {
    const { simulateurDispatch } = useContext(SimulateurContext);
    const { data: rows, openIFI, type, setType, conditionAffichageTableauAmortissementAnnualise, periodicite, somme } = props;

    useEffect(() => {
        if (!conditionAffichageTableauAmortissementAnnualise) {
            setType(1);
        }
    }, [conditionAffichageTableauAmortissementAnnualise])

    const styleCommun = {
        width: '12.5%',
        background: 'rgb(88, 92, 102)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        padding: 4
    }

    const styleCommunData = {
        width: '12.5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'right',
        padding: 4
    }

    let titresData = [
        <div style={{ ...styleCommun, textAlign: 'center', width: '8%' }} >{type === 1 ? "Période n°" : "N°année"}</div>,
        <div style={{ ...styleCommun, textAlign: 'center' }} >{type === 1 ? "Date échéance" : "Année"}</div>,
        <div style={{ ...styleCommun }} >Capital restant dû <br />avant échéance</div>,
        <div style={{ ...styleCommun, textAlign: 'center' }} >Intérêts</div>,
        <div style={{ ...styleCommun, textAlign: 'center' }} >Capital remboursé</div>,
        <div style={{ ...styleCommun, textAlign: 'center' }} >Assurance</div>,
        <div style={{ ...styleCommun }} >Echéance <br /> hors assurance</div>,
        <div style={{ ...styleCommun, marginRight: 2 }} >Echéance <br /> avec assurance</div>,
        type === 1 ? <div style={{ ...styleCommun, background: 'rgb(156,156,156)', marginRight: 2 }} >Capital restant dû <br />après échéance</div> : null,
        (type === 2 || periodicite === 3) ? <div style={{ ...styleCommun, background: 'rgb(156,156,156)' }} >Mt déductible <br />pour l'IFI</div> : null
    ]

    titresData = titresData.filter((element) => element !== null);

    return (
        <DataBlock
            title="TABLEAU D'AMORTISSEMENT"
            withCloseButton={true}
            rightTitle={
                <>
                    {
                        conditionAffichageTableauAmortissementAnnualise && (
                            <div style={{ position: 'absolute', top: 5, right: 45 }} >
                                <SwitchWithLabels
                                    width="230px"
                                    label1="PÉRIODIQUE"
                                    label2="ANNUEL"
                                    type={type}
                                    onRequestChoose={(value) => {
                                        simulateurDispatch(activePageBlock())
                                        setType(value);
                                    }}
                                />
                            </div>
                        )
                    }
                </>
            }
            bodyStyle={{
                paddingTop: 5,
                paddingLeft: 4
            }}
        >
            <div className='tableau'>
                <div className='dataTitre' style={{ width: '100%' }} >
                    {
                        titresData.map((data, index) => {
                            return (
                                <>{data}</>
                            )
                        })
                    }
                </div>
                {
                    rows.map((data, index) => {
                        return (
                            <div className='data' style={{ width: '100%' }} >
                                <div style={{ ...styleCommunData, textAlign: 'center', width: '8%' }} >{data?.[type === 1 ? 'periode' : 'num_annee'] || 0}</div>
                                <div style={{ ...styleCommunData, textAlign: 'center' }} >{type === 1 ? data.date_echeance ? moment(new Date(data.date_echeance)).format('DD/MM/YYYY') || 'JJ/MM/AAAA' : "JJ/MM/AAAA" : data?.annee || 0}</div>
                                <div style={{ ...styleCommunData, ...paddingRight }} >{data.capital_restant_avant ? numberFormat(data.capital_restant_avant) : '-'} €</div>
                                <div style={{ ...styleCommunData, ...paddingRight }} >{data.interet ? numberFormat(data.interet) : '-'} €</div>
                                <div style={{ ...styleCommunData, ...paddingRight }} >{data.capital_rembourse ? numberFormat(data.capital_rembourse) : '-'} €</div>
                                <div style={{ ...styleCommunData, ...paddingRight }} >{data.assurance ? numberFormat(data.assurance) : '-'} €</div>
                                <div style={{ ...styleCommunData, ...paddingRight }} >{data.echeance_hors_assurance ? numberFormat(data.echeance_hors_assurance) : '-'} €</div>
                                <div style={{ ...styleCommunData, ...paddingRight, marginRight: 2 }} >{data.echeance_avec_assurance ? numberFormat(data.echeance_avec_assurance) : '-'} €</div>
                                {
                                    type === 1 && (
                                        <div style={{ ...styleCommunData, ...paddingRight }} >{data.capital_restant ? numberFormat(data.capital_restant) : '-'} €</div>
                                    )
                                }
                                {
                                    (type === 2 || periodicite === 3) && (
                                        <div style={{ ...styleCommunData, ...paddingRight }} >{data.montant_deductible ? numberFormat(data.montant_deductible) : '-'} €</div>
                                    )
                                }
                            </div>
                        )
                    })
                }
                {/** Ligne de TOTAL */}
                <div className='data' style={{ width: '100%' }}>
                    <div style={{ ...styleCommunData, textAlign: 'center', width: '8%' }} />
                    <div style={{ ...styleCommunData, textAlign: 'center' }} />
                    <div style={{ ...styleCommunData, ...paddingRight }} />
                    <div style={{ ...styleCommunData, ...paddingRight, color: '#FFFFFF', background: '#9C9C9C' }} >{numberFormat(somme?.interet || 0)} €</div>
                    <div style={{ ...styleCommunData, ...paddingRight, color: '#FFFFFF', background: '#9C9C9C' }} >{numberFormat(somme?.capital_rembourse || 0)} €</div>
                    <div style={{ ...styleCommunData, ...paddingRight, color: '#FFFFFF', background: '#9C9C9C' }} >{numberFormat(somme?.assurance || 0)} €</div>
                    <div style={{ ...styleCommunData, ...paddingRight, color: '#FFFFFF', background: '#9C9C9C' }} >{numberFormat(somme?.echeance_hors_assurance || 0)} €</div>
                    <div style={{ ...styleCommunData, ...paddingRight, color: '#FFFFFF', marginRight: 2, background: '#9C9C9C' }} >{numberFormat(somme?.echeance_avec_assurance || 0)} €</div>
                    {
                        type === 1 && (
                            <div style={{ ...styleCommunData, ...paddingRight }} />
                        )
                    }
                    {
                        (type === 2 || periodicite === 3) && (
                            <div style={{ ...styleCommunData, ...paddingRight }} />
                        )
                    }
                </div>
            </div>
        </DataBlock>
    )
}

export default TableauAmortissement;