export const successionDefaultData = JSON.parse(JSON.stringify({
    withCalcul: true,
    ordreDecesPatrimoineTransmis: 0,
    infoSimulation: {
        identifiant_simulation: "brouillon",
        clientId: null,
        commentaire: ""
    },
    "date_succession_alt": 0,
    "date_succession_alt_graphe": 6,
    config: {
        "date_succession": 0,
        "avecFicheClient": false,
        "avecBilanPatrimonial": false,
        "id_client": null,
        "id_patrimonial": null,
        "situation_familial": 1, // 0 :seul 1: marie 2: pasce
        "pourcentage_repartition_bien_commun": 50,
        "situation_matrimonial": 1, //0 : Clause de partage à 50%-50% 1:Clause d'attribution intégrale
        "date_heure_importation_fiche_client": "",
        "date_heure_importation_bilan_patrimonial": "",
        "version_fiche_client": null,
        "import_fiche_client": 0,
        "import_bilan_patrimonial": 0
    },
    matrice3: {
        "pat_global": {
            "conjoint1": {
                "actif_eligible": 0,
                "residence_principale": 0,
                "autre_actifs": 0,
                "passif": 0
            },
            "conjoint_commun": {
                "actif_eligible": 0,
                "residence_principale": 0,
                "autre_actifs": 0,
                "passif": 0
            },
            "conjoint2": {
                "actif_eligible": 0,
                "residence_principale": 0,
                "autre_actifs": 0,
                "passif": 0
            }
        },
        "assurance_vie": {
            "conjoint1": [
                {
                    "id": 1,
                    "intitule": "",
                    "capitaux_deces_net": 0,
                    "regime_fiscale": 0,
                    "montant_primes_versees_apres_70_ans": 0
                }
            ],
            "conjoint_commun": [
                {
                    "id": 1,
                    "intitule": "",
                    "capitaux_deces_net": 0,
                    "conjoint1": {
                        "regime_fiscale": 0,
                        "montant_primes_versees_apres_70_ans": 0
                    },
                    "conjoint2": {
                        "regime_fiscale": 0,
                        "montant_primes_versees_apres_70_ans": 0
                    }
                }
            ],
            "conjoint2": [
                {
                    "id": 1,
                    "intitule": "",
                    "capitaux_deces_net": 0,
                    "regime_fiscale": 0,
                    "montant_primes_versees_apres_70_ans": 0
                }
            ],
        }
    },
    beneficiaire: {
        "conjoint1": {
            "nom": "",
            "age": 0
        },
        "conjoint2": {
            "nom": "",
            "age": 0
        },
        "liste_enfant": [],
        "liste_petit_enfant": [],
        "liste_autre_benef":{
            "conjoint1":[],
            "conjoint2":[]
        },
    },
    "dev1": {
        "valorisation": 0,
        "exoneration_dutriel": 0,
        "abattement_sur_residence_principale": 0,
        "valeur_net_imposable": 0,
        "devolution_succession": 0,
        "valorisation_capitaux_deces": 0,
        "pourcentage_transmis_conjoint": 0,
        "liste_av": [
            {
                "id": 1,
                "intitule": "",
                "capitaux_deces_net": 0,
                "choix1": 0,
                "choix2": '',
                "choix3": [
                    {
                        "id": 0,
                        "taux": 100,
                        "choix1": 0,
                        "choix2": ""

                    }
                ]
            }
        ],

        "liste": {
            "autre_benef": [],
            "enfant": [],
            "petit_enfant": [],
            "conjoint": 0
        }
    },
    "dev2": {
        "patrimoine_propre": 0,
        "patrimoine_herite": 0,
        "exoneration_dutreil": 0,
        "valeur_nette_imposable": 0,
        "valorisation_succession": 0,
        "devolution_succession": 3,
        "valorisation_capitaux_deces": 0,
        "liste_av": [
            {
                "id": 1,
                "intitule": "",
                "capitaux_deces_net": 0,
                "choix1": 0,
                "choix2": '',
                "choix3": [
                    {
                        "id": 0,
                        "taux": 100,
                        "choix1": 0,
                        "choix2": ""

                    }
                ]
            }
        ],
        "liste_capi": [
            {
                "id": 1,
                "intitule": "",
                "capitaux_deces_net": 0,
                "choix1": 0,
                "choix2": '',
                "choix3": [
                    {
                        "id": 0,
                        "taux": 100,
                        "choix1": 0,
                        "choix2": ""

                    }
                ]
            }
        ],
        "liste":{
            "autre_benef": [],
            "enfant": [],
            "petit_enfant": [],
        }
    },
    "d2_dev1": {
        "valorisation": 0,
        "exoneration_dutriel": 0,
        "abattement_sur_residence_principale": 0,
        "valeur_net_imposable": 0,
        "patrimoine_total": 0,
        "devolution_succession": 0,
        "valorisation_capitaux_deces": 0,
        "liste_av": [
            {
                "id": 1,
                "intitule": "",
                "capitaux_deces_net": 0,
                "choix1": 0,
                "choix2": "",
                "choix3": [
                    {
                        "id": 0,
                        "taux": 100,
                        "choix1": 0,
                        "choix2": ""

                    }
                ]
            }
        ],
        "liste": {
            "autre_benef": [],
            "enfant": [],
            "petit_enfant": [],
            "conjoint": 0
        }
    },
    "d2_dev2": {
        "patrimoine_propre": 0,
        "patrimoine_herite": 0,
        "exoneration_dutreil": 0,
        "valeur_nette_imposable": 0,
        "valorisation_succession": 0,
        "devolution_succession": 3,
        "valorisation_capitaux_deces": 0,
        "liste_av": [
            {
                "id": 1,
                "intitule": "",
                "capitaux_deces_net": 0,
                "choix1": 0,
                "choix2": "",
                "choix3": [
                    {
                        "id": 0,
                        "taux": 100,
                        "choix1": 0,
                        "choix2": ""

                    }
                ]
            }
        ],
        "liste_capi": [
            {
                "id": 1,
                "intitule": "",
                "capitaux_deces_net": 0,
                "choix1": 0,
                "choix2": "",
                "choix3": [
                    {
                        "id": 0,
                        "taux": 100,
                        "choix1": 0,
                        "choix2": ""

                    }
                ]
            }
        ],
        "liste": {
            "autre_benef": [],
            "enfant": [],
            "petit_enfant": [],
        }
    },
    "bilan_patrimonial": null
}))