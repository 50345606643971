import {
    now,
    definePageNumber,
    fetchPPTPersonnalise,
    completeDate,
    slideWithOnlyTitleAndSubtitle,
    blueBaseColor,
    addTitleWithChartIcon,
    listePuceCarre,
    grayLetter,
    numberFormat,
    blackColor,
    generateLastPage,
    getInfoClient,
    generateSlide1SimulateurTitle,
    generateThreeBarres
} from "../all_ppt_render";
import PPTX from 'pptxgenjs';
import aiguilleWhite from '../../assets/images/AIGUILLE_WHITE.png';
import settingsWhite from '../../assets/images/SETTINGS_WHITE.png';
import grilleWhite from '../../assets/images/GRILLE_WHITE.png';
import projetCession from '../../assets/images/PROJET_CESSION_WHITE.png';
import calendrierFiscal from '../../assets/images/Export/CS/CALENDRIER_FISCAL.png';
import chronologieFiscale from '../../assets/images/Export/CS/CHRONOLOGIE_FISCALE.png';
import apportsAvantCession from '../../assets/images/Export/CS/apport-avant-cession.png';
import hands from '../../assets/images/HANDS.png';
import infoBleu from '../../assets/icons/info.png';
import generateTableauSynthese from "./TableauSynthese/TableauSynthese";
import generateTableauDonation from "./TableauDonation/TableauDonation";
import { formattedCurrency } from "./TableauSynthese/TableauSynthese";
import generateBox1 from "./Box1/Box1";
import generateScenario from "./Scenario/scenario";
import generateTableauSyntheseResultat from "./TableauSyntheseResultat/TableauSyntheseResultat";
import generateChoixFlatTax from "./ChoixFlatTax/choixFlatTax";
import generateBlockOrange from "./BlockOrange/blockOrange";
import { conditionAffichageEnfant, nombreEnfantsParConjoint } from "../../views/Cession/CessionHaut/ProjectionCessionSociete/TableauSynthese/TableauSynthese";
import { calculDonation, structurationSyntheseDonation } from "../../views/Cession/CessionHaut/OperationAvantCession/DonationParConjoint/DonationParConjoint";
import tableauParPage from "./TableauParPage";
import enfantsParPage from "./EnfantParPage";
import enfantsParPageDonation from "./EnfantParPageDonation";
import { fetchAllResult, getData } from "../../views/Cession/CessionHaut/ProjectionCessionSociete/ProjetCessionSociete";
import { currencyToSend } from "../../views/DonationSuccession/UsufruitNP/UsufruitViager/UsufruitViager";
import { structurationDataDonationAvantCession } from './PartieDonation/PartieDonation'
import generateTableauBord2 from "./Box2/Box2";
import { defineSlideMaster, generateFirstSlideTitle, generateTitleAndSubtitle } from "../utils";

const enfantValue = (enfants, index, indiceEnfant, prenomConjoint1="", prenomConjoint2="") => {
    if (index === 1) return enfants[indiceEnfant].prenom !== "" ? enfants[indiceEnfant].prenom : `Enfant ${indiceEnfant + 1}`;
    else if (index === 2){
        const parent = enfants[indiceEnfant].parents;
        let value = "";

        if(parent == 'Enfant commun') value = 'Enfant commun';
        else if(parent == 'Enfant du conjoint 1') value = `Enfant ${ ["Conjoint 1"].includes(prenomConjoint1) ? 'du' : 'de' } ${prenomConjoint1}`;
        else if(parent == 'Enfant du conjoint 2') value = `Enfant ${ ["Conjoint 2"].includes(prenomConjoint2) ? 'du' : 'de' } ${prenomConjoint2}`;

        return value;
    } 
    else if (index === 3) return enfants[indiceEnfant].enfantCharge;
    else if (index === 4) return formattedCurrency(enfants[indiceEnfant].revenuAnnee);
    else if (index === 5) return enfants[indiceEnfant].situationFamiliale;
    else if (index === 6) return enfants[indiceEnfant].handicap ? 'Oui' : 'Non';
};

const fetchSyntheseOperation = async (conjoints, enfants, projet, operations) => {
    //const query = await calculDonation(conjoints, enfants, operations);
    const query = await getData(fetchAllResult(conjoints, enfants, projet, operations));

    return query;
};

const calculIPV2 = (ipv1) => {
    let realIpv1Value = 0;

    if (ipv1 === '' || ipv1 === '0' || ipv1 === null || ipv1 === undefined) realIpv1Value = 0;
    else realIpv1Value = parseFloat(ipv1).toFixed(2);

    return 100 - realIpv1Value;
};

const findIndexByPrenom = (enfants, prenom) => {
    return enfants.findIndex((element) => element.prenom === prenom);
};

const csPPT = async (
    profil,
    cession,
    tb1,
    tb2,
    syntheseDetaille,
    isFicheThematique = false
) => {
    const pptx = new PPTX();
    await defineSlideMaster(pptx);

    const orange = 'D4725E';
    const jaune = 'F2C662';
    const green = '65BF7F';

    const conjoint1Background = '318FCC';
    const conjoint2Background = 'F47F4F';

    const projetCessionTitle = {
        text: "LE PROJET DE CESSION",
        width: 3.5
    }

    const laSimulationProjetCessionTitle = {
        text: "LA SIMULATION DU PROJET DE CESSION",
        width: 5
    }

    const lesOperationsAvantCessionTitle = {
        text: "LES OPÉRATIONS AVANT CESSION",
        width: 4.8
    }

    if (!isFicheThematique) {
        const { identiteActionnaire: identite, projetCessionSociete: projet, operationsAvantCession: operations } = cession;
        const { conjoints, enfants } = identite;
        const { conjoints: conjointsProjet } = projet;
        let { donations, synthese } = operations;

        const prenomConjoint1 = conjoints[0].nomPersonnalise &&
            ![null, undefined, ""].includes(conjoints[0].nomPersonnalise) ? conjoints[0].nomPersonnalise : 'Conjoint 1';


        const prenomConjoint2 = conjoints[1].nomPersonnalise &&
            ![null, undefined, ""].includes(conjoints[1].nomPersonnalise) ? conjoints[1].nomPersonnalise : 'Conjoint 2';

        // Traitement slide2
        const slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

        const slide2Title = [
            { text: "INFORMATIONS PATRIMONIALES ET FISCALES", options: { breakLine: true } },
            { text: "RELATIVES À VOTRE PROJET DE CESSION DE SOCIÉTÉ", options: {} }
        ];

        const slide2Text = [
            { text: "LE PROJET DE CESSION", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12, bold: true } },
            { text: "", options: { breakLine: true } },
            { text: "LA SIMULATION DU PROJET DE CESSION", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12, bold: true } },
            { text: "", options: { breakLine: true } },
            { text: "L'IMPOSITION DE LA PLUS-VALUE DE CESSION", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12, bold: true } },
            { text: "", options: { breakLine: true } },
            { text: "LES OPÉRATIONS AVANT CESSION", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12, bold: true } },
        ]

        const xSlide2 = 3.8;
        const ySlide2 = 2;

        generateFirstSlideTitle(
            pptx,
            slide2,
            slide2Title,
            xSlide2 - 1.4,
            ySlide2 - 1,
            "SIMULATION",
            xSlide2 - 0.8,
            7.4
        )

        generateThreeBarres(slide2, xSlide2 + 0.4, ySlide2 + 0.6, 2.5);

        slide2.addText(slide2Text, {
            x: xSlide2 + 0.7,
            y: ySlide2 + 0.6,
            w: 5,
            h: 2.5,
            fontSize: 12,
            fontFace: 'Roboto',
            align: 'justify',
            color: '5271C7',
            // lineSpacingMultiple: 2
        })

        // Traitement SLIDE3
        const slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

        const slide3Title = [
            { text: "LE PROJET DE CESSION", options: { breakLine: true } },
        ];

        const slide3Text = [
            { text: "Présentation du projet de cession dans la situation actuelle", options: { breakLine: true, fontSize: 12, bold: true, color: '595959' } },
            { text: "", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
            { text: "La situation familiale", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
            { text: "", options: { breakLine: true } },
            { text: "Le tableau des actionnaires familiaux", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
            { text: "", options: { breakLine: true } },
            { text: "Les opérations d’apports et donations envisagées avant la cession", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        ]

        const xSlide3 = 3.8;
        const ySlide3 = 2;

        generateFirstSlideTitle(
            pptx,
            slide3,
            slide3Title,
            xSlide3 - 1.4,
            ySlide3 - 1,
            "SIMULATION",
            undefined,
            6.5
        )

        generateThreeBarres(slide3, xSlide3 + 0.4, ySlide3 + 0.6, 2.5);

        slide3.addText(slide3Text, {
            x: xSlide3 + 0.7,
            y: ySlide3 + 0.6,
            w: 5,
            h: 2.5,
            fontSize: 12,
            fontFace: 'Roboto',
            align: 'justify',
            color: '5271C7',
            // lineSpacingMultiple: 2
        })

        const rowsConjointsProps = {
            fontSize: 11,
            fontFace: 'Gill Sans MT',
            valign: 'middle'
        };

        const dashedBorderBottom = (type = 'dashed') => {
            return [
                {
                    pt: 0,
                    color: '808080',
                },
                {
                    pt: 0,
                    color: 'FFFFFF',
                },
                {
                    pt: type === 'dashed' ? 0.7 : 1.6,
                    color: '808080',
                    type: type === 'dashed' ? 'dash' : 'solid'
                },
                {
                    pt: 0,
                    color: '808080',
                },
            ]
        };

        const rowsConjoints = [
            [
                { text: "" },
                { text: "" },
                {
                    text: `${prenomConjoint1.toString().toUpperCase()}`, options: {
                        ...rowsConjointsProps,
                        bold: true,
                        align: 'center',
                        valign: 'middle',
                        fill: conjoint1Background,
                        color: 'FFFFFF',
                        border: [
                            {
                                pt: 'T',
                                color: 'ffffff'
                            },
                            {
                                pt: 'R',
                                color: 'ffffff'
                            },
                            {
                                pt: 0,
                                color: '7F90BC'
                            },
                            {
                                pt: 'L',
                                color: 'ffffff'
                            },
                        ]
                    }
                },
                { text: "" },
            ],
            [
                { text: "Nom personnalisé", options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" },
                { text: `${conjoints[0].nomPersonnalise !== "" ? conjoints[0].nomPersonnalise : 'Conjoint 1'}`, options: { align: 'center', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" },
            ],
            [
                { text: "Age", options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" },
                { text: `${conjoints[0].age} ans`, options: { align: 'center', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" },
            ],
            [
                { text: "Marié ou pacsé ou Personne seule", options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" },
                { text: `${conjoints[0].marieOuPasce === "Oui" ? "Marié ou Pacsé" : "Personne seule"}`, options: { align: 'center', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" },
            ],
            [
                { text: [{ text: "Revenus de l'année de cession imposables", options: { breakLine: true } }, { text: "à la CEHR" }], options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom('normal') } },
                { text: "" },
                { text: `${formattedCurrency(conjoints[0].revenuAnneeCession)} `, options: { align: 'center', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom('normal') } },
                { text: "" },
            ],
        ];

        const conjoint2Data = [
            [
                {
                    text: `${prenomConjoint2.toString().toUpperCase()}`, options: {
                        ...rowsConjointsProps,
                        bold: true,
                        align: 'center',
                        valign: 'middle',
                        fill: conjoint2Background,
                        color: 'FFFFFF',
                        border: [
                            {
                                pt: 'T',
                                color: 'ffffff'
                            },
                            {
                                pt: 'R',
                                color: 'ffffff'
                            },
                            {
                                pt: 0,
                                color: 'D4725E'
                            },
                            {
                                pt: 'L',
                                color: 'ffffff'
                            },
                        ]
                    }
                },
            ],
            [
                { text: `${conjoints[1].nomPersonnalise !== "" ? conjoints[1].nomPersonnalise : 'Conjoint 2'}`, options: { align: 'center', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } }
            ],
            [
                { text: `${conjoints[1].age} ans`, options: { align: 'center', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } }
            ],
            [
                { text: "-", options: { align: 'center', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } }
            ],
            [
                { text: `${formattedCurrency(conjoints[1].revenuAnneeCession)}`, options: { align: 'center', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom('normal') } }
            ]
        ];

        if (conjoints[0].marieOuPasce === 'Oui') {
            conjoint2Data.map((data, index) => {
                rowsConjoints[index] = [...rowsConjoints[index], ...data];
            });
        }

        const rowsEnfants = [
            [
                { text: "", options: { align: 'left', ...rowsConjointsProps } },
                { text: "" }
            ],
            [
                { text: "Prénom", options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" }
            ],
            [
                { text: "Les parents", options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" }
            ],
            [
                { text: "Fiscalement à charge ou détaché", options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" }
            ],
            [
                { text: [{ text: "Revenus de l'année de cession imposables", options: { breakLine: true } }, { text: "à la CEHR" }], options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" }
            ],
            [
                { text: "Situation familiale", options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom() } },
                { text: "" }
            ],
            [
                { text: "L'enfant présente un handicap ?", options: { align: 'left', ...rowsConjointsProps, fill: 'F2F2F2', border: dashedBorderBottom('normal') } },
                { text: "" }
            ]
        ];

        rowsEnfants.map((data, index) => {
            for (let i = 0; i < enfants.length; i++) {
                let prenom = enfantValue(enfants, 1, i);
                prenom = ![null, undefined, ""].includes(prenom) ? prenom.toString().toUpperCase() : `ENFANT ${i+1}`;

                rowsEnfants[index].push({
                    text: index === 0 ? prenom : `${enfantValue(enfants, index, i, prenomConjoint1, prenomConjoint2)}`,
                    options: {
                        bold: index === 0 ? true : false,
                        align: 'center',
                        fill: index === 0 ? '808080' : 'F2F2F2',
                        color: index === 0 ? 'FFFFFF' : blackColor,
                        border: index !== 0 ? dashedBorderBottom(index !== rowsEnfants.length - 1 ? 'dashed' : 'normal') : [
                            {
                                pt: 'T',
                                color: 'ffffff'
                            },
                            {
                                pt: 'T',
                                color: 'ffffff'
                            },
                            {
                                pt: 0,
                                color: '808080'
                            },
                            {
                                pt: 'L',
                                color: 'ffffff'
                            },
                        ],
                        ...rowsConjointsProps
                    }
                });
                rowsEnfants[index].push({
                    text: ""
                })
            }
        });

        const finalData = enfantsParPage(rowsEnfants, enfants);

        const colWEnfants = [4.1];
        for (let i = 0; i < (enfants.length / 2); i++) {
            colWEnfants.push(2.1);
            colWEnfants.push(0.2);
        }

        const colWEnfantsDonations = [3.7];
        for (let i = 0; i < (enfants.length + 1); i++) {
            colWEnfantsDonations.push(1.6);
            colWEnfantsDonations.push(0.1);
        }

        // Au cas où on aura pas d'enfants
        if (enfants.length === 0) {
            // Traitement SLIDE4 et il y a de boucle au cas où on aura plus de 2 enfants
            const slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

            generateTitleAndSubtitle(
                "SIMULATION",
                pptx,
                slide4,
                projetCessionTitle
            )

            slide4.addText("LA SITUATION FAMILIALE", {
                x: 0.6,
                y: 1.6,
                w: "90%",
                h: 0.4,
                align: 'center',
                fontFace: 'Gill Sans MT',
                fontSize: 15.8,
                bold: true,
                color: blackColor
            });

            slide4.addTable(rowsConjoints, {
                x: 0.6,
                y: 2.26,
                color: blackColor,
                colW: [3.7, 0.1, 1.6, 0.1, 1.6],
                rowH: [0.34, 0.34, 0.34, 0.34, 0.34]
            });
        }

        const childColumnWidth = (data) => {
            const first = [3.7];
            const length = data[0].length - 1;

            for (let i = 0; i < length; i++) {
                if (i % 2 === 0) first.push(0.1);
                else first.push(1.6);
            }

            return first;
        };

        // Au cas où on aura des enfants
        enfants.length > 0 && finalData.map((data, index) => {
            if (data && data[0] && data[0].length > 2) {

                // Traitement SLIDE4 et il y a de boucle au cas où on aura plus de 2 enfants
                const slide4 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

                generateTitleAndSubtitle(
                    "SIMULATION",
                    pptx,
                    slide4,
                    projetCessionTitle
                )

                slide4.addText("LA SITUATION FAMILIALE", {
                    x: 0.6,
                    y: 1.66,
                    h: 0.4,
                    w: "90%",
                    align: 'center',
                    fontFace: 'Gill Sans MT',
                    fontSize: 15.8,
                    bold: true,
                    color: blackColor
                });

                // On affiche uniquement les Conjoints à la première page
                if (index === 0) {
                    slide4.addTable(rowsConjoints, {
                        x: 0.6,
                        y: 2.26,
                        color: blackColor,
                        colW: [3.7, 0.1, 1.6, 0.1, 1.6],
                        rowH: [0.34, 0.34, 0.34, 0.34, 0.34]
                    });
                }

                slide4.addTable(data, {
                    x: 0.6,
                    y: index === 0 ? 4.56 : 3.2,
                    color: blackColor,
                    colW: childColumnWidth(data),
                    rowH: [0.34, 0.34, 0.34, 0.34, 0.34]
                });
            }


        });

        const paiementIPVText = [
            { text: "Paiement des impositions sur la PV", options: { breakLine: true } },
            { text: `dans le foyer fiscal ${["Conjoint 1"].includes(prenomConjoint1) ? 'du' : 'de'} ${prenomConjoint1.toString().toUpperCase()}` }
        ];

        const ipvsData = [
            [
                { text: [{ text: `% IPV pris en charge par ${["Conjoint 1"].includes(prenomConjoint1) ? 'le ' : ''}${prenomConjoint1.toString().toUpperCase()}`, options: { breakLine: false } }], options: { ...rowsConjointsProps, align: 'left', valign: 'middle', color: blackColor, fontSize: 12 } },
                { text: `${parseFloat(conjointsProjet[0].ipv.real).toFixed(2)} %`, options: { ...rowsConjointsProps, align: 'center', valign: 'middle', fill: conjoint1Background, color: 'FFFFFF', bold: true, fontSize: 12 } }
            ]
        ];

        if (conjoints[0].marieOuPasce === 'Oui') {
            ipvsData.push(
                [
                    { text: `% IPV pris en charge par ${["Conjoint 2"].includes(prenomConjoint2) ? 'le ' : ''}${prenomConjoint2.toString().toUpperCase()}`, options: { ...rowsConjointsProps, align: 'left', valign: 'middle', color: blackColor, fontSize: 12 } },
                    { text: `${calculIPV2(conjointsProjet[0].ipv.real)} %`, options: { ...rowsConjointsProps, align: 'center', valign: 'middle', fill: conjoint2Background, color: 'FFFFFF', bold: true, fontSize: 12 } }
                ]
            );
        }

        const realDataSynthese = tableauParPage(generateTableauSynthese(cession), 12);

        realDataSynthese.map((data, index) => {
            if (data.length > 0) {
                // Traitement SLIDE5
                let slide5 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

                generateTitleAndSubtitle(
                    "SIMULATION",
                    pptx,
                    slide5,
                    projetCessionTitle
                )

                slide5.addText("LES ACTIONNAIRES FAMILIAUX", {
                    x: 0.6,
                    y: 1.6,
                    w: "90%",
                    h: 0.4,
                    align: 'center',
                    color: blackColor,
                    fontFace: 'Gill Sans MT',
                    fontSize: 15.8,
                    bold: true
                });

                slide5.addTable(data, {
                    x: 0.6,
                    y: 2.16,
                    w: '90%',
                    border: {
                        color: '808080'
                    },
                    rowH: 0.35,
                    colW: [2.7, 1.925, 2.025, 2.025, 1.875]// Marge: 0.625 pour les colonnes de montant
                }
                );

                if (index === 0) {
                    slide5.addText(paiementIPVText, {
                        x: 0.6,
                        y: 6.7,
                        fontFace: 'Gill Sans MT',
                        w: 3,
                        h: 0.6,
                        fill: '28366D',
                        valign: 'middle',
                        fontSize: 13,
                        color: 'FFFFFF',
                        line: {
                            color: '28366D'
                        }
                    });

                    slide5.addTable(ipvsData, {
                        x: 4,
                        y: 6.7,
                        colW: [3.1, 2.1],
                        fontSize: 12,
                        rowH: [0.34, 0.34, 0.34]
                    });
                }
            }
        });

        const cond = true;

        // Début du TRAITEMENT SLIDE6
        if (cond) {
            const response = await fetchSyntheseOperation(conjoints, enfants, projet, operations);

            // Les donations liées au Conjoint 1
            if (response && response.donation && response.donation[0]) {
                const dataConjoint = response.donation[0].tableDonationConjoint;
                const donationsEnfants = response.donation[0].tableDonationEnfant;

                identite.conjoints[0].fraisDonation = Math.round(dataConjoint.frais_et_donation);
                identite.conjoints[0].tauxMarginalImposition = Math.round(dataConjoint.taux_marginal);

                donationsEnfants.map((data, index) => {
                    operations.donations[0].enfants[findIndexByPrenom(enfants, data.nom)].fraisDonation = Math.round(data.frais_et_donation);
                    operations.donations[0].enfants[findIndexByPrenom(enfants, data.nom)].tauxMarginalImposition = Math.round(data.taux_marginal);
                    operations.donations[0].enfants[findIndexByPrenom(enfants, data.nom)].id = data.id;
                });
            }

            // Les donations liées au Conjoint 2
            if (response && response.donation && response.donation[1]) {
                const dataConjoint = response.donation[1].tableDonationConjoint;
                const donationsEnfants = response.donation[1].tableDonationEnfant;

                identite.conjoints[1].fraisDonation = Math.round(dataConjoint.frais_et_donation);
                identite.conjoints[1].tauxMarginalImposition = Math.round(dataConjoint.taux_marginal);

                donationsEnfants.map((data, index) => {
                    operations.donations[1].enfants[findIndexByPrenom(enfants, data.nom)].fraisDonation = Math.round(data.frais_et_donation);
                    operations.donations[1].enfants[findIndexByPrenom(enfants, data.nom)].tauxMarginalImposition = Math.round(data.taux_marginal);
                    operations.donations[1].enfants[findIndexByPrenom(enfants, data.nom)].id = data.id;
                });
            }

            const data = response.table_donation_avant_cession;
            synthese = structurationSyntheseDonation(enfants, conjoints, data, identite.affichageNomPersonnalise, projet.holding.nomPersonnalise);
        }

        const realDataDonations = tableauParPage(generateTableauDonation(cession, synthese), 13);

        realDataDonations.map((data, index) => {
            if (data.length > 0) {
                // Traitement SLIDE6
                let slide6 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

                generateTitleAndSubtitle(
                    "SIMULATION",
                    pptx,
                    slide6,
                    projetCessionTitle
                )

                slide6.addText("LES OPÉRATIONS D’APPORTS ET/OU DONATIONS ENVISAGÉES AVANT LA CESSION", {
                    x: 0.6,
                    y: 1.6,
                    w: "90%",
                    h: 0.4,
                    align: 'center',
                    fontFace: 'Gill Sans MT',
                    fontSize: 15.8,
                    color: blackColor,
                    bold: true
                });

                slide6.addTable(data, {
                    x: 0.6,
                    y: 2.16,
                    w: '90%',
                    border: {
                        color: '808080'
                    },
                    rowH: 0.35,
                    colW: [3.7, 1.825, 1.725, 1.725, 1.725]// Départ: 2.6
                }
                );
            }
        });

        // Traitement SLIDE6suite
        const donationsRows1 = [
            [
                {
                    text: "Donation antérieure de moins de 15 ans ?",
                    options: {
                        align: 'left',
                        ...rowsConjointsProps,
                        color: '0070C0',
                        bold: true
                    }
                }
            ],
            [
                { text: "Valeurs fiscales données sauf les dons exonérés", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                { text: "Année de la donation antérieure", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                { text: "Abattement fiscal applicable sur la donation antérieure", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                {
                    text: "Nouvelle donation avant cession",
                    options: {
                        align: 'left',
                        ...rowsConjointsProps,
                        color: '0070C0',
                        bold: true,
                        underline: true
                    }
                }
            ],
            [
                { text: "Donation en pleine propriété", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                { text: "Donation en nue-propriété avec clause de remploi", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                { text: "Frais et droits de donation", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                { text: "Taux marginal des droits de donation", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
        ];

        const donationsRows2 = [
            [
                {
                    text: "Donation antérieure de moins de 15 ans ?",
                    options: {
                        align: 'left',
                        ...rowsConjointsProps,
                        color: '0070C0',
                        bold: true
                    }
                }
            ],
            [
                { text: "Valeurs fiscales données sauf les dons exonérés", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                { text: "Année de la donation antérieure", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                { text: "Abattement fiscal applicable sur la donation antérieure", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                {
                    text: "Nouvelle donation avant cession",
                    options: {
                        align: 'left',
                        ...rowsConjointsProps,
                        color: '0070C0',
                        bold: true,
                        underline: true
                    }
                }
            ],
            [
                { text: "Donation en pleine propriété", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                { text: "Donation en nue-propriété avec clause de remploi", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
            [
                { text: "Frais et droits de donation", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],

            [
                { text: "Taux marginal des droits de donation", options: { align: 'left', ...rowsConjointsProps, color: '28366D' } }
            ],
        ];

        const filling = (index, i, length, conjointIndex) => {
            let fill = 'F2F2F2';
            if (index === 0) fill = '808080';
            if (index === 4 || ((index != 5 && index !== 7) && (i === length))) fill = 'FFFFFF';
            if (index === 1 && i === length) fill = 'F2F2F2';
            if (index === 8 && i === length) fill = 'F2F2F2';

            return fill;
        };

        donationsRows1.map((data, index) => {
            const length = enfants.length + (conjoints[0].marieOuPasce === 'Oui' ? 1 : 0);
            let prop = '';
            let value = 0;

            for (let i = 0; i < length; i++) {
                if (index === 0) {
                    if (i !== (length - 1)) {
                        value = `ENFANT ${i + 1}`;
                    }
                    else {
                        value = `CONJOINT 2`;
                    }
                }
                else {
                    if (index === 1) prop = 'valeurFiscale';
                    else if (index === 2) prop = 'anneeDonation';
                    else if (index === 3) prop = 'abattementFiscal';
                    else if (index === 5) prop = 'donationPP';
                    else if (index === 6) prop = 'donationNP';
                    else if (index === 7) prop = 'fraisDonation';
                    else if (index === 8) prop = 'tauxMarginalImposition';
                }

                if (index !== 0) {
                    if (i !== (length - 1)) {
                        value = index === 4 ? "" : index === 8 ? `${donations[0].enfants[i][prop]} %` : index !== 2 ? formattedCurrency(donations[0].enfants[i][prop]) : donations[0].enfants[i]['anneeDonation'];

                        if (index !== 4) {
                            // Pour les années de donations
                            if (prop === 'anneeDonation') {
                                if (currencyToSend(donations[0].enfants[i].valeurFiscale) === 0) {
                                    value = ''
                                }
                            }

                            // Pour les abattements fiscaux
                            if (prop === 'abattementFiscal') {
                                if (currencyToSend(donations[0].enfants[i].valeurFiscale) === 0) {
                                    value = ''
                                }
                            }
                        }
                    }
                    else {
                        if (index === 8) value = `${conjoints[0][prop]} %`
                        else value = formattedCurrency(conjoints[0][prop]);
                    }
                }

                if (index === 4 && (i === length - 1)) value = '';

                if ((i < length - 1 && conditionAffichageEnfant(enfants, 0, i)) || (conjoints[0].marieOuPasce === 'Oui' && i === length - 1)) {
                    donationsRows1[index].push({
                        text: value,
                        options: {
                            bold: true,
                            align: index === 0 || (index === 4 && i === length - 1) ? 'center' : 'right',
                            fill: index === 0 && i === (length - 1) ? 'D4725E' : filling(index, i, length - 1, 0),
                            color: index === 0 ? 'FFFFFF' : index === 7 || index === 8 ? 'EF7D7D' : blackColor,
                            border: [
                                {
                                    pt: 0,
                                    color: '',
                                },
                                {
                                    pt: 4,
                                    color: 'FFFFFF',
                                },
                                {
                                    pt: 4,
                                    color: 'FFFFFF',
                                },
                                {
                                    pt: 4,
                                    color: 'FFFFFF',
                                },
                            ],
                            ...rowsConjointsProps
                        }
                    });
                }
            }
        });

        donationsRows2.map((data, index) => {
            const length = enfants.length + 1;
            let prop = '';
            let value = 0;

            for (let i = 0; i < length; i++) {
                if (index === 0) {
                    if (i !== (length - 1)) {
                        value = `ENFANT ${i + 1}`;
                    }
                    else {
                        value = `CONJOINT 1`;
                    }
                }
                else {
                    if (index === 1) prop = 'valeurFiscale';
                    else if (index === 2) prop = 'anneeDonation';
                    else if (index === 3) prop = 'abattementFiscal';
                    else if (index === 5) prop = 'donationPP';
                    else if (index === 6) prop = 'donationNP';
                    else if (index === 7) prop = 'fraisDonation';
                    else if (index === 8) prop = 'tauxMarginalImposition'
                }

                if (index !== 0) {
                    if (i !== (length - 1)) {
                        value = index === 4 ? "" : index === 8 ? `${donations[1].enfants[i][prop]} %` : index !== 2 ? formattedCurrency(donations[1].enfants[i][prop]) : donations[1].enfants[i]['anneeDonation'];

                        if (index !== 4) {
                            // Pour les années de donations
                            if (prop === 'anneeDonation') {
                                if (currencyToSend(donations[1].enfants[i].valeurFiscale) === 0) {
                                    value = ''
                                }
                            }

                            // Pour les abattements fiscaux
                            if (prop === 'abattementFiscal') {
                                if (currencyToSend(donations[1].enfants[i].valeurFiscale) === 0) {
                                    value = ''
                                }
                            }
                        }
                    }
                    else {
                        if (index === 8) value = `${conjoints[1][prop]} %`
                        else value = formattedCurrency(conjoints[1][prop]);
                    }
                }

                if (index === 4 && (i === (length - 1))) value = '';

                if ((i < length - 1 && conditionAffichageEnfant(enfants, 1, i) || (i === length - 1))) {
                    donationsRows2[index].push({
                        text: value,
                        options: {
                            bold: true,
                            align: index === 0 || (index === 4 && i === length - 1) ? 'center' : 'right',
                            fill: index === 0 && i === (length - 1) ? '7F90BC' : filling(index, i, length - 1, 1),
                            color: index === 0 || (index === 4 && i === (length - 1)) ? 'FFFFFF' : index === 7 || index === 8 ? 'EF7D7D' : blackColor,
                            border: [
                                {
                                    pt: 0,
                                    color: '',
                                },
                                {
                                    pt: 4,
                                    color: 'FFFFFF',
                                },
                                {
                                    pt: 4,
                                    color: 'FFFFFF',
                                },
                                {
                                    pt: 4,
                                    color: 'FFFFFF',
                                },
                            ],
                            ...rowsConjointsProps
                        }
                    });
                }
            }
        });

        //const donations1 = enfantsParPageDonation(donationsRows1, enfants.filter((element) => [0, 1, "0", "1"].includes(element.parent)));
        //const donations2 = enfantsParPageDonation(donationsRows2, enfants.filter((element) => [0, 2, "0", "2"].includes(element.parent)));

        // console.log('HOME', 'HOME');
        const donations1 = structurationDataDonationAvantCession(
            donations && donations[0] && donations[0].enfants ? donations[0].enfants : [],
            conjoints[0],
            0,
            conjoints[0].marieOuPasce === 'Oui',
            enfants,
            prenomConjoint1,
            prenomConjoint2
        );

        const donations2 = structurationDataDonationAvantCession(
            donations && donations[1] && donations[1].enfants ? donations[1].enfants : [],
            conjoints[1],
            1,
            false,
            enfants,
            prenomConjoint1,
            prenomConjoint2
        );

        const largeurColonneDonation = (data) => {
            const first = [3.55];
            data.map((donnee, index) => {
                if (index > 0) {
                    if (donnee.text === "") first.push(0.1);
                    else first.push(1.4);
                }
            });

            return first;
        };

        // Traitement SLIDE6
        donations1.map((data, index) => {
            if (data && data[0] && data[0].length >= 2) {
                let slide6Suite = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

                generateTitleAndSubtitle(
                    "SIMULATION",
                    pptx,
                    slide6Suite,
                    projetCessionTitle
                )

                if (index === 0) {
                    slide6Suite.addText(
                        [
                            { text: `DONATION PAR ${ ["Conjoint 1"].includes(prenomConjoint1) ? 'LE ' : '' }`, options: { breakLine: false } },
                            { text: `${prenomConjoint1.toString().toUpperCase()}`, options: { underline: true, bold: true } }
                        ]
                        , {
                            x: 4.15,
                            y: 2.16,
                            fontFace: 'Gill Sans MT',
                            color: 'FFFFFF',
                            w: 3.3,
                            h: '5%',
                            fontSize: 12,
                            valign: 'middle',
                            align: 'center',
                            fill: conjoint1Background
                        });
                }

                slide6Suite.addText("LES OPÉRATIONS D’APPORTS ET/OU DONATIONS ENVISAGÉES AVANT LA CESSION", {
                    x: 0.6,
                    y: 1.6,
                    w: "90%",
                    h: 0.4,
                    align: 'center',
                    fontFace: 'Gill Sans MT',
                    fontSize: 15.8,
                    bold: true,
                    color: blackColor
                });

                slide6Suite.addTable(data, {
                    x: 0.6,
                    y: 2.9,
                    colW: largeurColonneDonation(data[5]),
                    rowH: [0.6, 0.4, 0.4, 0.4, 0.6, 0.4, 0.4, 0.4, 0.4]
                });
            }
        });

        if (conjoints[0].marieOuPasce === 'Oui') {
            // Traitement SLIDE6
            donations2.map((data, index) => {
                if (data && data[0] && data[0].length >= 2) {
                    let slide6Suite = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

                    generateTitleAndSubtitle(
                        "SIMULATION",
                        pptx,
                        slide6Suite,
                        projetCessionTitle
                    )

                    if (index === 0) {
                        slide6Suite.addText(
                            [
                                { text: `DONATION PAR ${ ["Conjoint 2"].includes(prenomConjoint2) ? 'LE ' : '' }`, options: { breakLine: false } },
                                { text: `${prenomConjoint2.toString().toUpperCase()}`, options: { underline: true, bold: true } }
                            ]
                            , {
                                x: 4.15,
                                y: 2.16,
                                fontFace: 'Gill Sans MT',
                                color: 'FFFFFF',
                                w: 3.3,
                                h: '5%',
                                fontSize: 12,
                                valign: 'middle',
                                align: 'center',
                                fill: conjoint2Background
                            });
                    }

                    slide6Suite.addText("LES OPÉRATIONS D’APPORTS ET/OU DONATIONS ENVISAGÉES AVANT LA CESSION", {
                        x: 0.6,
                        y: 1.6,
                        w: "90%",
                        h: 0.4,
                        align: 'center',
                        fontFace: 'Gill Sans MT',
                        fontSize: 15.8,
                        bold: true,
                        color: blackColor
                    });

                    slide6Suite.addTable(data, {
                        x: 0.6,
                        y: 2.9,
                        colW: largeurColonneDonation(data[5]),
                        rowH: [0.6, 0.4, 0.4, 0.4, 0.6, 0.4, 0.4, 0.4, 0.4]
                    });
                }
            });
        }


        // Traitement SLIDE7
        const slide7 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

        const slide7Title = [
            { text: "LA SIMULATION DU PROJET DE CESSION", options: { breakLine: true } },
        ];

        const slide7Text = [
            { text: "Simulation des impositions sur la plus-value de cession et des gains fiscaux", options: { breakLine: true, fontSize: 12, bold: true, color: '595959' } },
            { text: "", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
            { text: "Les tableaux de bord des impositions et gains", options: { breakLine: true, color: '4472C4', bullet: { code: '25A0', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
            { text: "", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
            { text: "La présentation des différents scénarios", options: { breakLine: true, color: '4472C4', bullet: { code: '25A0', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
            { text: "", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
            { text: "Scénario 1 : ", options: { breakLine: false, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 2, fontSize: 12, bold: true } },
            { text: "imposition à la flat tax sans régime de faveur dans la situation actuelle", options: { breakLine: true, color: '4472C4', fontSize: 12 } },
            { text: "", options: { breakLine: true, color: '4472C4', fontSize: 12 } },
            { text: "Scénario 2 : ", options: { breakLine: false, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 2, fontSize: 12, bold: true } },
            { text: "optimisation fiscale des régimes d’imposition dans la situation actuelle", options: { breakLine: true, color: '4472C4', fontSize: 12 } },
            { text: "", options: { breakLine: true, color: '4472C4', fontSize: 12 } },
            { text: "Scénario 3 : ", options: { breakLine: false, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 2, fontSize: 12, bold: true } },
            { text: "imposition après apports / donations, avec prise en charge des frais de donation ", options: { breakLine: false, color: '4472C4', fontSize: 12 } },
            { text: "par les donateurs", options: { breakLine: true, underline: true, color: '4472C4', fontSize: 12 } },
            { text: "", options: { breakLine: true, color: '4472C4', fontSize: 12 } },
            { text: "Scénario 4 : ", options: { breakLine: false, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 2, fontSize: 12, bold: true } },
            { text: "imposition après apports / donations, avec prise en charge des frais de donation ", options: { breakLine: false, color: '4472C4', fontSize: 12 } },
            { text: "par les donataires", options: { breakLine: true, underline: true, color: '4472C4', fontSize: 12 } },
        ]

        const xSlide7 = 3.8;
        const ySlide7 = 2;

        // generateSlide1SimulateurTitle(pptx, slide7, slide7Title, "CS", xSlide7 - 1.4, ySlide7 - 1);
        generateFirstSlideTitle(
            pptx,
            slide7,
            slide7Title,
            xSlide7 - 1.4,
            ySlide7 - 1,
            "SIMULATION",
            xSlide7 - 1,
            6.5
        )
        generateThreeBarres(slide7, xSlide7 + 0.4, ySlide7 + 0.6, 4);

        slide7.addText(slide7Text, {
            x: xSlide7 + 0.7,
            y: ySlide7 + 0.6,
            w: 5,
            h: 4,
            fontSize: 12,
            fontFace: 'Roboto',
            align: 'justify',
            color: '5271C7',
            // lineSpacingMultiple: 2
        })

        /*slide7.addText("", {
            x: 0.6,
            y: 0.72,
            w: 1.2,
            h: 0.8,
            fill: 'B1A0C7',
            line: {
                color: 'B1A0C7'
            }
        });

        slide7.addImage({
            path: grilleWhite,
            x: 0.85,
            y: 0.88,
            w: '6%',
            h: '6%'
        });

        slide7.addText("LA SIMULATION DU PROJET DE CESSION", {
            x: 0.8,
            y: 2.2,
            w: '50%',
            h: 0.4,
            fontSize: 20,
            color: '32396c',
            fontFace: 'Gill Sans MT'
        });

        slide7.addText("Simulation des impositions sur la plus-value de cession et des gains fiscaux"
            , {
                x: 0.8,
                y: 2.9,
                w: '70%',
                h: 0.4,
                fontSize: 16,
                bold: true,
                color: blackColor,
                fontFace: 'Gill Sans MT'
            });

        slide7.addText("", {
            x: 0.6,
            y: 3.8,
            w: '55%',
            h: '0.4%',
            fill: 'B1A0C7'
        });

        const listeslide7 = [
            "Les tableaux de bord des impositions et gains",
            "La présentation des différents scénarios",
        ];

        listePuceCarre(slide7, listeslide7, 1, 4.1);

        slide7.addText(
            [
                { text: "> Scénario 1 : ", options: { breakLine: false, color: 'B1A0C7', bold: true } },
                { text: "imposition à la flat tax sans régime de faveur dans la situation actuelle", options: { color: blackColor } }
            ]
            , {
                x: 1.6,
                y: 4.9,
                w: '60%',
                h: 0.3,
                fontFace: 'Gill Sans MT',
                fontSize: 14
            });

        slide7.addText(
            [
                { text: "> Scénario 2 : ", options: { breakLine: false, color: 'B1A0C7', bold: true } },
                { text: "optimisation fiscale des régimes d’imposition dans la situation actuelle", options: { color: blackColor } }
            ]
            , {
                x: 1.6,
                y: 5.35,
                w: '60%',
                h: 0.3,
                fontFace: 'Gill Sans MT',
                fontSize: 14
            });

        slide7.addText(
            [
                { text: "> Scénario 3 : ", options: { breakLine: false, color: 'B1A0C7', bold: true } },
                { text: "imposition après apports / donations, avec prise en charge des frais de ", options: { breakLine: true, color: blackColor } },
                { text: "donation ", options: { breakLine: false, color: blackColor } },
                { text: "par les donateurs", options: { underline: true, color: blackColor } }
            ]
            , {
                x: 1.6,
                y: 5.81,
                w: '63%',
                h: 0.45,
                fontFace: 'Gill Sans MT',
                fontSize: 14
            });

        slide7.addText(
            [
                { text: "> Scénario 4 : ", options: { breakLine: false, color: 'B1A0C7', bold: true } },
                { text: "imposition après apports / donations, avec prise en charge des frais de ", options: { breakLine: true, color: blackColor } },
                { text: "donation ", options: { breakLine: false, color: blackColor } },
                { text: "par les donataires", options: { underline: true, color: blackColor } }
            ]
            , {
                x: 1.6,
                y: 6.46,
                w: '60%',
                h: 0.46,
                fontFace: 'Gill Sans MT',
                fontSize: 14
            });*/

        // Traitement SLIDE8
        const slide8 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

        generateTitleAndSubtitle(
            "SIMULATION",
            pptx,
            slide8,
            laSimulationProjetCessionTitle
        )

        slide8.addText("TABLEAU DE BORD n°1 : imposition dans la situation actuelle à la flat tax sans régime de faveur", {
            x: 0.6,
            y: 1.6,
            w: "90%",
            h: 0.4,
            align: 'center',
            fontFace: 'Gill Sans MT',
            color: blackColor,
            fontSize: 15.8,
            bold: true
        });

        const debutYTb1 = 2.75;

        generateBox1(
            pptx,
            slide8,
            0.6,
            debutYTb1,
            "Prix de cession",
            numberFormat(Math.round(tb1.prix_cession)),
            tb1 && tb1.taux_prix_cession ? Math.round(tb1.taux_prix_cession) : 0,
            '8E5FA8',
            'D2C5EB',
            '17%'
        );

        generateBox1(
            pptx,
            slide8,
            2.7,
            debutYTb1,
            "PV brute",
            numberFormat(Math.round(tb1.plus_value_brute)),
            tb1 && tb1.taux_plus_value_brute ? Math.round(tb1.taux_plus_value_brute) : 0,
            '318FCC',
            '7f90bc',
            '18%'
        );

        generateBox1(
            pptx,
            slide8,
            4.9,
            debutYTb1,
            "Impôts sur PV",
            numberFormat(Math.round(tb1.impot_sur_plus_value)),
            tb1 && tb1.taux_impot_sur_plus_value ? parseFloat(tb1.taux_impot_sur_plus_value).toFixed(2) : 0,
            'EF4F6C',
            'FFCED7',
            '17%'
        );

        generateBox1(
            pptx,
            slide8,
            7.0,
            debutYTb1,
            "Net de cession",
            numberFormat(Math.round(tb1.net_cession)),
            tb1 && tb1.taux_net_cession ? Math.round(tb1.taux_net_cession) : 0,
            '279757',
            'C4E7CE',
            '17%'
        );

        generateBox1(
            pptx,
            slide8,
            9.1,
            debutYTb1,
            "Optimisation fiscale",
            numberFormat(Math.round(tb1.optimisation_fiscal)),
            undefined,
            'F2C662',
            undefined,
            '17%'
        );

        slide8.addImage({
            path: infoBleu,
            x: 0.7,
            y: debutYTb1 + 3.17,
            w: '3.5%',
            h: '5%'
        });

        slide8.addText("Les gains fiscaux sont calculés par rapport au scénario de référence (cession dans la situation actuelle fiscalisée à la flat tax) et compte tenu des hypothèses envisagées en termes d'apports / donations avant cession. Il s'agit de gains immédiats estimés post apport / donation / cession et avant succession.", {
            x: 1.25,
            y: debutYTb1 + 3.04,
            w: '65%',
            h: '10%',
            fontFace: 'Gill Sans MT',
            fontSize: 12,
            color: '5271C7'
        });

        // Traitement SLIDE9
        const slide9 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

        generateTitleAndSubtitle(
            "SIMULATION",
            pptx,
            slide9,
            laSimulationProjetCessionTitle
        )

        slide9.addText("TABLEAU DE BORD n°2", {
            x: 0.6,
            y: 1.6,
            w: "90%",
            h: 0.4,
            align: 'center',
            fontFace: 'Gill Sans MT',
            fontSize: 15.8,
            color: blackColor,
            bold: true
        });

        slide9.addText("Situation actuelle", {
            x: 0.6,
            y: 2.09,
            w: "45%",
            align: 'center',
            fontFace: 'Gill Sans MT',
            fontSize: 12,
            w: '45%',
            h: 0.2,
            bold: true,
            color: '808080'
        });

        slide9.addText("", {
            x: 0.6,
            y: 2.36,
            w: "45%",
            h: "0.4%",
            fill: 'EFAF10',
            line: {
                color: 'EFAF10'
            }
        });

        slide9.addText("Cession après apports / donations", {
            x: 6,
            y: 2.09,
            w: "44%",
            h: 0.2,
            align: 'center',
            fontFace: 'Gill Sans MT',
            fontSize: 12,
            bold: true,
            color: '808080'
        });

        slide9.addText("", {
            x: 6,
            y: 2.36,
            w: "44%",
            h: "0.4%",
            fill: '81C457',
            line: {
                color: '81C457'
            }
        });

        generateTableauBord2(
            pptx,
            slide9,
            0.6,
            2.46,
            `${numberFormat(Math.round(tb2.prix_cession))}`,
            `${numberFormat(Math.round(tb2.plus_value_brute))}`,
            `${numberFormat(Math.round(tb2.apport_holding))}`,
            `${numberFormat(Math.round(tb2.donation_pp))}`,
            `${numberFormat(Math.round(tb2.donation_np))}`
        );

        generateScenario(pptx, slide9, 0.6, 3.51, 1, "22%", tb2.scenario_1);
        generateScenario(pptx, slide9, 3.3, 3.51, 2, "22%", tb2.scenario_2);
        generateScenario(pptx, slide9, 6, 3.51, 3, "21.5%", tb2.scenario_3);
        generateScenario(pptx, slide9, 8.65, 3.51, 4, "21.5%", tb2.scenario_4);

        const spaceWidth = 0.00002;
        const resultatScenario1 = tableauParPage(generateTableauSyntheseResultat(cession, 1, syntheseDetaille.scenario1), 15);

        resultatScenario1.map((data, index) => {
            if (data.length > 0) {
                // Traitement SLIDE10
                let slide10 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

                generateTitleAndSubtitle(
                    "SIMULATION",
                    pptx,
                    slide10,
                    laSimulationProjetCessionTitle
                )

                slide10.addText(
                    [
                        { text: "SCENARIO 1 - ", options: { breakLine: false, color: 'A585E6' } },
                        { text: "dans la situation actuelle,", options: { breakLine: true, underline: true, color: blackColor } },
                        { text: "Imposition à la flat tax sans régime de faveur : ", options: { breakLine: false, color: blackColor } },
                        { text: "situation de référence", options: { underline: true, color: blackColor } }
                    ]
                    , {
                        x: 0.6,
                        y: 1.51,
                        w: "90%",
                        h: 0.5,
                        align: 'center',
                        fontFace: 'Gill Sans MT',
                        fontSize: 15.8,
                        bold: true
                    });

                slide10.addTable(data, {
                    x: 0.6,
                    y: 2.16,
                    rowH: 0.35,
                    colW: [3, 1.75, spaceWidth, 1.75, spaceWidth, 1.75, spaceWidth, 1.75]// Départ 2.5
                }
                );
            }
        });

        const resultatScenario2 = tableauParPage(generateTableauSyntheseResultat(cession, 2, syntheseDetaille.scenario2), 15);

        resultatScenario2.map((data, index) => {
            if (data.length) {
                // Traitement SLIDE11
                const slide11 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

                generateTitleAndSubtitle(
                    "SIMULATION",
                    pptx,
                    slide11,
                    laSimulationProjetCessionTitle
                )

                slide11.addText(
                    [
                        { text: "SCENARIO 2 - ", options: { breakLine: false, color: 'A585E6' } },
                        { text: "dans la situation actuelle,", options: { breakLine: true, underline: true, color: blackColor } },
                        { text: "Optimisation fiscale des régimes d’imposition", options: { breakLine: false, color: blackColor } },
                    ]
                    , {
                        x: 0.6,
                        y: 1.51,
                        w: "90%",
                        h: 0.5,
                        align: 'center',
                        fontFace: 'Gill Sans MT',
                        fontSize: 15.8,
                        bold: true
                    });

                slide11.addTable(data, {
                    x: 0.6,
                    y: 2.16,
                    rowH: 0.35,
                    colW: [3, 1.75, spaceWidth, 1.75, spaceWidth, 1.75, spaceWidth, 1.8]// Départ 2.5
                }
                );
            }
        });

        const resultatScenario3 = tableauParPage(generateTableauSyntheseResultat(cession, 3, syntheseDetaille.scenario3), 15);

        resultatScenario3.map((data, index) => {
            if (data.length > 0) {
                // Traitement SLIDE12
                const slide12 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

                generateTitleAndSubtitle(
                    "SIMULATION",
                    pptx,
                    slide12,
                    laSimulationProjetCessionTitle
                )

                slide12.addText(
                    [
                        { text: "SCENARIO 3 - ", options: { breakLine: false, color: 'A585E6' } },
                        { text: "Imposition après apports / donations, ", options: { breakLine: true, color: blackColor } },
                        { text: "avec prise en charge des frais de donation ", options: { breakLine: false, color: blackColor } },
                        { text: "par les donateurs", options: { underline: true, color: blackColor } }
                    ]
                    , {
                        x: 0.6,
                        y: 1.51,
                        w: "90%",
                        h: 0.5,
                        align: 'center',
                        fontFace: 'Gill Sans MT',
                        fontSize: 15.8,
                        bold: true
                    });

                slide12.addTable(data, {
                    x: 0.6,
                    y: 2.16,
                    rowH: 0.35,
                    colW: [3, 1.75, spaceWidth, 1.75, spaceWidth, 1.75, spaceWidth, 1.8]// Départ 2.5
                })

            }
        });

        const resultatScenario4 = tableauParPage(generateTableauSyntheseResultat(cession, 4, syntheseDetaille.scenario4), 15);

        resultatScenario4.map((data, index) => {
            if (data.length > 0) {
                // Traitement SLIDE13
                const slide13 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

                generateTitleAndSubtitle(
                    "SIMULATION",
                    pptx,
                    slide13,
                    laSimulationProjetCessionTitle
                )

                slide13.addText(
                    [
                        { text: "SCENARIO 4 - ", options: { breakLine: false, color: 'A585E6' } },
                        { text: "Imposition après apports / donations, ", options: { breakLine: true, color: blackColor } },
                        { text: "avec prise en charge des frais de donation ", options: { breakLine: false, color: blackColor } },
                        { text: "par les donataires", options: { underline: true, color: blackColor } }
                    ]
                    , {
                        x: 0.6,
                        y: 1.51,
                        w: "90%",
                        h: 0.5,
                        align: 'center',
                        fontFace: 'Gill Sans MT',
                        fontSize: 15.8,
                        bold: true
                    });

                slide13.addTable(data, {
                    x: 0.6,
                    y: 2.16,
                    rowH: 0.35,
                    colW: [3, 1.75, spaceWidth, 1.75, spaceWidth, 1.75, spaceWidth, 1.8]// Départ 2.5
                })
            }
        });
    }
    else {
        // Traitement slide2
        const slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

        const slide2Title = [
            { text: "CESSION DE SOCIÉTÉ", options: { breakLine: true } },
            { text: "", options: { breakLine: true } },
            { text: "Environnement fiscal et pistes de réflexions", options: { breakLine: true } }
        ]

        const slide2Text = [
            { text: "1 - L’IMPOSITION DE LA PLUS-VALUE DE CESSION", options: { breakLine: true, bold: true, color: '4472C4' } },
            { text: "Régime des plus-values de cession de titres réalisées par les particuliers dans le cadre de la gestion de leur patrimoine privé", options: { breakLine: true, italic: true, color: '595959', bold: true } },
            { text: "", options: { breakLine: true } },
            { text: "L’environnement fiscal", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: "Les deux régimes d’imposition à l’IR", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: "Les régimes d’abattement pour le calcul de l’IR", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: "Le choix entre la flat tax et le barème de l’IR", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: "Les prélèvements sociaux (PS)", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: "La contribution exceptionnelle sur les hauts revenus (CEHR)", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: "Le calendrier fiscal", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: "", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: "2 - LES OPÉRATIONS AVANT CESSION", options: { breakLine: true, bold: true, color: '4472C4' } },
            { text: "La chronologie fiscale des opérations", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: " Les apports avant cession", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
            { text: " Les donations avant cession", options: { bullet: { code: '25CF', color: '4472C4' }, breakLine: true, color: '4472C4', indentLevel: 1 } },
        ]

        const xSlide2 = 3.8;
        const ySlide2 = 2;

        // generateSlide1SimulateurTitle(pptx, slide2, slide2Title, "CS", xSlide2 - 1.4, ySlide2 - 1, 'FICHE');
        generateFirstSlideTitle(
            pptx,
            slide2,
            slide2Title,
            xSlide2 - 1.4,
            ySlide2 - 1,
            "FICHE",
            xSlide2 - 1
        )
        generateThreeBarres(slide2, xSlide2 + 1, ySlide2 + 0.6, 4);

        slide2.addText(slide2Text, {
            x: xSlide2 + 1.3,
            y: ySlide2 + 0.6,
            w: 5,
            h: 4,
            fontSize: 11,
            fontFace: 'Roboto',
            align: 'justify',
            color: '5271C7',
            lineSpacingMultiple: 1.3
        })
    }

    // Traitement slide14
    const slide14 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    const slide14Title = [
        { text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION", options: { breakLine: true } },
    ];

    const slide14Text = [
        { text: "Régime des plus-values de cession de titres réalisées par les particuliers dans le cadre de la gestion de leur patrimoine privé", options: { breakLine: true, fontSize: 14, bold: true, color: '595959' } },
        { text: "", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "L’environnement fiscal", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "", options: { breakLine: true } },
        { text: " Les deux régimes d’imposition à l’IR", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "", options: { breakLine: true } },
        { text: " Les régimes d’abattement pour le calcul de l’IR", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "", options: { breakLine: true } },
        { text: " Le choix entre la flat tax et le barème de l’IR", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "", options: { breakLine: true } },
        { text: " Les prélèvements sociaux (PS)", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "", options: { breakLine: true } },
        { text: " La contribution exceptionnelle sur les hauts revenus (CEHR)", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "", options: { breakLine: true } },
        { text: " Le calendrier fiscal", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
    ]

    const xSlide14 = 3.8;
    const ySlide14 = 2;

    // generateSlide1SimulateurTitle(pptx, slide14, slide14Title, "CS", xSlide14 - 1.4, ySlide14 - 1, 'FICHE');
    generateFirstSlideTitle(
        pptx,
        slide14,
        slide14Title,
        xSlide14 - 1.4,
        ySlide14 - 1,
        "FICHE",
        xSlide14 - 1
    )
    generateThreeBarres(slide14, xSlide14 + 0.4, ySlide14 + 0.6, 4);

    slide14.addText(slide14Text, {
        x: xSlide14 + 0.7,
        y: ySlide14 + 0.6,
        w: 5,
        h: 4,
        fontSize: 14,
        fontFace: 'Roboto',
        align: 'justify',
        color: '5271C7',
        // lineSpacingMultiple: 1.3
    })

    const slide15 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide15,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            text: "L’ENVIRONNEMENT FISCAL",
            width: 4
        }
    )

    const bleuB = '4472C4';
    const grisB = 'ADB9CA';
    const grisClair = 'D6DCE5';

    slide15.addText("IR", {
        x: 1.5,
        y: 2.26,
        align: 'center',
        w: '7%',
        h: '4.5%',
        fill: grisB,
        fontFace: 'Gill Sans MT',
        fontSize: 15,
        color: 'FFFFFF',
        bold: true,
        valign: 'center',
        line: {
            color: grisB
        }
    });

    slide15.addText(
        [
            { text: "IR à la FLAT TAX ", options: { breakLine: false, bold: true, color: bleuB } },
            { text: " au taux de " },
            { text: "12,8 %", options: { bold: true, breakLine: false } },
            { text: " (30% avec les", options: { breakLine: true } },
            { text: "prélèvements sociaux) sur la plus-value nette après déduction", options: { breakLine: true } },
            { text: "éventuelle de ", options: { breakLine: false } },
            { text: "l’abattement de 500.000 €", options: { color: bleuB, bold: true } }
        ],
        {
            x: 3.0,
            y: 2.06,
            w: '50%',
            h: 0.8,
            color: blackColor,
            fontFace: 'Gill Sans MT',
            fontSize: 15.8,
            shrinkText: true
        }
    );

    slide15.addText(
        [
            { text: "ou", options: { color: blackColor, underline: true, breakLine: false, fontSize: 16, bold: true } },
            { text: " " },
            { text: "IR au barème", options: { bold: true, color: bleuB } },
            { text: " au taux marginal de ", options: { color: blackColor } },
            { text: "45%", options: { bold: true, color: blackColor } },
            { text: " (à partir de", options: { breakLine: true, color: blackColor } },
            { text: "157 K€ de revenus nets par part) sur la plus-value nette après", options: { breakLine: true, color: blackColor } },
            { text: "déduction éventuelle de l’un des abattements suivants :", options: { breakLine: true, color: blackColor } },
            { text: "abattement de 500.000 €", options: { color: bleuB, bold: true } },
            { text: " ou ", options: { color: blackColor } },
            { text: "abattement de 85%", options: { color: bleuB, bold: true } },
        ],
        {
            x: 3.0,
            y: 3.24,
            w: '50%',
            h: 1,
            fontFace: 'Gill Sans MT',
            fontSize: 15.8
        }
    );

    slide15.addText("+", {
        x: 1.5,
        y: 4.46,
        align: 'center',
        w: '7%',
        h: '4.5%',
        fill: 'FFFFFF',
        fontFace: 'Gill Sans MT',
        fontSize: 15,
        color: blueBaseColor,
        bold: true,
        valign: 'center',
        line: {
            color: 'FFFFFF'
        }
    });

    slide15.addText("PS", {
        x: 1.5,
        y: 4.86,
        align: 'center',
        w: '7%',
        h: '4.5%',
        fill: grisB,
        fontFace: 'Gill Sans MT',
        fontSize: 15,
        color: 'FFFFFF',
        bold: true,
        valign: 'center',
        line: {
            color: grisB
        }
    });

    slide15.addText(
        [
            { text: "Prélèvements sociaux ", options: { fontSize: 17, bold: true } },
            { text: " au taux de ", otpions: { fontSize: 16 } },
            { text: "17,2 %", options: { fontSize: 18, bold: true } },
            { text: " sur la totalité", options: { breakLine: true, fontSize: 16 } },
            { text: "de la plus-value nette" }
        ],
        {
            x: 3.0,
            y: 4.8,
            w: '50%',
            h: 0.5,
            color: blackColor,
            fontFace: 'Gill Sans MT',
        });

    slide15.addText("+", {
        x: 1.5,
        y: 5.36,
        align: 'center',
        w: '7%',
        h: '4.5%',
        fill: 'FFFFFF',
        fontFace: 'Gill Sans MT',
        fontSize: 15,
        color: blueBaseColor,
        bold: true,
        valign: 'center',
        line: {
            color: 'FFFFFF'
        }
    });

    slide15.addText("CEHR", {
        x: 1.5,
        y: 5.76,
        align: 'center',
        w: '7%',
        h: '4.5%',
        fill: grisB,
        fontFace: 'Gill Sans MT',
        fontSize: 15,
        color: 'FFFFFF',
        bold: true,
        valign: 'center',
        line: {
            color: grisB
        }
    });

    slide15.addText(
        [
            { text: "Contribution exceptionnelle sur les hauts revenus ", options: { bold: true, fontSize: 17, breakLine: true } },
            { text: "au taux de ", options: { fontSize: 16 } },
            { text: "3-4%", options: { bold: true, fontSize: 18 } },
            { text: " sur la totalité la plus-value nette" }
        ],
        {
            x: 3.0,
            y: 5.7,
            w: '50%',
            h: 0.5,
            color: blackColor,
            fontFace: 'Gill Sans MT'
        }
    )

    // Traitement slide16
    const slide16 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide16,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            text: "LES DEUX RÉGIMES D’IMPOSITION À L’IR",
            width: 5.2
        }
    )

    slide16.addText(
        [
            { text: "Imposition à la « flat tax »", options: { breakLine: true } },
            { text: "au taux global de 30%" }
        ],
        {
            x: 0.6,
            y: 1.66,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fill: 'DEEBF7',
            w: '43%',
            h: '8%',
            valign: 'center',
            align: 'center',
            bold: true,
            fontSize: 15
        }
    );

    const text1Slide16 = [
        { text: "La plus-value de cession de société est en principe soumise à un prélèvement forfaitaire unique (PFU) ou flat tax au taux de 12,8%, auquel s’ajoutent les prélèvements sociaux au taux de 17,2%, soit une fiscalité totale de ", options: { breakLine: false } },
        { text: " 30 %", options: { color: bleuB, bold: true, breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "L’imposition de la plus-value est établie après compensation avec des moins-values de même nature de l’année ou des dix années précédentes et après certaines déductions.", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "Pour le calcul du PFU au taux de 12,8%, la plus-value nette de cession est éventuellement réduite d’un ", options: { breakLine: false } },
        { text: "abattement fixe de 500.000 € ", options: { color: bleuB, bold: true, breakLine: false } },
        { text: "applicable à certaines cessions de titres de PME réalisées jusqu’au 31/12/2024 par des dirigeants prenant leur retraite.", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "L’imposition au PFU concerne globalement tous les revenus et produits financiers et devient définitive l’année suivant celle de la cession, sauf option globale pour le barème progressif de l’IR." }
    ];

    slide16.addText(text1Slide16, {
        x: 0.6,
        y: 2.4,
        w: '43%',
        h: '50%',
        fontSize: 13,
        align: 'justify',
        fontFace: 'Gill Sans MT',
    });

    slide16.addText(
        [
            { text: "Sauf option expresse pour une imposition ", options: { breakLine: true } },
            { text: "globale au barème progressif de l’IR" }
        ],
        {
            x: "52%",
            y: 1.66,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fill: 'DEEBF7',
            w: '43%',
            h: '8%',
            valign: 'center',
            align: 'center',
            bold: true,
            fontSize: 15
        }
    );

    const text2Slide16 = [
        { text: "L’option pour une imposition globale au barème progressif de l’IR s’exerce dans la déclaration des revenus annuels.", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "Lorsque l’option est exercée, elle concerne tous les revenus, produits et gains financiers (sauf exceptions).", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "L’imposition de la plus-value est établie après compensation avec des moins-values de même nature de l’année ou des dix années précédentes et après certaines déductions.", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        //{ text: "TEXT", options: { breakLine: true } },
        { text: "Pour le calcul de l’IR au barème, la plus-value nette de cession est éventuellement réduite de l’un des abattements suivants :", options: { breakLine: true } }
    ];

    const text2Part2Slide16 = [
        { text: "Abattement de 85%", options: { bold: true, color: bleuB, breakLine: false, bullet: { code: '25A0', color: blackColor } } },
        { text: " pour certaines cessions de titres de PME de moins de 10 ans.", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "Abattement de 500.000 €", options: { bold: true, color: bleuB, breakLine: false, bullet: { code: '25A0', color: blackColor } } },
        { text: " pour certaines cessions de titres de PME réalisées jusqu’au 31/12/2024 par des dirigeants prenant leur retraite." }
    ];

    slide16.addText(text2Slide16, {
        x: "52%",
        y: 2.40,
        w: '43%',
        h: '35%',
        fontSize: 13,
        fontFace: 'Gill Sans MT',
        align: 'justify',
        color: blackColor
    });

    slide16.addText(text2Part2Slide16, {
        x: "54%",
        y: 5.2,
        w: '41.5%',
        h: 1.25,
        fontSize: 13,
        fontFace: 'Gill Sans MT',
        align: 'justify',
        color: blackColor
    });


    slide16.addText("Pour le calcul de l’IR l’année suivant celle de la cession : une fraction de la CSG payée sur la plus-value pourra être déductible des revenus imposables au barème de l’IR.", {
        x: "52%",
        y: 6.6,
        w: '43%',
        h: 0.8,
        fontSize: 13,
        fontFace: 'Gill Sans MT',
        align: 'justify',
        color: blackColor
    });

    // Traitement slide17
    const slide17 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide17,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            x: 5.7,
            text: "LES RÉGIMES D’ABATTEMENT POUR L’IR",
            width: 5.2
        }
    )

    slide17.addText("Abattement fixe de 500.000 €",
        {
            x: 0.6,
            y: 1.66,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fill: 'ADB9CA',
            w: '40%',
            h: '8%',
            valign: 'center',
            align: 'center',
            bold: true,
            fontSize: 15
        }
    );

    slide17.addText(
        [
            { text: "Applicable à certaines cessions de titres de PME", options: { breakLine: true } },
            { text: "réalisées jusqu’au 31 décembre 2024 ", options: { breakLine: true } },
            { text: "par des dirigeants prenant leur retraite", options: { breakLine: true } },
        ],
        {
            x: 0.6,
            y: 2.4,
            w: '40%',
            h: 0.7,
            fontFace: 'Gill Sans MT',
            fontSize: 14,
            bold: true,
            align: 'center',
            color: blackColor
        });

    slide17.addText("", {
        x: 0.6,
        y: 3.16,
        w: "40%",
        h: "0.3%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });

    // Traits sur la partie gauche
    /*slide17.addText("", {
        x: 0.6,
        y: 3.46,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: 0.6,
        y: 3.76,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: 0.6,
        y: 4.77,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: 0.9,
        y: 5.16,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: 0.9,
        y: 5.46,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: 0.9,
        y: 6.11,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: 0.6,
        y: 6.64,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });*/

    const text1Slide17 = [
        { text: "Impôt concerné : FLAT TAX ou BARÈME de l’IR", options: { bold: true, breakLine: true, bullet: { code: '25A0' } } }
    ];

    const text2Slide17 = [
        { text: "Avantage fiscal ", options: { bold: true, bullet: { code: '25A0' } } },
        { text: ": 100% de la plus-value est exonérée d’IR (flat tax ou barème) jusqu’à 500.000 € ; étant précisé que l’abattement s’applique à chaque cédant pour l’ensemble des gains afférents à une même société.", options: { breakLine: true } },
    ];

    slide17.addText(text1Slide17,
        {
            x: 0.5,
            y: 3.35,
            w: '42%',
            h: 0.2,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fontSize: 13
        });

    slide17.addText(text2Slide17,
        {
            x: 0.5,
            y: 3.68,
            w: '42%',
            h: 0.85,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fontSize: 13
        });

    slide17.addText("Principales conditions cumulatives (non exhaustif)",
        {
            x: 0.5,
            y: 4.64,
            w: '42%',
            h: 0.3,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            bold: true,
            fontSize: 13,
            bullet: {
                code: '25A0',
                indentLevel: 1
            }
        });

    slide17.addText("La cession porte sur des titres d’une PME soumise à l’IS.",
        {
            x: 1,
            y: 5,
            w: '35%',
            h: 0.3,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fontSize: 11,
            bullet: {
                code: '25CF',
                indentLevel: 2
            }
        });

    slide17.addText("Le cédant exerce des fonctions de direction et détient une participation de plus de 25% avec son groupe familial de manière continue depuis au moins 5 ans",
        {
            x: 1,
            y: 5.4,
            w: '35%',
            h: 0.5,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fontSize: 11,
            w: "37%",
            bullet: {
                code: '25CF',
                indentLevel: 2
            }
        });

    slide17.addText("Le cédant cède la totalité de sa participation et prend sa retraite dans un intervalle de 2 ans",
        {
            x: 1,
            y: 6.02,
            w: '35%',
            h: 0.4,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fontSize: 11,
            w: "37%",
            bullet: {
                code: '25CF',
                indentLevel: 2
            }
        });

    slide17.addText(
        [
            { text: "Règle de non cumul :", options: { bold: true, bullet: { code: '25A0' } } },
            { text: " cet abattement fixe de 500.000 € n'est " },
            { text: "pas cumulable", options: { bold: true } },
            { text: " avec l’abattement de 85%. Il n’est pas applicable pour l’imposition aux PS, ni à la CEHR." }
        ],
        {
            x: 0.5,
            y: 6.56,
            w: '42%',
            h: 0.6,
            fontSize: 13,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            w: '42%'
        }
    );


    slide17.addText("NON             CUMULABLE",
        {
            x: "48%",
            y: 1.66,
            fontFace: 'Gill Sans MT',
            color: '28366D',
            fill: grisClair,
            w: '4%',
            h: '68%',
            valign: 'center',
            align: 'center',
            fontSize: 15,
            inset: 1.25
        }
    );

    slide17.addText("Abattement proportionnel de 85%",
        {
            x: "55%",
            y: 1.66,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fill: 'ADB9CA',
            w: '40%',
            h: '8%',
            valign: 'center',
            align: 'center',
            bold: true,
            fontSize: 15
        }
    );

    slide17.addText(
        [
            { text: "Applicable à certaines cessions de titres de PME ", options: { breakLine: true } },
            { text: "acquis au cours des 10ères années de la création", options: { breakLine: true } },
            { text: "de la société et détenus depuis plus de 8 ans", options: { breakLine: true } },
        ],
        {
            x: "55%",
            y: 2.4,
            w: '40%',
            h: 0.7,
            fontFace: 'Gill Sans MT',
            fontSize: 14,
            bold: true,
            align: 'center'
        });

    slide17.addText("", {
        x: "55%",
        y: 3.16,
        w: "40%",
        h: "0.3%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });

    /*slide17.addText("", {
        x: "55%",
        y: 3.46,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: "55%",
        y: 3.77,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: "55%",
        y: 4.28,
        w: "0.4%",
        h: "0.4%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: "57.3%",
        y: 4.56,
        w: "0.3%",
        h: "0.3%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: "57.3%",
        y: 5.45,
        w: "0.3%",
        h: "0.3%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: "57.3%",
        y: 6.1,
        w: "0.3%",
        h: "0.3%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });
    
    slide17.addText("", {
        x: "57.3%",
        y: 6.58,
        w: "0.3%",
        h: "0.3%",
        fill: blackColor,
        line: {
            color: blackColor
        }
    });*/

    slide17.addText(
        [
            { text: "Impôt concerné : " },
            { text: "BARÈME IR", options: { fill: orange } },
        ],
        {
            x: "54%",
            y: 3.35,
            w: '40%',
            h: 0.2,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            bold: true,
            fontSize: 13,
            bullet: {
                code: '25A0'
            }
        });

    slide17.addText(
        [
            { text: "Avantage fiscal : ", options: { bold: true } },
            { text: "85% de la plus-value est exonérée du barème de l’IR, sans limitation de montant" },
        ],
        {
            x: "54%",
            y: 3.68,
            w: '40%',
            h: 0.4,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fontSize: 13,
            w: '40%',
            bullet: {
                code: '25A0'
            }
        });

    slide17.addText("Régime applicable uniquement si : ",
        {
            x: "54%",
            y: 4.2,
            w: '40%',
            h: 0.2,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            fontSize: 13,
            w: '40%',
            bold: true,
            bullet: {
                code: '25A0'
            }
        });

    slide17.addText(
        [
            { text: "Lors de l’acquisition ou la souscription des titres, la société est une PME créée depuis moins de10 ans et ", options: { color: grayLetter } },
            { text: "et n’est pas issue de d’une restructuration,  d’une extension ou d’une reprise d’activités préexistantes", options: { color: orange } }
        ],
        {
            x: "58.5%",
            y: 4.46,
            fontSize: 11,
            fontFace: 'Gill Sans MT',
            w: '37%',
            h: 0.8,
            bullet: {
                code: '25CF',
                indentLevel: 2
            }
        });

    slide17.addText("Pendant toute la durée de détention des titres, la société exerce une activité opérationnelle, elle est soumise à l’IS et n’accorde aucune garantie en capital à ses associés",
        {
            x: "58.5%",
            y: 5.34,
            fontSize: 11,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            w: '37%',
            h: 0.6,
            bullet: {
                code: '25CF',
                indentLevel: 2
            }
        });

    slide17.addText(
        [
            { text: "Les titres ont été acquis ", options: { color: grayLetter, breakLine: false } },
            { text: "avant 2018 ", options: { color: orange, breakLine: false } },
            { text: "et sont détenus depuis au moins 8 ans pour ouvrir droit au taux majoré de 85%", options: { color: grayLetter } }
        ],
        {
            x: "58.5%",
            y: 6,
            fontSize: 11,
            fontFace: 'Gill Sans MT',
            w: '37%',
            h: 0.4,
            bullet: {
                code: '25CF',
                indentLevel: 2
            }
        });

    slide17.addText(
        [
            { text: "Règle de non cumul : ", options: { breakLine: false, bold: true } },
            { text: "cet abattement de 85% n’est pas cumulable avec l’abattement de 500.000 €. Il ne s’applique pas en cas d’imposition à la flat tax. Il ne s’applique pas pour l’imposition aux PS, ni à la CEHR.", options: { breakLine: false } },
        ],
        {
            x: "54%",
            y: 6.5,
            fontSize: 13,
            fontFace: 'Gill Sans MT',
            color: grayLetter,
            w: '40%',
            h: 0.85,
            bullet: {
                code: '25A0'
            }
        });

    // Traitement SLIDE18
    const slide18 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide18,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            text: "LE CHOIX ENTRE LA FLAT TAX ET LE BARÈME IR",
            width: 5.7
        }
    )

    slide18.addText("LES 4 SCÉNARIOS ENVISAGEABLES", {
        x: 0.6,
        y: 1.8,
        w: "90%",
        h: 0.5,
        align: 'center',
        fontFace: 'Gill Sans MT',
        fontSize: 15.8,
        color: blackColor,
        bold: true
    });

    slide18.addText(
        [
            { text: "Comparatif des impositions sur la plus-value de cession de société", options: { breakLine: true } },
            { text: "(prélèvements sociaux inclus et hors contribution exceptionnelle sur les hauts revenus – CEHR)" }
        ]
        , {
            x: 0.6,
            y: 2.3,
            w: "90%",
            h: 0.5,
            color: grayLetter,
            align: 'center',
            fontFace: 'Gill Sans MT',
            fontSize: 12,
            bold: true
        });

    generateChoixFlatTax(slide18, 1, 1.1, 3.5, "16%");

    generateChoixFlatTax(slide18, 2, 3.7, 3.5, "16%");

    generateChoixFlatTax(slide18, 3, 6.3, 3.5, "16%");

    generateChoixFlatTax(slide18, 4, 8.9, 3.5, "16%");

    slide18.addText(
        [
            { text: "Le TMI désigne le taux marginal d’imposition au barème progressif de l’IR", options: { breakLine: true } },
            { text: "Le taux de 45% s’applique à partir de 157.000 € de revenus net par part" }
        ],
        {
            x: 1.0,
            y: 6.8,
            w: '50%',
            h: 0.4,
            fontFace: 'Gill Sans MT',
            fontSize: 12,
            color: grayLetter,
            italic: true
        }
    );

    // Traitement SLIDE19
    const slide19 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide19,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            text: "LE CHOIX ENTRE LA FLAT TAX ET LE BARÈME IR",
            width: 5.7
        }
    )

    slide19.addText("LES ELEMENTS CLÉS POUR UN CHOIX FISCAL OPTIMAL", {
        x: 0.6,
        y: 1.9,
        w: "90%",
        h: 0.4,
        align: 'center',
        fontFace: 'Gill Sans MT',
        color: blackColor,
        fontSize: 15.8,
        bold: true
    });

    const slide19Text = [
        { text: "La flat tax est fiscalement plus avantageuse que le barème de l’IR sauf si la plus-value est éligible à l’abattement de 85% (régime des cessions de titres de PME acquis dans les 10 ans de sa création)", options: { breakLine: true, bullet: { code: '25A0' } } },
        { text: "", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "La flat tax est compatible avec l’abattement de 500.000 € (régime applicable aux dirigeants partant en retraite) qui permet d’économiser jusqu’à 64.000 € d’impôt par cédant (= PV 500.000 € x FLAT TAX 12,80%)", options: { breakLine: true, bullet: { code: '25A0' } } },
        { text: "", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "Lorsque la plus-value de cession est éligible à la fois à l’abattement fixe de 500.0000 € et à l’abattement proportionnel de 85% : la meilleure option fiscale dépendra alors du montant de la plus-value nette : ", options: { breakLine: true, bullet: { code: '25A0' } } },
        { text: "", options: { breakLine: true } },
        { text: "Pour une plus-value nette > 1 M€ : BAREME avec abattement 85% sans abattement 500.000 €", options: { breakLine: true, bullet: { code: '25CF', indentLevel: 2 } } },
        { text: "", options: { breakLine: true } },
        { text: "Pour une plus-value nette comprise entre 500.000 € et 1 M€ : FLAT TAX avec abattement de 500.000 €", options: { breakLine: true, bullet: { code: '25CF', indentLevel: 2 } } },
        { text: "", options: { breakLine: true } },
        { text: "Pour une plus-value nette < 500.000 € : FLAT TAX ou BAREME avec abattement 500.000 €", options: { breakLine: true, bullet: { code: '25CF', indentLevel: 2 } } },
    ]

    slide19.addText(slide19Text, {
        x: 1,
        y: 2.5,
        w: '88%',
        h: 3.5,
        fontSize: 13,
        fontFace: 'Gill Sans MT',
        valign: 'middle'
    })

    // Traitement SLIDE20
    const slide20 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide20,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            text: "LE CHOIX ENTRE LA FLAT TAX ET LE BARÈME IR",
            width: 5.7
        }
    )

    const eligibleAbattement500Text = [
        { text: "Eligible à", options: { breakLine: true, bold: true } },
        { text: "l'abattement de", options: { breakLine: true, bold: true } },
        { text: "500.000 €", options: { breakLine: true, bold: true } },
    ];

    const baremeText = [
        { text: "BAREME IR", options: { breakLine: true, bold: true } },
        { text: " avec abatt 85%", options: { bold: true } }
    ];

    const exonerationText = [
        { text: "EXONERATION IR avec abatt. 500.000 €", options: { breakLine: true, bold: true } },
        { text: "(flat tax ou barème)", options: { bold: true } }
    ];

    const pvNetteText = [
        { text: "PV nette entre", options: { breakLine: true } },
        { text: "0,5 M€ et 1 M€" }
    ];

    const flatTax1Text = [
        { text: "", options: { breakLine: true } },
        { text: "Flat tax", options: { breakLine: true, bold: true } },
        { text: "avec abatt 500.000 €", options: { bold: true } },
    ];

    const flatTax2Text = [
        { text: "", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "Flat tax", options: { breakLine: true, bold: true } },
        { text: "avec abatt 500.000 €", options: { breakLine: true, bold: true } },
        { text: "sans abatt 85%" },
    ];

    const bareme2Text = [
        { text: "BAREME IR", options: { breakLine: true, bold: true } },
        { text: "sans abatt 500.000 €", options: { breakLine: true } },
        { text: "avec abatt 85%", options: { bold: true } }
    ];

    const rowsSlide20 = [
        [
            {
                text: "LA TABLE D’AIDE À LA DÉCISION",
                options: {
                    fontFace: 'Gill Sans MT',
                    bold: true,
                    fontSize: 15,
                    fill: 'FFFFFF',
                    color: blackColor,
                    colspan: 3,
                    align: 'center',
                    valign: 'middle',
                    /*border: [
                        {
                            pt: 1,
                            color: '808080'
                        },
                        {
                            pt: "R",
                            color: ''
                        },
                        {
                            pt: "B",
                            color: 'FFFFFF'
                        },
                        {
                            pt: 1,
                            color: '808080'
                        },
                    ]*/
                }
            },
            {
                text: "Eligible à l'abattement de 85 %",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fill: 'ADB9CA',
                    colspan: 2,
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    border: [
                        {
                            pt: 0,
                            color: ""
                        },
                        {
                            pt: "R",
                            color: ""
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            }
        ],
        [
            {
                text: "Taux d’imposition PS inclus et hors CEHR",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: orange,
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: 'FFFFFF',
                    colspan: 3,
                    /*border: [
                        {
                            pt: 'T',
                            color: 'FFFFFF'
                        },
                        {
                            pt: 'R',
                            color: 'FFFFFF'
                        },
                        {
                            pt: 'L',
                            color: 'FFFFFF'
                        },
                        {
                            pt: 1,
                            color: '808080'
                        },
                    ]*/
                }
            },
            {
                text: "NON",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: orange,
                    border: [
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            },
            {
                text: "OUI",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: green,
                    border: [
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "R",
                            color: green
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: green
                        },
                    ]
                }
            },
        ],
        [
            {
                text: eligibleAbattement500Text,
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: 'ADB9CA',
                    rowspan: 4,
                    /*border: [
                        {
                            pt: "T",
                            color: "28366D"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 'B',
                            color: "28366D"
                        },
                        {
                            pt: "L",
                            color: "28366D"
                        },
                    ]*/
                }
            },
            {
                text: "NON",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: orange,
                    colspan: 2,
                    border: [
                        {
                            pt: "T",
                            color: orange
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            },
            {
                text: "FLAT TAX",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: orange,
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: 'DCE6F1',
                    bold: true,
                    border: [
                        {
                            pt: "T",
                            color: ""
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            },
            {
                text: baremeText,
                options: {
                    fontFace: 'Gill Sans MT',
                    color: '28366D',
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: 'FDE9D9',
                    border: [
                        {
                            pt: "T",
                            color: ""
                        },
                        {
                            pt: "R",
                            color: "FDE9D9"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            },
        ],
        [
            //{ text: "" },
            {
                text: "OUI",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: green,
                    rowspan: 3,
                    border: [
                        {
                            pt: "T",
                            color: ""
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 'B',
                            color: green
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                    ]
                }
            },
            {
                text: "PV nette < 0,5 M€",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: green,
                    border: [
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            },
            {
                text: exonerationText,
                options: {
                    fontFace: 'Gill Sans MT',
                    color: green,
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: 'EEECE1',
                    colspan: 2,
                    border: [
                        {
                            pt: "T",
                            color: ""
                        },
                        {
                            pt: "R",
                            color: "EEECE1"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            }
        ],
        [
            //{ text: "" },
            //{ text: "" },
            {
                text: pvNetteText,
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: green,
                    border: [
                        {
                            pt: "T",
                            color: ""
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                    ]
                }
            },
            {
                text: flatTax1Text,
                options: {
                    fontFace: 'Gill Sans MT',
                    color: orange,
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: 'DCE6F1',
                    border: [
                        {
                            pt: "T",
                            color: ""
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            },
            {
                text: flatTax2Text,
                options: {
                    fontFace: 'Gill Sans MT',
                    color: orange,
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: 'DCE6F1',
                    border: [
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "R",
                            color: "DCE6F1"
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            },
        ],
        [
            //{ text: "" },
            //{ text: "" },
            {
                text: "PV nette > 1 M€",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: green,
                    border: [
                        {
                            pt: "T",
                            color: ""
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: 'B',
                            color: green
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                    ]
                }
            },
            {
                text: flatTax1Text,
                options: {
                    fontFace: 'Gill Sans MT',
                    color: orange,
                    fontSize: 11,
                    align: 'center',
                    valign: 'middle',
                    fill: 'DCE6F1',
                    border: [
                        {
                            pt: "T",
                            color: ""
                        },
                        {
                            pt: 3,
                            color: "FFFFFF"
                        },
                        {
                            pt: "B",
                            color: "DCE6F1"
                        },
                        {
                            pt: "L",
                            color: ""
                        },
                    ]
                }
            },
            { text: bareme2Text, options: { fontFace: 'Gill Sans MT', color: '28366D', fontSize: 11, align: 'center', valign: 'middle', fill: 'FDE9D9' } },
        ],
    ];

    slide20.addTable(rowsSlide20, {
        x: 0.6,
        y: 1.76,
        colW: [1.5, 1.2, 1.6, 3.1, 3.1],
        rowH: [0.5, 0.5, 0.7, 1, 1.3, 1.3]
    });

    generateBlockOrange(slide20, "30 %", 4.97, 2.85, "5%");
    generateBlockOrange(slide20, "17,2 %", 4.97, 3.55, "7%");

    generateBlockOrange(slide20, "17,2 %", 4.97, 4.55, "7%");
    generateBlockOrange(slide20, "30 %", 6.0, 4.55, "7%");

    generateBlockOrange(slide20, "17,2 %", 8.05, 4.55, "7%");
    generateBlockOrange(slide20, "30 %", 9.1, 4.55, "7%");

    generateBlockOrange(slide20, "17,2 %", 4.97, 5.85, "7%");
    generateBlockOrange(slide20, "30 %", 6.0, 5.85, "7%");

    generateBlockOrange(slide20, "23,95 %", 8.05, 5.85, "7.3%", true);
    generateBlockOrange(slide20, "23,95 %", 8.05, 2.85, "7.3%", true);

    // Traitement SLIDE21
    const slide21 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide21,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            text: "LE CHOIX ENTRE LA FLAT TAX ET LE BARÈME IR",
            width: 5.7
        }
    )

    slide21.addText(
        [
            { text: "DES ILLUSTRATIONS CHIFFRÉES POUR LES DIFFÉRENTS CHOIX FISCAUX", options: { breakLine: true } },
            { text: "EN FONCTION DU MONTANT DE LA PLUS-VALUE NETTE" }
        ]
        , {
            x: 0.6,
            y: 1.8,
            w: "90%",
            h: 0.6,
            align: 'center',
            fontFace: 'Gill Sans MT',
            color: blackColor,
            fontSize: 15.8,
            bold: true
        });

    slide21.addText(
        [
            { text: "Tableau proposant un comparatif des impositions sur la plus-value par cédant", options: { breakLine: true } },
            { text: " (prélèvements sociaux inclus et hors contribution exceptionnelle sur les hauts revenus – CEHR)" }
        ]
        , {
            x: 0.6,
            y: 2.5,
            w: "90%",
            h: 0.5,
            color: blackColor,
            align: 'center',
            fontFace: 'Gill Sans MT',
            fontSize: 12,
        });

    const slide21Rows = [
        [
            { text: "", /*options: { border: [ { pt: 1, color: '808080' }, { pt: 0, color: '808080' }, { pt: 0, color: '808080' }, { pt: 1, color: '808080' },  ] }*/ },
            { text: [{ text: "FLAT TAX", options: { breakLine: true } }, { text: "30%" }], options: { color: '28366D', fill: 'DAE3F3', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FDE9D9' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: [{ text: "FLAT TAX", options: { breakLine: true } }, { text: "30%" }], options: { color: '28366D', fill: 'DAE3F3', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', colspan: 2, border: [{ pt: 3, color: 'FDE9D9' }, { pt: 4, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: [{ text: "BAREME IR", options: { breakLine: true } }, { text: "AVEC TMI 45%" }], options: { color: '28366D', fill: 'DAE3F3', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', colspan: 2, border: [{ pt: 3, color: 'EEECE1' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: [{ text: "BAREME IR", options: { breakLine: true } }, { text: "AVEC TMI 45%" }], options: { color: '28366D', fill: 'DAE3F3', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', colspan: 2, border: [{ pt: 3, color: 'EEECE1' }, { pt: 'R', color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' },] } },
        ],
        [
            { text: [{ text: "Montant du gain net", options: { breakLine: true } }, { text: "par cédant" }], options: { color: 'FFFFFF', fill: '28366D', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 'T', color: '28366D' }, { pt: 3, color: 'FFFFFF' }, { pt: 2, color: 'FFFFFF' }, { pt: 3, color: '28366D' },] } },
            { text: [{ text: "REGIME", options: { breakLine: true } }, { text: "GENERAL" }], options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FDE9D9' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: [{ text: "avec abatt. ", options: { breakLine: false } }, { text: "500.000 €", options: { bold: true } }], options: { color: blackColor, fill: 'FBE5D6', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', colspan: 2, border: [{ pt: 3, color: 'FDE9D9' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: [{ text: "avec abatt. ", options: { breakLine: false } }, { text: "500.000 €", options: { bold: true } }], options: { color: blackColor, fill: 'FBE5D6', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', colspan: 2, border: [{ pt: 3, color: 'FDE9D9' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: [{ text: "avec abatt. ", options: { breakLine: false } }, { text: "85%", options: { bold: true } }], options: { color: blackColor, fill: 'FBE5D6', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', colspan: 2, border: [{ pt: 3, color: 'FDE9D9' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' },] } },
        ],
        [
            { text: "500 000 €", options: { fill: 'DCE6F1', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 3, color: 'DCE6F1' },] } },
            { text: "150 000 €", options: { color: 'FFFFFF', fill: 'D5725D', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'D5725D' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "86 000 €", options: { color: 'FFFFFF', fill: green, align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: green }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "17,20 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "86 000 €", options: { color: 'FFFFFF', fill: green, align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: green }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "17,20 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "119 750 €", options: { color: 'FFFFFF', fill: 'F2BC4C', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'F2BC4C' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "23,95 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
        ],
        [
            { text: "1 000 000 €", options: { fill: 'DCE6F1', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 3, color: 'DCE6F1' },] } },
            { text: "300 000 €", options: { color: 'FFFFFF', fill: 'ED8F00', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'ED8F00' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "236 000 €", options: { color: 'FFFFFF', fill: green, align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: green }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "23,60 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "397 000 €", options: { color: 'FFFFFF', fill: 'D5725D', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'D5725D' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "39,70 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "239 500 €", options: { color: 'FFFFFF', fill: 'F2BC4C', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'F2BC4C' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "23,95 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
        ],
        [
            { text: "1 500 000 €", options: { fill: 'DCE6F1', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 3, color: 'DCE6F1' },] } },
            { text: "450 000 €", options: { color: 'FFFFFF', fill: 'ED8F00', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'ED8F00' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "386 000 €", options: { color: 'FFFFFF', fill: 'F2BC4C', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'F2BC4C' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "25,73 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "708 000 €", options: { color: 'FFFFFF', fill: 'D5725D', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'D5725D' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "47,20 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "359 250 €", options: { color: 'FFFFFF', fill: green, align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: green }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "23,95 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
        ],
        [
            { text: "2 000 000 €", options: { fill: 'DCE6F1', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 3, color: 'DCE6F1' },] } },
            { text: "600 000 €", options: { color: 'FFFFFF', fill: 'ED8F00', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'ED8F00' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "536 000 €", options: { color: 'FFFFFF', fill: 'F2BC4C', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'F2BC4C' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "26,80 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "1 019 000 €", options: { color: 'FFFFFF', fill: 'D5725D', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'D5725D' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "50,95 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "479 000 €", options: { color: 'FFFFFF', fill: green, align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: green }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "23,95 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
        ],
        [
            { text: "3 000 000 €", options: { fill: 'DCE6F1', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 3, color: 'DCE6F1' },] } },
            { text: "900 000 €", options: { color: 'FFFFFF', fill: 'ED8F00', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'ED8F00' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "836 000 €", options: { color: 'FFFFFF', fill: 'F2BC4C', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'F2BC4C' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "27,87 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "1 641 000 €", options: { color: 'FFFFFF', fill: 'D5725D', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'D5725D' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "54,70 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "718 500 €", options: { color: 'FFFFFF', fill: green, align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: green }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "23,95 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
        ],
        [
            { text: "5 000 000 €", options: { fill: 'DCE6F1', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 3, color: 'DCE6F1' },] } },
            { text: "1 500 000 €", options: { color: 'FFFFFF', fill: 'ED8F00', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'ED8F00' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "1 436 000 €", options: { color: 'FFFFFF', fill: 'F2BC4C', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'F2BC4C' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "28,72 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "2 885 000 €", options: { color: 'FFFFFF', fill: 'D5725D', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'D5725D' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "57,70 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
            { text: "1 197 500 €", options: { color: 'FFFFFF', fill: green, align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: green }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "23,95 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: '808080' }, { pt: 'L', color: '' },] } },
        ],
        [
            { text: "10 000 000 €", options: { fill: 'DCE6F1', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'DCE6F1' }, { pt: 3, color: 'DCE6F1' },] } },
            { text: "3 000 000 €", options: { color: 'FFFFFF', fill: 'ED8F00', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'ED8F00' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "2 936 000 €", options: { color: 'FFFFFF', fill: 'F2BC4C', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'F2BC4C' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "29,36 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: 'FFFFFF' }, { pt: 'L', color: '' },] } },
            { text: "5 995 000 €", options: { color: 'FFFFFF', fill: 'D5725D', align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: 'D5725D' }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "59,95 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: 'FFFFFF' }, { pt: 'L', color: '' },] } },
            { text: "2 395 000 €", options: { color: 'FFFFFF', fill: green, align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 3, color: 'FFFFFF' }, { pt: 0.5, color: green }, { pt: 3, color: 'FFFFFF' },] } },
            { text: "23,95 %", options: { align: 'center', valign: 'middle', fontFace: 'Gill Sans MT', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 4, color: 'FFFFFF' }, { pt: 0.5, color: 'FFFFFF' }, { pt: 'L', color: '' },] } },
        ],
    ];

    slide21.addTable(slide21Rows, {
        x: 0.85,
        y: 3.3,
        colW: [2, 1.5, 1.5, 0.7, 1.5, 0.7, 1.5, 0.7],
        rowH: [0.75, 0.5, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35]
    });

    slide21.addText("1", {
        x: 2.85,
        y: 3.1,
        fontFace: 'Gill Sans MT',
        fontSize: 19.3,
        w: "4%",
        h: "4%",
        color: 'FFFFFF',
        fill: '5B9BD5',
        align: 'center',
        valign: 'middle'
    });

    slide21.addText("2", {
        x: 4.4,
        y: 3.1,
        fontFace: 'Gill Sans MT',
        fontSize: 19.3,
        w: "4%",
        h: "4%",
        color: 'FFFFFF',
        fill: '5B9BD5',
        align: 'center',
        valign: 'middle'
    });

    slide21.addText("3", {
        x: 6.6,
        y: 3.1,
        fontFace: 'Gill Sans MT',
        fontSize: 19.3,
        w: "4%",
        h: "4%",
        color: 'FFFFFF',
        fill: '5B9BD5',
        align: 'center',
        valign: 'middle'
    });

    slide21.addText("4", {
        x: 8.8,
        y: 3.1,
        fontFace: 'Gill Sans MT',
        fontSize: 19.3,
        w: "4%",
        h: "4%",
        color: 'FFFFFF',
        fill: '5B9BD5',
        align: 'center',
        valign: 'middle'
    });

    // Traitement SLIDE22
    const slide22 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide22,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            x: 5.7,
            text: "LES PRÉLÈVEMENTS SOCIAUX (PS)",
            width: 4.6
        }
    )

    const slide22Text = [
        { text: "La plus-value de cession est soumise aux PS au taux actuel de 17,20% sur son montant net, quel que soit le régime d’imposition à l’IR (flat tax ou barème)", options: { breakLine: true, bullet: { code: '25A0' } } },
        { text: "> Pour une PV nette de 100, les PS sont de 17,20", options: { breakLine: true } },
        { text: "> Les abattements de 500.000 € et 85% ne sont pas applicables pour les PS", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "Seule la plus-value soumise sur option globale au barème de l’IR ouvre droit à déduction partielle de la CSG au taux de 6,8%", options: { breakLine: true, bullet: { code: '25A0' } } },
        { text: "> Pour une PV nette de 100, la CSG déductible sera en principe de 6,8", options: { breakLine: true } },
        { text: "> Mais si la PV bénéficie d’un abattement pour l’IR, la CSG déductible sera calculée sur le montant de la PV nette après abattement : ainsi pour une PV nette de 100 avec abattement de 85%, la CSG déductible sera (100 – 85) x 6,8% = 1,02", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "La déduction de la CSG s’impute au titre de l’année du paiement", options: { breakLine: true, bullet: { code: '25A0' } } },
        { text: "> Pour une cession réalisée en N soumise au barème de l’IR, la déduction s’imputera sur les revenus N+1 soumis au barème", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "Mais l’excédent de CSG déductible est perdue (sans possibilité de report sur les années suivantes)", options: { breakLine: true, bullet: { code: '25A0' } } },
    ]

    slide22.addText(slide22Text, {
        x: 0.8,
        y: 2.3,
        w: '87.5%',
        h: 4,
        fontSize: 13,
        fontFace: 'Gill Sans MT',
        color: blackColor
    })

    // Traitement SLIDE23
    const slide23 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide23,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            text: "LA CEHR",
            width: 2.3
        }
    )

    slide23.addText("La contribution exceptionnelle sur les hauts revenus (CEHR) concerne tous les revenus et gains annuels qui sont supérieurs à 250.000 ou 500.000 € (selon la situation familiale), y compris des revenus ou gains exonérés et certaines déductions ou abattements.", {
        x: 0.6,
        y: 1.9,
        fontFace: 'Gill Sans MT',
        w: '88%',
        h: 0.7,
        bold: true,
        fontSize: 14,
        color: blackColor,
    });

    slide23.addText(
        [
            { text: "Contribuables concernés : ", options: { color: '4472C4', breakLine: false, bold: true, bullet: { code: '25A0' } } },
            { text: "Les contribuables dont le revenu fiscal de référence (RFR) est supérieur à 250.000 € pour une personne seule et 500.000 € pour un couple marié ou pacsé. Le RFR s’apprécie au niveau du foyer fiscal au sens de l’IR", options: { color: blackColor } }
        ], {
        x: 0.85,
        y: 2.88,
        w: '87%',
        h: 0.5,
        fontSize: 14,
        fontFace: 'Gill Sans MT'
    });

    slide23.addText(
        [
            { text: "Plus-value prise en compte : ", options: { color: '4472C4', breakLine: false, bold: true, bullet: { code: '25A0' } } },
            { text: "le RFR inclut la plus-value de cession de société pour son montant net imposable à l’IR, avant abattement éventuel. Le régime d’imposition choisi à l’IR (flat tax versus barème) est donc sans incidence au regard de la CEHR au titre de l’année de cession.", options: { color: blackColor } }
        ], {
        x: 0.85,
        y: 3.68,
        w: '87%',
        h: 0.65,
        fontSize: 14,
        fontFace: 'Gill Sans MT'
    });

    slide23.addText("Quel taux d’imposition ?", {
        x: 0.85,
        y: 4.65,
        w: '87%',
        h: 0.3,
        fontSize: 14,
        fontFace: 'Gill Sans MT',
        color: '4472C4',
        bold: true,
        bullet: {
            code: '25A0'
        }
    });

    const bords = [
        {
            pt: 1,
            color: 'FFFFFF'
        },
        {
            pt: 1,
            color: 'FFFFFF'
        },
        {
            pt: 1,
            color: 'FFFFFF'
        },
        {
            pt: 1,
            color: 'FFFFFF'
        },
    ]

    const rowsSlide23 = [
        [
            {
                text: [{ text: "Fraction du RFR", options: { breakLine: true } }, { text: "pour personne seule" }],
                options: {
                    fill: 'ADB9CA',
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 12,
                    bold: true,
                    align: 'center',
                    valign: 'middle',
                    border: [...bords]
                }
            },
            {
                text: "Taux",
                options: {
                    fill: 'ADB9CA',
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 12,
                    bold: true,
                    align: 'center',
                    valign: 'middle',
                    border: [...bords]
                }
            },
            {
                text: [{ text: "Fraction du RFR", options: { breakLine: true } }, { text: "pour couple marié / pacsé" }],
                options: {
                    fill: 'ADB9CA',
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    fontSize: 12,
                    align: 'center',
                    valign: 'middle',
                    bold: true,
                    border: [...bords]
                }
            },
        ],
        [
            {
                text: [{ text: "inférieur à ", options: { breakLine: false } }, { text: "250.000 €", options: { breakLine: false, bold: true } }],
                options: {
                    fontFace: 'Gill Sans MT',
                    color: grayLetter,
                    fontSize: 12,
                    align: 'center',
                    valign: 'middle',
                    border: [...bords],
                    fill: 'F2F2F2'
                }
            },
            {
                text: "0%",
                options: {
                    fontFace: 'Gill Sans MT',
                    fontSize: 12,
                    color: grayLetter,
                    valign: 'middle',
                    align: 'center',
                    border: [...bords],
                    fill: 'F2F2F2'
                }
            },
            {
                text: [{ text: "inférieur à ", options: { breakLine: false } }, { text: "500.000 €", options: { breakLine: false, bold: true } }],
                options: {
                    fontFace: 'Gill Sans MT',
                    color: grayLetter,
                    fontSize: 12,
                    align: 'center',
                    valign: 'middle',
                    border: [...bords],
                    fill: 'F2F2F2'
                }
            },
        ],
        [
            {
                text: "Entre 250.000 € et 500.000 €",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: grayLetter,
                    fontSize: 12,
                    align: 'center',
                    valign: 'middle',
                    border: [...bords],
                    fill: 'F2F2F2'
                }
            },
            {
                text: "3%",
                options: {
                    fontFace: 'Gill Sans MT',
                    fontSize: 12,
                    color: grayLetter,
                    valign: 'middle',
                    align: 'center',
                    border: [...bords],
                    fill: 'F2F2F2'
                }
            },
            {
                text: "Entre 500.000 € et 1 000 000",
                options: {
                    fontFace: 'Gill Sans MT',
                    color: grayLetter,
                    fontSize: 12,
                    align: 'center',
                    valign: 'middle',
                    border: [...bords],
                    fill: 'F2F2F2'
                }
            },
        ],
        [
            {
                text: [{ text: "supérieur à ", options: { breakLine: false } }, { text: "500.000 €", options: { breakLine: false, bold: true } }],
                options: {
                    fontFace: 'Gill Sans MT',
                    color: grayLetter,
                    fontSize: 12,
                    align: 'center',
                    valign: 'middle',
                    border: [...bords],
                    fill: 'F2F2F2'
                }
            },
            {
                text: "4%",
                options: {
                    fontFace: 'Gill Sans MT',
                    fontSize: 12,
                    color: grayLetter,
                    valign: 'middle',
                    align: 'center',
                    border: [...bords],
                    fill: 'F2F2F2'
                }
            },
            {
                text: [{ text: "supérieur à ", options: { breakLine: false } }, { text: "1.000.000 €", options: { breakLine: false, bold: true } }],
                options: {
                    fontFace: 'Gill Sans MT',
                    color: grayLetter,
                    fontSize: 12,
                    align: 'center',
                    valign: 'middle',
                    border: [...bords],
                    fill: 'F2F2F2'
                }
            },
        ],
    ];

    slide23.addTable(rowsSlide23, {
        x: 0.85,
        y: 5.1,
        colW: [3.5, 1, 3.5],
        rowH: [0.5, 0.32, 0.32, 0.32, 0.32]
    });

    slide23.addText(
        [
            { text: "Avec mécanisme de lissage", options: { breakLine: true } },
            { text: "en fonction des RFR", options: { breakLine: true } },
            { text: "précédents" }
        ], {
        x: 9.2,
        y: 5.35,
        w: '20%',
        h: 0.8,
        fontSize: 12,
        color: blackColor,
        italic: true,
        fontFace: 'Gill Sans MT'
    });


    // Traitement SLIDE24
    const slide24 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide24,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            text: "LA CEHR",
            width: 2.3
        }
    )

    slide24.addText(
        [
            { text: "CALCUL DE LA CEHR ", options: { breakLine: false, bold: true } },
            { text: "EN FONCTION DU MONTANT DU RFR", options: { breakLine: true } },
            { text: "(revenu fiscal de référence, plus-value de cession incluse) et hors effet de lissage éventuel" }
        ]
        , {
            x: 0.6,
            y: 1.8,
            w: "90%",
            h: 0.5,
            align: 'center',
            fontFace: 'Gill Sans MT',
            fontSize: 15.8,
            color: blackColor
        });

    const greatMarge = 4;

    const rowsSlide24 = [
        [
            {
                text: [{ text: "Montant du revenu fiscal", options: { breakLine: true, bold: true } }, { text: "de référence", options: { breakLine: true, bold: true } }, { text: "(y compris la PV de cession)" }],
                options: {
                    color: 'FFFFFF',
                    fill: 'ADB9CA',
                    fontFace: 'Gill Sans MT',
                    valign: 'middle',
                    align: 'center',
                    border: [...bords]
                }
            },
            {
                text: [{ text: "CEHR pour une", options: { breakLine: true } }, { text: "personne seule" }],
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    bold: true,
                    fill: 'ADB9CA',
                    valign: 'middle',
                    align: 'center',
                    colspan: 2,
                    border: [...bords]
                }
            },
            {
                text: [{ text: "CEHR pour un couple", options: { breakLine: true } }, { text: "marié ou pacsé" }],
                options: {
                    fontFace: 'Gill Sans MT',
                    color: 'FFFFFF',
                    bold: true,
                    fill: 'ADB9CA',
                    valign: 'middle',
                    align: 'center',
                    colspan: 2,
                    border: [...bords]
                }
            },
        ],
        [
            { text: "250 000 €", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "0 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "0,00 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
            { text: "0 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "0,00 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
        ],
        [
            { text: "500 000 €", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "7 500 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "1,50 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
            { text: "0 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "0,00 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
        ],
        [
            { text: "1 000 000 €", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "27 500 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "2,75 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
            { text: "15 000 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "1,50 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
        ],
        [
            { text: "1 500 000 €", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "47 500 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "3,17 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
            { text: "35 000 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "2,33 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
        ],
        [
            { text: "2 000 000 €", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "67 500 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "3,38 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
            { text: "55 000 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "2,75 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
        ],
        [
            { text: "3 000 000 €", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "107 500 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "3,58 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
            { text: "95 000 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "3,17 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
        ],
        [
            { text: "5 000 000 €", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "187 500 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "3,75 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
            { text: "175 000 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "3,50 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
        ],
        [
            { text: "10 000 000 €", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "387 500 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "3,88 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
            { text: "375 000 €", options: { fill: "FDE9D9", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [...bords] } },
            { text: "3,75 %", options: { fill: "F2F2F2", fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', color: '808080', border: [...bords] } },
        ],
    ];

    slide24.addTable(rowsSlide24, {
        x: 1.4,
        y: 2.7,
        colW: [2.3, 2.3, 1, 2.3, 1],
        rowH: [1, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35]
    });

    // Traitement SLIDE25
    const slide25 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide25,
        {
            text: "L’IMPOSITION DE LA PLUS-VALUE DE CESSION",
            width: 5.7
        },
        {
            text: "LE CALENDRIER FISCAL",
            width: 3.6
        }
    )

    slide25.addText("LA DÉCLARATION DE LA PLUS-VALUE & LE PAIEMENT DES IMPOSITIONS", {
        x: 0.6,
        y: 1.66,
        w: "90%",
        h: 0.4,
        align: 'center',
        fontFace: 'Gill Sans MT',
        color: blackColor,
        fontSize: 14,
        bold: true
    });

    slide25.addImage({
        path: calendrierFiscal,
        x: 1.2,
        y: 2.4,
        w: '83%',//'99%',
        h: '30%'//'40%'
    });


    // Traitement SLIDE26
    const slide26 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    const slide26Title = [
        { text: "LES OPÉRATIONS AVANT CESSION", options: { breakLine: true } },
    ];

    const slide26Text = [
        { text: "Les conséquences fiscales des apports en société et des donations réalisées préalablement à la cession", options: { breakLine: true, fontSize: 14, bold: true, color: '595959' } },
        { text: "", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "La chronologie fiscale des opérations", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "", options: { breakLine: true } },
        { text: "Les apports avant cession", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
        { text: "", options: { breakLine: true } },
        { text: "Les donations avant cession", options: { breakLine: true, color: '4472C4', bullet: { code: '25CF', color: '4472C4' }, indentLevel: 1, fontSize: 12 } },
    ]

    const xSlide26 = 3.8;
    const ySlide26 = 2;

    generateFirstSlideTitle(
        pptx,
        slide26,
        slide26Title,
        xSlide26 - 1.4,
        ySlide26 - 1,
        "FICHE",
        xSlide26 - 1
    )
    generateThreeBarres(slide26, xSlide26 + 0.4, ySlide26 + 0.6, 2);

    slide26.addText(slide26Text, {
        x: xSlide26 + 0.7,
        y: ySlide26 + 0.6,
        w: 5,
        h: 2,
        fontSize: 14,
        fontFace: 'Roboto',
        align: 'justify',
        color: '5271C7',
        // lineSpacingMultiple: 1.3
    })

    // Traitement SLIDE27
    const slide27 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide27,
        lesOperationsAvantCessionTitle,
        {
            text: [{ text: "LA CHRONOLOGIE FISCALE", options: { breakLine: true } }, { text: "ou LA DATE DE CESSION" }],
            width: 4
        }
    )

    slide27.addImage({
        path: chronologieFiscale,
        x: 0.6,
        y: 1.7,
        w: '90%',
        h: '58%'
    });

    // Traitement SLIDE28
    const slide28 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide28,
        lesOperationsAvantCessionTitle,
        {
            text: "LES APPORTS AVANT CESSION",
            width: 4.3
        }
    )

    slide28.addText("L’opération consiste à apporter ses titres au capital d’une société soumise à l’impôt sur les sociétés (IS) avant la cession pour que la société bénéficiaire des apports procède à la vente.  L’objectif est de réinvestir tout ou partie du prix de cession dans la structure à l’IS sans payer l’imposition sur la plus-value de cession.", {
        x: 0.6,
        y: 1.65,
        fontFace: 'Gill Sans MT',
        bold: true,
        fontSize: 15,
        color: blackColor,
        w: '90%',
        h: 0.95
    });

    slide28.addImage({
        path: apportsAvantCession,
        x: 0.7,
        y: 2.9,
        w: "51%",
        h: "51%"
    });

    slide28.addText("Etape I", {
        x: 8.8,
        y: 2.9,
        w: '10%',
        h: '2%',
        fontFace: 'Gill Sans MT',
        align: 'center',
        valign: 'center',
        fill: 'F2C662',
        bold: true,
        color: 'FFFFFF',
        fontSize: 15
    });

    slide28.addText("Etape 2", {
        x: 8.8,
        y: 5.3,
        w: '10%',
        h: '2%',
        fontFace: 'Gill Sans MT',
        align: 'center',
        valign: 'center',
        fill: 'F2C662',
        bold: true,
        color: 'FFFFFF',
        fontSize: 15
    });

    slide28.addText("Apporter les titres « à céder » au capital (initial ou augmentation de capital) d’une société à l’IS et en contrepartie recevoir des titres de la société bénéficiaire des apports", {
        x: 7.8,
        y: 3.1,
        w: '30.5%',
        h: 0.9,
        fontFace: 'Gill Sans MT',
        color: blackColor,
        fontSize: 13
    });

    slide28.addText("> La plus-value de cession de titres « mute » alors en plus-value d’échange qui bénéficie, en principe, d’un différé d’imposition", {
        x: 7.8,
        y: 4.11,
        w: '30.5%',
        h: 0.9,
        fontFace: 'Gill Sans MT',
        fontSize: 13,
        color: blackColor,
        bold: true
    });

    slide28.addText("La société bénéficiaire de l’apport cède les titres apportés en franchise d’impôt", {
        x: 7.8,
        y: 5.5,
        w: '30.5%',
        h: 0.5,
        fontFace: 'Gill Sans MT',
        color: blackColor,
        fontSize: 13
    });

    slide28.addText("> Le prix d’acquisition des titres cédés correspond à la valeur d’apport. Si l’apport est réalisé peu de temps avant la cession, cela signifie que la plus-value de cession est faible voire nulle", {
        x: 7.8,
        y: 6.1,
        w: '30.5%',
        h: 1.1,
        fontFace: 'Gill Sans MT',
        fontSize: 13,
        color: blackColor,
        bold: true
    });


    // Traitement SLIDE29
    const slide29 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide29,
        lesOperationsAvantCessionTitle,
        {
            text: "LES APPORTS AVANT CESSION",
            width: 4.3
        }
    )

    slide29.addText("LES RÉGIMES DE DIFFÉRÉS D’IMPOSITION", {
        x: 0.6,
        y: 1.6,
        w: "90%",
        h: 0.4,
        align: 'center',
        fontFace: 'Gill Sans MT',
        color: blackColor,
        fontSize: 15.8,
        bold: true
    });

    slide29.addText("Situation 1", {
        x: 1.15,
        y: 2.2,
        fontFace: 'Gill Sans MT',
        color: 'FFFFFF',
        fill: '28366D',
        w: '25%',
        h: '4%',
        fontSize: 13,
        align: 'center',
        valign: 'middle',
        bold: true
    });

    const blueValue = [
        { text: "Apport à une société ", options: { breakLine: false } },
        { text: "contrôlée", options: { underline: true, breakLine: true, bold: true } },
        { text: "par l’apporteur avec son groupe familial", options: {} }
    ];

    slide29.addText(blueValue, {
        x: 1.15,
        y: 2.58,
        fontFace: 'Gill Sans MT',
        color: 'FFFFFF',
        fill: '28366D',
        w: '25%',
        h: '10%',
        fontSize: 13,
        align: 'center',
        valign: 'middle',
    });

    slide29.addText("Régime fiscal du report d’imposition sur la PV", {
        x: 1.15,
        y: 3.51,//3.6,
        fontFace: 'Gill Sans MT',
        color: orange,
        w: '53.3%',
        h: '5%',
        fontSize: 13,
        align: 'center',
        valign: 'middle',
        line: {
            color: orange
        }
    });

    slide29.addText("La plus-value d’apport doit être déclarée au titre de l’année de l’échange de titres. L’imposition de la PV est aussi calculée mais son paiement est différé jusqu’au jour notamment de la cession ou du remboursement des titres apportés ou des titres reçus en contrepartie de l’apport", {
        x: 1.15,
        y: 4.0,
        fontFace: 'Gill Sans MT',
        color: orange,
        w: '53.3%',
        h: '14%',
        fontSize: 13,
        align: 'center',
        valign: 'middle',
        line: {
            color: orange
        }
    });

    slide29.addText(
        [
            { text: "Cession", options: { breakLine: false } },
            { text: " dans les 3 ans", options: { breakLine: true, bold: true } },
            { text: "suivant l'apport" }
        ]
        , {
            x: 1.15,
            y: 5.25,
            fontFace: 'Gill Sans MT',
            color: 'FFFFFF',
            fill: '28366D',
            w: '25%',
            h: '7%',
            fontSize: 13,
            align: 'center',
            valign: 'middle',
        });

    slide29.addText("Obligation de réinvestir dans les 2 ans de la cession au moins 60 % du prix de cession dans des activités fiscalement « éligibles » pour maintenir le report d’imposition"
        , {
            x: 1.15,
            y: 5.90,//5.95,
            fontFace: 'Gill Sans MT',
            color: '28366D',
            fill: 'FBE5D6',
            w: '25%',
            h: '15%',
            fontSize: 13,
            align: 'center',
            valign: 'middle',
        });

    slide29.addText("Situation 2", {
        x: 4.45,
        y: 2.2,
        fontFace: 'Gill Sans MT',
        color: 'FFFFFF',
        fill: '28366D',
        w: '25%',
        h: '4%',
        fontSize: 13,
        align: 'center',
        valign: 'middle',
        bold: true
    });

    slide29.addText(blueValue, {
        x: 4.45,
        y: 2.58,
        fontFace: 'Gill Sans MT',
        color: 'FFFFFF',
        fill: '28366D',
        w: '25%',
        h: '10%',
        fontSize: 13,
        align: 'center',
        valign: 'middle',
    });

    slide29.addText(
        [
            { text: "Cession", options: { breakLine: false } },
            { text: " plus de 3 ans", options: { breakLine: true, bold: true } },
            { text: "après l'apport" }
        ]
        , {
            x: 4.45,
            y: 5.25,
            fontFace: 'Gill Sans MT',
            color: '28366D',
            w: '25%',
            h: '7%',
            fontSize: 13,
            align: 'center',
            valign: 'middle',
            line: {
                color: '28366D'
            }
        });

    slide29.addText("Liberté de réinvestissement"
        , {
            x: 4.45,
            y: 5.90,
            fontFace: 'Gill Sans MT',
            color: '28366D',
            fill: 'E2F0D9',
            w: '25%',
            h: '7%',
            fontSize: 13,
            align: 'center',
            valign: 'middle',
        });

    slide29.addText("Situation 3", {
        x: 7.75,
        y: 2.2,
        fontFace: 'Gill Sans MT',
        color: 'FFFFFF',
        fill: '28366D',
        w: '25%',
        h: '4%',
        fontSize: 13,
        valign: 'middle',
        align: 'center',
        bold: true
    });

    slide29.addText(
        [
            { text: "Apport à une société ", options: { breakLine: false } },
            { text: "non", options: { breakLine: true, underline: true, bold: true } },
            { text: "contrôlée par l’apporteur" }
        ]
        , {
            x: 7.75,
            y: 2.58,
            fontFace: 'Gill Sans MT',
            color: '28366D',
            w: '25%',
            h: '10%',
            fontSize: 13,
            align: 'center',
            valign: 'middle',
            line: {
                color: '28366D'
            }
        });

    slide29.addText("Régime fiscal du sursis d’imposition", {
        x: 7.75,
        y: 3.51,
        fontFace: 'Gill Sans MT',
        color: green,
        w: '25%',
        h: '5%',
        fontSize: 13,
        align: 'center',
        valign: 'middle',
        line: {
            color: green
        }
    });

    slide29.addText("L’échange est fiscalement neutre sur la PV à condition qu’il n’y ait pas de soulte excédentaire", {
        x: 7.75,
        y: 4.0,
        fontFace: 'Gill Sans MT',
        color: green,
        w: '25%',
        h: '14%',
        fontSize: 13,
        align: 'center',
        valign: 'middle',
        line: {
            color: green
        }
    });

    slide29.addText(
        [
            { text: "Cession", options: { breakLine: true } },
            { text: "sans considération de durée" },
        ]
        , {
            x: 7.75,
            y: 5.25,
            fontFace: 'Gill Sans MT',
            color: '28366D',
            w: '25%',
            h: '7%',
            fontSize: 13,
            align: 'center',
            valign: 'middle',
            line: {
                color: '28366D'
            }
        });

    slide29.addText("Liberté de réinvestissement"
        , {
            x: 7.75,
            y: 5.90,
            fontFace: 'Gill Sans MT',
            color: '28366D',
            fill: 'E2F0D9',
            w: '25%',
            h: '7%',
            fontSize: 13,
            align: 'center',
            valign: 'middle',
        });

    // Traitement SLIDE30
    const slide30 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide30,
        lesOperationsAvantCessionTitle,
        {
            text: "LES APPORTS AVANT CESSION",
            width: 4.3
        }
    )

    slide30.addText("REPORT D’IMPOSITION EN  CAS D’APPORT – CESSION DE TITRES", {
        x: 0.6,
        y: 1.6,
        fontFace: 'Gill Sans MT',
        color: '28366D',
        bold: true,
        fill: 'ADB9CA',
        fontSize: 15,
        align: 'center',
        valign: 'middle',
        w: '68%',
        h: '5%'
    });

    slide30.addText("Art. 150-0 B ter CGI", {
        x: 8.8,
        y: 1.6,
        fontFace: 'Gill Sans MT',
        color: '4472C4',
        bold: true,
        fontSize: 15,
        w: '20%',
        h: '5%',
        align: 'center',
        valign: 'middle'
    });

    slide30.addText("Applicable aux plus-values d’échanges de titres réalisées depuis le 14/11/2012 au profit d’une société contrôlée par l’apporteur avec son groupe familial", {
        x: 1,
        y: 2.35,
        fontFace: 'Gill Sans MT',
        bold: true,
        fontSize: 14,
        color: blackColor,
        w: '88%',
        h: 0.5
    });

    slide30.addText("Les conditions du réinvestissement en cas d’apport/cession de moins de 3 ans", {
        x: 1,
        y: 3.05,
        w: '88%',
        h: 0.3,
        fontFace: 'Gill Sans MT',
        bold: true,
        fontSize: 17.2,
        color: grayLetter,
        underline: true
    });

    slide30.addText(
        [
            { text: " Réinvestissement portant sur ", options: { breakLine: false, color: blackColor, bullet: { code: '25A0' } } },
            { text: "au moins 60% du produit de la cession", options: { color: '4472C4', bold: true } }
        ]
        , {
            x: 1.6,
            y: 3.45,
            w: '85%',
            h: 0.3,
            fontFace: 'Gill Sans MT',
            fontSize: 15.5,
        });

    slide30.addText(
        [
            { text: " ", options: { breakLine: false, color: blackColor, bullet: { code: '25A0' } } },
            { text: "Dans les 2 ans ", options: { breakLine: false, bold: true, color: '4472C4' } },
            { text: "qui suivent la cession", options: { color: blackColor } }
        ]
        , {
            x: 1.6,
            y: 3.85,
            fontFace: 'Gill Sans MT',
            fontSize: 15.5,
            w: '85%',
            h: 0.3
        });

    slide30.addText(" Dans une activité économique éligible de nature commerciale, industrielle, artisanale, libérale, agricole ou financière (les activités de gestion de son propre patrimoine mobilier ou immobilier étant exclues) sous l’une ou l’autre des modalités suivantes :"
        , {
            x: 1.6,
            y: 4.3,
            w: '85%',
            h: 0.8,
            color: blackColor,
            fontFace: 'Gill Sans MT',
            fontSize: 15.5,
            bullet: { code: '25A0' }
        });

    slide30.addText("Financement de biens mobiliers ou immobiliers inscrits directement à l’actif de la société et affectés à son activité", {
        x: 1.9,
        y: 5.15,
        fontFace: 'Gill Sans MT',
        fontSize: 13.7,
        w: '82%',
        h: 0.3,
        color: '4472C4',
        bullet: { code: '25CF' }
    });

    slide30.addText("Acquisition de titres d’une société opérationnelle à condition d’en prendre le contrôle", {
        x: 1.9,
        y: 5.55,
        fontFace: 'Gill Sans MT',
        fontSize: 13.7,
        w: '82%',
        h: 0.3,
        color: '4472C4',
        bullet: { code: '25CF' }
    });

    slide30.addText("Souscription en numéraire au capital initial ou à l’augmentation de capital d’une société opérationnelle", {
        x: 1.9,
        y: 5.95,
        fontFace: 'Gill Sans MT',
        fontSize: 13.7,
        w: '82%',
        h: 0.3,
        color: '4472C4',
        bullet: { code: '25CF' }
    });

    slide30.addText("Souscription de titres de FCPR, FCPI, SCR (sous conditions)", {
        x: 1.9,
        y: 6.35,
        fontFace: 'Gill Sans MT',
        fontSize: 13.7,
        w: '82%',
        h: 0.3,
        color: '4472C4',
        bullet: { code: '25CF' }
    });

    slide30.addText(
        [
            { text: "Les actifs ou titres objet du réinvestissement doivent être ", options: { breakLine: false, color: blackColor } },
            { text: "conservés pendant au moins 1 an ", options: { bold: true, breakLine: false, color: '4472C4' } },
            { text: "(ou 5 ans pour les réinvestissements via des fonds ou SCR).", options: { breakLine: false, color: blackColor } },
        ]
        , {
            x: 1.6,
            y: 6.8,
            w: '85%',
            h: 0.5,
            fontFace: 'Gill Sans MT',
            fontSize: 15.5,
            bullet: { code: '25A0' }
        });

    // Traitement SLIDE31
    const slide31 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide31,
        lesOperationsAvantCessionTitle,
        {
            text: "LES DONATIONS AVANT CESSION",
            width: 4.4
        }
    )

    slide31.addText("L’opération consiste à donner des titres en pleine propriété et/ou en nue-propriété avec réserve d’usufruit avant la cession.", {
        x: 0.6,
        y: 2.1,
        fontFace: 'Gill Sans MT',
        bold: true,
        color: blackColor,
        fontSize: 15,
        w: "90%",
        h: 0.5
    });

    slide31.addText("L’objectif est de transmettre son patrimoine dans des conditions fiscales favorables dès lors que la donation permet d’économiser tout ou partie de l’imposition sur la plus-value.", {
        x: 0.6,
        y: 2.8,
        fontFace: 'Gill Sans MT',
        bold: true,
        color: blackColor,
        fontSize: 15,
        w: "90%",
        h: 0.5
    });

    slide31.addText("De manière simplifiée, pour le coût d’un seul impôt : les droits de donation, deux opérations sont réalisées : la transmission et la cession.", {
        x: 0.6,
        y: 3.5,
        fontFace: 'Gill Sans MT',
        bold: true,
        fontSize: 15,
        color: blackColor,
        w: "90%",
        h: 0.5
    });

    slide31.addText("", {
        x: 0.8,
        y: 4.4,
        fill: '4472C4',
        w: "50%",
        h: "0.5%"
    });

    slide31.addText("La donation doit être réelle et entrainer un dessaisissement effectif du donateur", {
        x: 0.9,
        y: 4.85,
        w: '80%',
        h: 0.3,
        fontFace: 'Gill Sans MT',
        color: blackColor,
        fontSize: 15,
        bullet: {
            code: '25A0'
        }
    });

    slide31.addText(
        [
            { text: "La donation rend exigible les " },
            { text: "droits de donations", options: { bold: true } }
        ]
        , {
            x: 0.9,
            y: 5.25,
            w: '80%',
            h: 0.3,
            fontFace: 'Gill Sans MT',
            fontSize: 15,
            color: blackColor,
            bullet: {
                code: '25A0'
            }
        });

    slide31.addText(
        [
            { text: "La donation doit être réalisée avant la cession pour " },
            { text: "revaloriser le prix de revient des titres donnés et donc gommer fiscalement tout ou partie des plus-values latentes. ", options: { bold: true } },
            { text: "Si la donation est réalisée peu de temps avant la cession, la plus-value de cession est faible voire nulle." }
        ]
        , {
            x: 0.9,
            y: 5.68,
            w: '80%',
            h: 0.8,
            fontFace: 'Gill Sans MT',
            fontSize: 15,
            color: blackColor,
            bullet: {
                code: '25A0'
            }
        });

    // Traitement SLIDE32
    const slide32 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide32,
        lesOperationsAvantCessionTitle,
        {
            text: "LES DONATIONS AVANT CESSION",
            width: 4.4
        }
    )

    slide32.addText("LA DONATION REND EXIGIBLE LES DROITS DE DONATION", {
        x: 0.6,
        y: 1.56,
        w: "90%",
        h: 0.4,
        align: 'center',
        fontFace: 'Gill Sans MT',
        fontSize: 15.8,
        bold: true,
        color: blackColor,
    });

    slide32.addText("Rappel fiscal des donations antérieures de moins de 15 ans", {
        x: 0.6,
        y: 2.1,
        w: "20%",
        h: "11%",
        fill: "ADB9CA",
        fontFace: 'Gill Sans MT',
        fontSize: 13,
        color: '28366D',
        align: 'center',
        valign: 'middle',
        bold: true
    });

    slide32.addText("Les droits de donation sont calculés sur la part donnée par donateur et par donataire. Il est tenu compte des donations antérieures de moins de 15 ans pour déterminer le montant des abattements disponibles et des tranches basses disponibles du barème qui se renouvellent effectivement tous les 15 ans (rappel fiscal)", {
        x: 3.1,
        y: 2.1,
        w: "69%",
        h: "11%",
        fontFace: 'Gill Sans MT',
        fontSize: 13,
        align: 'center',
        valign: 'middle',
        line: {
            color: 'ADB9CA',
        },
        color: '28366D',
    });

    const rowsSlide32 = [
        [
            { text: "Evaluation fiscale de l’usufruit et la nue-propriété", options: { fill: grisB, color: grayLetter, fontSize: 13, fontFace: 'Gill Sans MT', bold: true, colspan: 3, align: 'center', valign: 'middle', border: [{ pt: 0, color: "FFFFFF" }, { pt: 15, color: "FFFFFF" }, { pt: 3, color: "FFFFFF" }, { pt: 0, color: "FFFFFF" },] } },
            { text: "Tarif applicable en ligne directe (entre parents et enfants)", options: { fill: grisB, color: grayLetter, fontSize: 13, fontFace: 'Gill Sans MT', bold: true, colspan: 3, align: 'center', valign: 'middle', border: [{ pt: 0, color: "FFFFFF" }, { pt: 0, color: "FFFFFF" }, { pt: 3, color: "FFFFFF" }, { pt: 0, color: "FFFFFF" },] } },
        ],
        [
            { text: [{ text: "Age de", options: { breakLine: true } }, { text: "l'usufruitier" }], options: { fill: grisClair, color: grayLetter, fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: "", color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: [{ text: "Valeur fiscale", options: { breakLine: true } }, { text: "USUFRUIT" }], options: { fill: grisClair, color: grayLetter, fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: [{ text: "Valeur fiscale", options: { breakLine: true } }, { text: "NUE-PROPRIÉTÉ" }], options: { fill: grisClair, color: grayLetter, fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: [{ text: "Fraction de la part", options: { breakLine: true } }, { text: "nette taxable" }], options: { fill: grisClair, color: grayLetter, fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "Tarif", options: { fill: grisClair, color: grayLetter, fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "Montant des droits", options: { fill: grisClair, color: grayLetter, fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 3, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
        [
            { text: "Jusqu’à 20 ans", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "90%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "10%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "   Jusqu'à 8 072 €", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "5 %", options: { color: blackColor, fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "= D x 5%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
        [
            { text: "De 21 à 30 ans", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "80%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "20%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "   De 8 072 € à 12 109 €", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "10 %", options: { color: blackColor, fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "= D x 10%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
        [
            { text: "De 31 à 40 ans", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "70%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "30%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "   De 12 109 € à 15 932 €", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "15 %", options: { color: blackColor, fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "= D x 15%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
        [
            { text: "De 41 à 50 ans", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "60%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "40%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "   De 15 932 € à 552 324 €", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "20 %", options: { color: blackColor, fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "= D x 20%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
        [
            { text: "De 51 à 60 ans", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "50%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "50%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "   De 552 324 € à 902 838 €", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "30 %", options: { color: blackColor, fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "= D x 30%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
        [
            { text: "De 61 à 70 ans", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "40%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "60%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "   De 902 838 € à 1 805 677 €", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "40 %", options: { color: blackColor, fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "= D x 40%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
        [
            { text: "De 71 à 80 ans", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "30%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "70%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "   Supérieur à 1 805 677 €", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "45 %", options: { color: blackColor, fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "= D x 45%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
        [
            { text: "De 81 à 90 ans", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "20%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "80%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: [{ text: "   Avec D = montant de donation", options: { breakLine: true } }, { text: "   Abattement fiscal entre parent et enfant : 100 000 €", options: { bold: true } }], options: { colspan: 3, rowspan: 2, fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'left', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
        [
            { text: "Supérieur à 91 ans", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "10%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
            { text: "90%", options: { fill: 'F2F2F2', fontSize: 13, fontFace: 'Gill Sans MT', align: 'center', valign: 'middle', border: [{ pt: 0, color: 'FFFFFF' }, { pt: 15, color: 'FFFFFF' }, { pt: 1, color: 'FFFFFF' }, { pt: 0, color: 'FFFFFF' },] } },
        ],
    ];

    slide32.addTable(rowsSlide32, {
        x: 0.6,
        y: 3.4,
        colW: [1.6, 1.4, 1.8, 2.8, 1, 2]
    });

    // Traitement SLIDE33
    const slide33 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    generateTitleAndSubtitle(
        "FICHE",
        pptx,
        slide33,
        lesOperationsAvantCessionTitle,
        {
            text: "LES DONATIONS AVANT CESSION",
            width: 4.4
        }
    )

    slide33.addText("LA DONATION PERMET DE GOMMER TOUT OU PARTIE DE L’IMPOT DE PLUS-VALUE", {
        x: 0.6,
        y: 1.56,
        w: "90%",
        h: 0.4,
        align: 'center',
        fontFace: 'Gill Sans MT',
        fontSize: 15.8,
        bold: true,
        color: blackColor,
    });

    slide33.addText(
        [
            { text: "Donation en pleine propriété", options: { breakLine: true } },
            { text: "avant cession" }
        ]
        , {
            x: 0.6,
            y: 2,
            fontFace: 'Gill Sans MT',
            fontSize: 13,
            color: grayLetter,
            bold: true,
            align: 'center',
            valign: 'middle',
            w: '27%',
            h: '6.5%',
            fill: grisB
        });

    const text1Slide33 = [
        { text: "Droits de donation sur la valeur de la PP ", options: { bold: true, color: grayLetter } },
        { text: "au jour de la donation, à la charge du donataire ou donateur ", options: { color: grayLetter } },
        { text: "(i)", options: { color: orange, breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "IPV à la charge du donataire cédant ", options: { bold: true, color: grayLetter } },
        { text: "cédant avec comme prix de revient = Valeur de la PP au jour de la donation + Frais et droits de donation s’ils ont été payés par le donataire ", options: { color: grayLetter } },
        { text: "(ii)", options: { color: orange, breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "Si valeur de la PP au jour de la donation = Valeur de la PP au jour de la cession : aucune plus-value n’est extériorisée, donc pas d’IPV", options: { bold: true, color: grayLetter } },

    ];

    slide33.addText(text1Slide33
        , {
            x: 0.6,
            y: 2.6,
            w: "27%",
            h: '43%',
            fontFace: 'Gill Sans MT',
            fontSize: 13,
            fill: 'F2F2F2'
        });

    slide33.addText(
        [
            { text: "Donation en nue-propriété", options: { breakLine: true } },
            { text: "avant cession" }
        ]
        , {
            x: 7.0,
            y: 2,
            fontFace: 'Gill Sans MT',
            align: 'center',
            valign: 'middle',
            fontSize: 13,
            color: grayLetter,
            bold: true,
            w: '36%',
            h: '6.5%',
            fill: grisB
        });

    const rightTextSlide33 = [
        { text: "Droits de donation sur la valeur de la NP au jour de la donation ", options: { bold: true, color: grayLetter } },
        { text: "donation (déterminée sur la base du barème fiscal selon l’âge de l’usufruitier) à la charge du donataire ou donateur ", options: { color: grayLetter } },
        { text: "(i)", options: { color: orange, breakLine: true } },
        { text: "", options: { breakLine: true } },
        { text: "IPV à la charge du donataire cédant ", options: { bold: true, color: grayLetter } },
        { text: "cédant avec comme prix de revient = Valeur de la NP au jour de la donation + Prix d'acquisition de l'usufruit + Frais et droits de donation s’ils ont été payés par le donataire ", options: { color: grayLetter } },
        { text: "(ii) (iii).", options: { color: orange, breakLine: true } },
        //{ text: "", options: { breakLine: true } },
        { text: "(iii) ", options: { color: orange } },
        { text: "Hypothèse d’une cession conjointe sans répartition du prix de vente, avec clause de report du démembrement sur le prix de cession et les actifs acquis en remploi.", options: { color: blackColor, breakLine: true } },
        { text: "Hypothèse d’une cession portant sur des titres dont la propriété est démembrée depuis le 3 juillet 2001.", options: { breakLine: true, color: blackColor } },
        { text: "", options: { breakLine: true } },
        { text: "Si valeur de la PP au jour de la donation = Valeur de la PP au jour de la cession : PV sur la valeur de l’usufruit", options: { bold: true, color: grayLetter } },


    ];

    slide33.addText(rightTextSlide33
        , {
            x: 7,
            y: 2.55,
            w: "36%",
            h: '57%',
            fontFace: 'Gill Sans MT',
            fontSize: 13,
            fill: 'F2F2F2'
        });

    slide33.addText(
        [
            { text: "(i) Prise en charge des frais et droits de donation", options: { bold: true, breakLine: true } },
            { text: "En principe les frais et droits de donation sont à la charge du donataire. Mais ils peuvent sur option être acquittés par le donateur sans coût supplémentaire" },
        ]
        , {
            x: 4.15,
            y: 2.9,
            w: "22%",
            h: '20%',
            fontFace: 'Gill Sans MT',
            align: 'center',
            valign: 'middle',
            fontSize: 12,
            color: orange,
            line: {
                color: orange
            }
        });

    slide33.addText(
        [
            { text: "(ii) Prix de revient fiscal & Prise en charge de l’IPV ", options: { bold: true, breakLine: true } },
            { text: "Si le donataire est rattaché au foyer fiscal du donateur, les frais de donation pris en charge par le donateur pourront être retenus pour le calcul de la PV imposable du foyer fiscal et l’IPV pourra être pris en charge par le foyer fiscal" },
        ]
        , {
            x: 4.15,
            y: 4.6,
            w: "22%",
            h: '25%',
            fontFace: 'Gill Sans MT',
            align: 'center',
            valign: 'middle',
            fontSize: 12,
            color: orange,
            line: {
                color: orange
            }
        });

    // Traitement SLIDE34 (LAST SLIDE)
    const slide34 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    // profil = await getInfoClient();

    generateLastPage(pptx, slide34, profil);

    pptx.writeFile(`${isFicheThematique ? 'Fiche' : 'Simulation'}_CS_` + completeDate(now) + ".pptx");
};

export default csPPT;