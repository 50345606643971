import React, { useContext } from "react";
import { CInput } from '@coreui/react';
import { SimulateurContext } from "../../store/context/simulateur";
import { activePageBlock } from "../../store/actions/simulateur";
import PostitPin from "../Postit/PostitPin";

const InputText = (props) => {
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);

    const handleChange = (event) => {
        // On met à jour l'état de changement de FORMULAIRE
        simulateurDispatch(activePageBlock())

        // Changement de valeur contenue dans le FORMULAIRE
        props.onChange(event);
    }

    return (
      <div className={props.className ? props.className : "col-lg-3 mr-3"} style={{ padding: 0, position: 'relative', ...props.style, }}>
        <CInput
            invalid={props.invalid}
            id={props.id}
            value={props.value}
            onKeyDown={event => {
                if (event.keyCode === 13) {
                    const form = event.target.form;
                    if (form) {
                        let index = Array.prototype.indexOf.call(form, event.target);
                        if (form.elements[index + 1])
                            form.elements[index + 1].focus();
                        event.preventDefault();
                    }
                }
            }}
            placeholder={props.placeholder}
            onChange={handleChange}
            disabled={props.disabled}
            style={{
                width: "100%",
                textAlign: this?.props?.style?.textAlign || 'left',
                fontSize: 13,
                textTransform: props.uppercase ? 'uppercase' : props.capitalize ? 'capitalize' : 'none',
                ...props.styleInput
            }}
        />
        {
          !props.hidePin && (
            <PostitPin isPostitShow={simulateurState?.postItData?.displayBlocPostIt} pinId={props.id} />
          )
        }
      </div>
    )
}

export default InputText
