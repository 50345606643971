export const ifiDefaultData = JSON.parse(JSON.stringify({
    withCalcul: true,
    idSimulation: null,
    activeSimu: false,
    infoSimulation: {
        identifiant_simulation: "brouillon",
        clientId: null,
        commentaire: ""
    },
    "indexTab": 0,
    "anneeFiscale": new Date().getFullYear(),
    "affichageMessage": 0,
    "form1": {
        "configuration": {
            "client": null,
            "idBilan": null,
            "idFiscalite": null,
            "bilan_patrimonial": null,
            "fiscalite": null,
            "date_importation_bilan_patrimonial": "",
            "date_importation_fiscalite": "",
            "import_bilan_patrimonial": 0,
            "import_fiscalite_revenus": 0
        }
    },
    "form2": {
        "dejaDansForm2": 0,
        "souhaitez_vous_desactiver_le_plafonnement_des_dettes": 0,
        "residence_principale": null,
        "autre_actif_immo": [],
        "rp": {
            "table" : [{
              "intitule": "",
              "actif": 0,
              "passif": 0,
            }],
            "avec": 0
        },
        "rp_added": null,
        "autresImmos": [],
        "autresImmosAdded": [],
        "biens_ruraux_ou_forestiers": {
            "bois_forets_parts": [],
            "biens_ruraux_loues_long_terme": [],
            "parts_GFA_GAF": []
        },
        "dettes_fiscales_autres_dettes_deductibles": []
    },
    "form2Copie": {
        "dejaDansForm2": 0,
        "souhaitez_vous_desactiver_le_plafonnement_des_dettes": 0,
        "residence_principale": null,
        "autre_actif_immo": [],
        "rp": {
            "table" : [{
              "intitule": "",
              "actif": 0,
              "passif": 0,
            }],
            "avec": 0
        },
        "rp_added": null,
        "autresImmos": [],
        "autresImmosAdded": [],
        "biens_ruraux_ou_forestiers": {
            "bois_forets_parts": [],
            "biens_ruraux_loues_long_terme": [],
            "parts_GFA_GAF": []
        },
        "dettes_fiscales_autres_dettes_deductibles": []
    },
    "form3": {
        "dons_aux_organismes_interets_general": 0,
        "activer_calcul_plafonnement": 0,
        "revenus_pris_en_compte_pour_plafonnement": 0,
        "impots_sur_les_revenus_retenus_pour_plafonnement": 0,
        "revenus_pris_en_compte_pour_plafonnement_avec_import_donnees": 0,
        "impots_sur_les_revenus_retenus_pour_plafonnement_avec_import_donnees": 0,
        "dejaDansForm3": 0,
        "dejaDansForm3ActivationCalcul": 0
    },
    "form5": {
        "revenus_et_produits": {
            "traitements_et_salaires": {
                "montant_brut": 0,
                "frais_professionnels": 0
            },
            "pension_retraites_rentes": {
                "montant_net": 0
            },
            "rentes_viageres_a_titre_onereux": {
                "montant_net": 0
            },
            "renumeration_des_gerants_et_associes": {
                "montant_brut": 0,
                "frais_professionnels": 0
            },
            "benefices_agricoles": {
                "montant_net": 0
            },
            "Benefices_industriels_et_commerciaux": {
                "montant_net": 0
            },
            "Benefices_non_commerciaux": {
                "montant_net": 0
            },
            "revenus_fonciers": {
                "montant_net": 0
            },
            "revenus_de_capitaux_mobiliers": {
                "montant_brut": 0,
                "frais_professionnels": 0
            },
            "plus_values_y_compris_celles_exonerees_IR": {
                "montant_net": 0
            },
            "produits_soumis_un_prelevement_liberatoire_IR": {
                "montant_net": 0
            },
            "deficit_categoriel": {
                "montant_net": 0
            },
            "revenus_exoneres_IR_et_autres_revenus": {
                "revenus_de_placements_exoneres_IR": {
                    "montant_net": 0
                },
                "plus_values_immobilieres_exonerees_IR": {
                    "montant_net": 0
                },
                "participation_des_salaries": {
                    "montant_net": 0
                },
                "abondement_de_entreprise_sur_un_PEE": {
                    "montant_net": 0
                },
                "autres_revenus": {
                    "montant_net": 0
                },
                "revenus_percus_a_etranger": {
                    "montant_brut": 0,
                    "frais_professionnels": 0
                }
            }
        },
        "impot_sur_les_revenus": {
            "ir": {
                "proportionnel": {
                    "montant_net": 0
                },
                "montant_net": 0
            },
            "ps_a_la_source_revenus_activite": {
                "montant_net": 0
            },
            "ps_a_la_source_revenus_capitaux": {
                "montant_net": 0
            },
            "ps_par_voie_role": {
                "montant_net": 0
            },
            "contribution_exceptionnelle_sur_hauts_revenus": {
                "montant_net": 0
            },
            "impots_taxes_sur_plus_value_immo": {
                "montant_net": 0
            },
            "taxe_sur_cession_terrains_nus": {
                "montant_net": 0
            },
            "taxe_sur_metaux_precieux": {
                "montant_net": 0
            },
            "contribution_saliare_sur_gain_levee": {
                "montant_net": 0
            },
            "contribution_sociale_liberation": {
                "montant_net": 0
            },
            "contribution_sur_retraites_chapeaux": {
                "montant_net": 0
            }
        },
        "import_data": 0
    },
    "form5AvecImport": {
        "revenus_et_produits": {
            "traitements_et_salaires": {
                "montant_brut": 0,
                "frais_professionnels": 0
            },
            "pension_retraites_rentes": {
                "montant_net": 0
            },
            "benefices_agricoles": {
                "montant_net": 0
            },
            "Benefices_industriels_et_commerciaux": {
                "montant_net": 0
            },
            "Benefices_non_commerciaux": {
                "montant_net": 0
            },
            "revenus_fonciers": {
                "montant_net": 0
            },
            "revenus_de_capitaux_mobiliers": {
                "montant_brut": 0,
                "frais_professionnels": 0
            },
            "plus_values_y_compris_celles_exonerees_IR": {
                "montant_net": 0
            },
            "produits_soumis_un_prelevement_liberatoire_IR": {
                "montant_net": 0
            },
            "deficit_categoriel": {
                "montant_net": 0
            },
            "revenus_exoneres_IR_et_autres_revenus": {
                "plus_values_immobilieres_exonerees_IR": {
                    "montant_net": 0
                },
                "autres_revenus": {
                    "montant_net": 0
                }
            }
        },
        "impot_sur_les_revenus": {
            "ir": {
                "proportionnel": {
                    "montant_net": 0
                },
                "montant_net": 0
            },
            "ps_a_la_source_revenus_capitaux": {
                "montant_net": 0
            },
            "ps_par_voie_role": {
                "montant_net": 0
            },
            "contribution_exceptionnelle_sur_hauts_revenus": {
                "montant_net": 0
            }
        }
    },
    "form5Copie": {
        "revenus_et_produits": {
            "traitements_et_salaires": {
                "montant_brut": 0,
                "frais_professionnels": 0
            },
            "pension_retraites_rentes": {
                "montant_net": 0
            },
            "benefices_agricoles": {
                "montant_net": 0
            },
            "Benefices_industriels_et_commerciaux": {
                "montant_net": 0
            },
            "Benefices_non_commerciaux": {
                "montant_net": 0
            },
            "revenus_fonciers": {
                "montant_net": 0
            },
            "revenus_de_capitaux_mobiliers": {
                "montant_brut": 0,
                "frais_professionnels": 0
            },
            "plus_values_y_compris_celles_exonerees_IR": {
                "montant_net": 0
            },
            "produits_soumis_un_prelevement_liberatoire_IR": {
                "montant_net": 0
            },
            "deficit_categoriel": {
                "montant_net": 0
            },
            "revenus_exoneres_IR_et_autres_revenus": {
                "plus_values_immobilieres_exonerees_IR": {
                    "montant_net": 0
                },
                "autres_revenus": {
                    "montant_net": 0
                }
            }
        },
        "impot_sur_les_revenus": {
            "ir": {
                "proportionnel": {
                    "montant_net": 0
                },
                "montant_net": 0
            },
            "ps_a_la_source_revenus_capitaux": {
                "montant_net": 0
            },
            "ps_par_voie_role": {
                "montant_net": 0
            },
            "contribution_exceptionnelle_sur_hauts_revenus": {
                "montant_net": 0
            }
        }
    },
    "form6": {
        "residence_principale": [{
            "intitule": "",
            "emprunt_bien_personnel_conjoint1": {
                "table_capital": [],
                "table_passif": []
            },
            "emprunt_bien_commun_conjoint": {
                "table_capital": [],
                "table_passif": []
            },
            "emprunt_bien_personnel_conjoint2": {
                "table_capital": [],
                "table_passif": []
            },
            "valeur_brute_imposable": {
                "valeur_des_droits_en_PP_et_USUF": 0,
                "abattement_residence_principale": 0
            },
            "passif_deductible": {
                "capital_restant_du_sur_PP_et_USUF": 0,
                "passif_theorique_IFI": 0
            }
        }],
        "autres_actifs_immo": []
    },
    "form6Copie": {
        "residence_principale": [{
            "intitule": "",
            "emprunt_bien_personnel_conjoint1": {
                "table_capital": [],
                "table_passif": []
            },
            "emprunt_bien_commun_conjoint": {
                "table_capital": [],
                "table_passif": []
            },
            "emprunt_bien_personnel_conjoint2": {
                "table_capital": [],
                "table_passif": []
            },
            "valeur_brute_imposable": {
                "valeur_des_droits_en_PP_et_USUF": 0,
                "abattement_residence_principale": 0
            },
            "passif_deductible": {
                "capital_restant_du_sur_PP_et_USUF": 0,
                "passif_theorique_IFI": 0
            }
        }],
        "autres_actifs_immo": []
    }
}
))
