import React, { useContext } from 'react';
import { numberFormat } from '../../../../../extra/all_ppt_render';
import { DataControlContext } from '../../../../../store/context/dataControl';
import { valuePeriodiciteEcheance } from '../Bloc3/Bloc3';
import './style.scss';

const Bloc33 = (props) => {
    const  { data } = props;
    const { empruntState, setEmpruntState } = useContext(DataControlContext);
    const { periode_echeance } = empruntState;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
            <div style={{ fontWeight: 'bold', borderBottom: '2px solid #D8D8D8', padding: 8 }} >Montant échéance</div>
            <div style={{ height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }} >

                <div style={{ display: 'flex', height: '30px', lineHeight: '30px' }} >
                    <div style={{ width: '45%' }} >Périodicité</div>
                    <div style={{ background: 'rgb(88,92,102)', color: '#FFFFFF', width: '35%', textAlign: 'center', borderRadius: 15 }} >
                        { valuePeriodiciteEcheance(periode_echeance) }
                    </div>
                </div>
                <div className='cadreRougeBloc33' ></div>

                <div style={{ display: 'flex', height: '40px', lineHeight: '40px' }} >
                    <div style={{ width: '55%' }} >Amortissement du capital</div>
                    <div style={{ color: 'rgb(23,157,199)', fontWeight: 'bold', fontSize: 20 }} >
                        {
                            data &&
                            data.amortissement_capital ? numberFormat(data.amortissement_capital) : 0
                        } €
                    </div>
                </div>
                <div className='cadreRoseBloc33' ></div>
            </div>
        </div>
    )
};

export default Bloc33;